/*
   侧边菜单 - HZW - 191126
*/
import * as React from "react";
import { Menu, Icon } from 'antd';
import "../lib/css/SideMenu.scss";
import { connect } from "react-redux";
import * as sideMenu_AC from "src/redux/actionCreators/sideMenu_AC";
import { BoardRouterList } from '../../router/RouterConfig';
import { PoiIcon } from "./PoiIcon";
import * as head_AC from "../../redux/actionCreators/head_AC";

const { SubMenu }: any = Menu;
class SideMenu extends React.Component<any>{
	public constructor(props: any) {
		super(props);
		//获取菜单接口
		this.getListUserMenus();
	}

	public MenuTool = () => {
		// listUserMenus
		const { sideMenuReducer: { defalutOpenKeys, defalutKey, isHide, listUserMenus }, push } = this.props;

		return (
			<Menu className="menu" mode="inline"
				inlineCollapsed={isHide}
				defaultOpenKeys={defalutOpenKeys}
				selectedKeys={defalutKey}
				onClick={({ key }) => this.selectSubKey({ type: 'defalutKey', value: [key], key })}
				onOpenChange={(...data: any[]) => this.selectSubKey({ type: 'defalutOpenKeys', value: data[0] })}>
				{listUserMenus.map((item: any,) => {
					if (item.childResources && item.childResources.length > 0) {
						return (
							<SubMenu key={item.resourcesUri}
								title={
									<span>
										<PoiIcon type={ item.icon || 'poi-zongkanban' }  style={{ fontSize: '20px', }} />
										<span>{item.resourcesName}</span>
									</span>
								}
							>
								{item.childResources.map((subItem: any) => (
									<Menu.Item key={subItem.resourcesUri} onClick={() => this.pushRouter(subItem, push)}>{subItem.resourcesName}</Menu.Item>
								))}
							</SubMenu>
						)
					} else {
						return (
							<Menu.Item key={item.resourcesUri} onClick={() => this.pushRouter(item, push)}>
								<PoiIcon type={ item.icon || 'poi-zongkanban' }  style={{ fontSize: '20px', }} />
								<span>{item.resourcesName}</span>
							</Menu.Item>
						)
					}
				})}
			</Menu>
		)
	}
	public render() {
		const { isHide } = this.props.sideMenuReducer;
		return (
			<div className={"SideMenu"} style={isHide ? { width: 'auto', minWidth: 'auto' } : undefined}>
				<div className={"tac header"}>
					<img src={require('src/assets/images/logo5_wb.png')} alt="" onClick={() => this.selectSubKey({ type: 'isHide', value: !isHide })} />
					<div className="content" style={{ display: `${isHide ? 'none' : 'block'}` }}>
						<p>POI-COS</p>
						<p>客户运营平台</p>
					</div>
					<div>
						<Icon type="left" onClick={() => this.selectSubKey({ type: 'isHide', value: !isHide })} style={{ transform: `rotate(${isHide ? 180 : 0}deg)` }} />
					</div>
				</div>
				{this.MenuTool()}
			</div>
		);
	}


	public getListUserMenus = () => {
		this.props.dispatch(sideMenu_AC.getListUserMenus());
	};

	public selectSubKey({ type, value, key }: Record<string, any>) {
		const isBoradRouter = BoardRouterList.some(item => item.path === key);
		// 看板路由不显示高亮
		if (isBoradRouter) {
			return;
		}

		this.props.dispatch(sideMenu_AC.changeData(type, value))
	}

	// 根据路由类型跳转
	public pushRouter({ resourcesUri }: Record<string, any>, push: any) {
		const isBoradRouter = BoardRouterList.some(item => item.path === resourcesUri);
		// 看板路由跳转新的窗口
		if (isBoradRouter) {
			window.open(`${window.location.origin}${resourcesUri}`);
			return;
		}

    (this.props as any).dispatch(head_AC.setTitleHandler({ titleName: '', backFunc: null }));
    push(resourcesUri);
	}
}

export default connect(({ sideMenuReducer }: any) => ({ sideMenuReducer }))(SideMenu);
