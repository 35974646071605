import { actionType, defaultState } from '../actionCreators/CooperationOperationAnalysis_AC';

export const CooperationOperationAnalysisReducer = (state: Record<string, any> = defaultState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case actionType.DEFAULT_REPLACE:
			return { ...state,  ...payload };
		case actionType.INIT_DATA:
			return { ...defaultState };
		default:
			return state;
	}
}