/**
 * http axios 配置
 * HZW 20191125
 */

import axios from 'axios';
import * as Qs from 'qs';
import { message } from 'antd'

import * as storage from '../localStorage';
import * as common from '../common';
import { logout } from '../misc';
import httpClient from "../httpClient";
import { env_config } from "../common";

// axios 配置
axios.defaults.timeout = 60 * 1000;
axios.defaults.paramsSerializer = (params: any) => {
	return Qs.stringify(params, { arrayFormat: 'indices' });
};

// http request 拦截器，请求时拦截
axios.interceptors.request.use(
	async (config: any) => {
		config.headers['poit-cloud-src-client'] = config.headers['poit-cloud-src-client'] || common.ClientType;
		config.headers['poit-cloud-org'] = await storage.get(common.OrgIdTag);
		config.headers.Authorization = await storage.get(common.AccessTokenTag);
		config.headers.token = await storage.get(common.AccessTokenTag);
		return config;

	},
	(err: any) => {
		return Promise.reject(err);
	},
);

// http response 拦截器
axios.interceptors.response.use(
	(response: any) => {
		// console.log('http response 拦截器：', response, common)
		if (response.data.retCode === common.TokenInvalidCode) {
			message.warning(`请求失败-${response.data.retMsg}`);
			// token失效或无效，返回登录界面
			logout();
		} else {
			return response;
		}
	},
	(error: any) => {
		const { status, data } = error.response;
		console.log('http response 拦截器-error：', error.response)
		if (status === 500) {
			if (data.retCode === common.TokenInvalidCode) {
				message.warning(`请求失败-${data.retMsg}`);
				// token失效或无效，返回登录界面
				logout();
				return;
			} 
			return Promise.resolve({ status, ...data, retMsg: '服务器拥堵，请稍后重试' });
		}

		if (error instanceof Error) {
			switch (error.message) {
				case 'Network Error':
					error.message = '网络错误，请稍后再试';
					break;
				case 'timeout of 60000ms exceeded':
					error.message = '请求超时，请重新请求';
					break;
			}
			return Promise.resolve({ status, retMsg: error.message });
		}
		if (error.response) {
			switch (error.response.status) {
				case 401:
			}
		}
		return Promise.resolve({ status, retMsg: error.response });
});

// 返回data信息
function checkStatus(response: any) {
	// loading
	// 如果http状态码正常，则直接返回数据
	if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
		return response.data;
		// 如果不需要除了data之外的数据，可以直接 return response.data
	}
	// 异常状态下，把错误信息返回去
	return {
		retCode: '-404',
		retMsg: '网络异常',
	};
}

function checkCode(res: any) {
	// 如果code异常(这里已经包括网络错误，服务器错误，后端抛出的错误)，可以弹出一个错误提示，告诉用户
	if (!res) {
		return;
	}
	if (res.status === -404) {
		console.log(res.msg);
	}
	if (res.data && (res.data.retCode !== '0')) {
		console.log(res.data.retMsg);
	}
	return res;
}


// 检查返回API是否为当前页面API
function checkResponse(response: any) {
	checkCode(response);
	try {
		return checkStatus(response);
	} catch (err) {
		console.log('Callback Error:' + err);
	}
}

const GET = 'get';
const POST = 'post';
//这三个是用于setParams做区分的
export const BODY_TYPE_JSON_STRINGIFY = 'JSON_STRINGIFY';
export const BODY_TYPE_JSON = 'JSON';
export const BODY_TYPE_FORM_DATA = 'FORM_DATA';

const ContentTypeConfig: Record<string, string> = {
	[BODY_TYPE_JSON_STRINGIFY]: 'application/x-www-form-urlencoded; charset=UTF-8',
	[BODY_TYPE_JSON]: 'application/json; charset=UTF-8',
	[BODY_TYPE_FORM_DATA]: 'multipart/form-data'
}

const typeConfig: Record<string, any> = {
	export: { responseType: "arraybuffer" }
}


httpClient.handleRequest(async ({ method, url, body }) => {
	let require = postRequest;
	if (method === 'get') require = getRequest;

	console.log(3333, process);
	const env = process.env.REACT_APP_ENV || 'dev';
	return require(`${env_config[env]?.url.replace(/\/$/, '')}${url}`, { params: body });
});

// e.g => etRequest(api.GET_PASSWARD, { params })
export const getRequest = (url: string, option: Record<string, any> = {}): Record<string, any> => {
	return request({ method: GET, url, option });
}

// e.g => postRequest(api.GET_PASSWARD, { params, bodyType: BODY_TYPE_JSON_STRINGIFY });
export const postRequest = (url: string, options: Record<string, any> = { params: {} }): Record<string, any> => {
	return request({ method: POST, url, option: { ...options, bodyType: options.bodyType || BODY_TYPE_JSON } });
}

// client: 客户端（cloud-连接应用平台、service-数据服务平台、region-区域平台）
export const request = ({ method, url, option = {} }: any) => {
	// 参数转换
	const { client = 'region' } = option;
	const bodyType = option.bodyType || BODY_TYPE_JSON_STRINGIFY;
	const headers = { 'X-Requested-With': 'XMLHttpRequest', 'poit-cloud-src-client': client, 'Content-Type': ContentTypeConfig[bodyType] }
	const data: Record<string, any> = setParams({ method, option, bodyType, client });
	return new Promise((resolve, reject) => {
		Promise.race([
			timeoutRequest(),
			axios({ url, method, headers, ...data }),
		]).then(
			(response: any) => {
				resolve(checkResponse(response));
			},
		).catch(error => {
			console.log(error);
			reject({ retCode: 404, retMsg: error + '' });
		});;
	});
}

const setParams = ({ method, option, bodyType, client }: Record<string, any>): Record<string, any> => {
	let data: Record<string, any> = {};
	let params: Record<string, any> = makeParams(bodyType, option)

	if (method === GET) {
		data.params = { ...params, [client === 'region' ? 'regionId' : 'eid']: localStorage.getItem(common.OrgIdTag) };
	} else if (bodyType === BODY_TYPE_FORM_DATA) {
		data.data = params.formData;
		data.params = params;
	} else {
		data.data = bodyType === BODY_TYPE_JSON_STRINGIFY ? Qs.stringify(params) : params;
	}

	option.type && ( data = { ...data, ...typeConfig[option.type] } );

	return data
}
const makeParams = (bodyType: string, option: Record<string, any>) => {
	let params = {};
	// 不需解构的数据类型
	const noDeconstruction = [BODY_TYPE_FORM_DATA];
  
	if (noDeconstruction.includes(bodyType)) {
	  params = option.params;
	} else {
	  params = {
		version: common.version,
		operateUserId: localStorage.getItem('operateUserId'),
		orgId: localStorage.getItem('orgId'),
		...option.params
	  }
	}
	return params;
}

const TIMEOUT = 60 * 1000;
const timeoutRequest = () => {
	return new Promise(resolve => {
		setTimeout(() => {
			const data = {
				retCode: '-1',
				retMsg: '网络超时',
			}
			resolve(data);
		}, TIMEOUT);
	});
}
