//@ts-nocheck
    /**
 * title: POI-OAUTH
 * description: POI-OAUTH 认证中心服务 API
 * version: 0.0.1
 * contact:
 *  */
import qs from 'qs';
import {
  ChuangJianZuHuFanHuiXinXi,
  HuiShouZuHuFanHuiCanShu,
  MianMiDengLuDiZhiFanHuiCanShu,
  ApiResp_List_CloudLoginConfigResp__,
  ApiResp_int_,
  LTResp_boolean_,
  LTResp_LianTongLoginResp_,
  AccountLoginReq,
  ApiResp_YongHuDengLuXiangYingTi_,
  AccountLoginJoinOrgReq,
  ApiResp_long_,
  TokenReq,
  ApiResp_boolean_,
  ThirdLoginReqDto,
  UserIdReq,
  GetDisclaimersReq,
  ApiResp_FileInfo_,
  ImcLoginReqDto,
  MobileLoginReq,
  MobileLoginJoinOrgReq,
  SwitchUserOriginReq,
  ApiResp_UserDomainOrigin_,
  UserRegisterJoinOrgReq,
  CasDengLuQingQiuCanShuDto,
  CasQianMingQingQiuCanShuDto,
  ApiResp_CasQianMingXiangYingCanShuDto_,
  TokenRefreshReq,
  ApiResp_UserAuthResp_,
  UserNameMobileRegisterReq,
  ApiResp_YongHuMiMaGuoQiXiangYingShiTi_,
  GetVerificationReq,
  JiChu_QingQiuTi,
  ApiResp_Map_string_object__,
  organizationModifyReq,
  ApiResp,
  OrgIdReqQingQiuDuiXiang_,
  ApiResp_OrganizationDuiXiang_,
  OrgTypeReqQingQiuDuiXiang,
  ApiResp_List_OrganizationDuiXiang__,
  OrganizationIpXinZengHuoZheBianJiQingQiuDuiXiang,
  OrganizationIpIdDelReq_,
  GetOrganizationIpReq,
  ApiResp_List_OrganizationIpDuiXiang__,
  JiChuToken_QingQiuTi,
  ApiResp_UserPhoneResp_,
  UserIdListReq,
  ApiResp_List_PoitUser__,
  BindPhoneReqDuiXiang,
  HuoQuShuJuLaiYuanQingQiuTi,
  ListOrgUserListReqQingQiuDuiXiang_,
  ZhongZhiMiMaQingQiuDuiXiangGenJuShouJiHaoMa,
  UnBindPhoneReqDuiXiang,
  ResetPasswordReq,
  WeChatMpDecryptReq,
  ApiResp_string_,
} from './definitions';
import httpClient from '../../httpClient';
/**
 * 创建租户
 *
 * @param app 当前应用的名称:[应用平台:poit-cloud,服务平台:poit-service,区域平台:poit-region,运营平台:poit-operation-platform,APP平台:poit-app]
 * @param appId 应用唯一ID，一个租户可以重复购买一款软件，每次购买appId都不同
 * @param appType 类型
 * @param appVersion 客户端|请求端版本号|应用版本号
 * @param id 该次访问唯一标示符（幂等验证ID）
 * @param moduleAttribute JSON字符串
 * @param purchasedServices purchasedServices
 * @param tenantId IoT平台标识一个租户的唯一ID
 * @returns OK
 */
export const _ali_instance_create_POST = (
  app?: string,
  appId?: string,
  appType?: string,
  appVersion?: string,
  id?: string,
  moduleAttribute?: string,
  purchasedServices?: string,
  tenantId?: string,
): Promise<ChuangJianZuHuFanHuiXinXi> => {
  return httpClient.post(
    '/poit-oauth/ali/instance/create' +
      `?${qs.stringify(
        {
          app: app,
          appId: appId,
          appType: appType,
          appVersion: appVersion,
          id: id,
          moduleAttribute: moduleAttribute,
          purchasedServices: purchasedServices,
          tenantId: tenantId,
        },
        { arrayFormat: 'repeat' },
      )}`,
  );
};
/**
 * 回收租户
 *
 * @param app 当前应用的名称:[应用平台:poit-cloud,服务平台:poit-service,区域平台:poit-region,运营平台:poit-operation-platform,APP平台:poit-app]
 * @param appId 应用唯一ID，一个租户可以重复购买一款软件，每次购买appId都不同
 * @param appVersion 客户端|请求端版本号|应用版本号
 * @param id 该次访问唯一标示符（幂等验证ID）
 * @param tenantId IoT平台标识一个租户的唯一ID
 * @param userId SaaS标识一个租户的唯一ID，对应的appid不同返回的userid也不同
 * @returns OK
 */
export const _ali_instance_delete_POST = (
  app?: string,
  appId?: string,
  appVersion?: string,
  id?: string,
  tenantId?: string,
  userId?: string,
): Promise<HuiShouZuHuFanHuiCanShu> => {
  return httpClient.post(
    '/poit-oauth/ali/instance/delete' +
      `?${qs.stringify(
        {
          app: app,
          appId: appId,
          appVersion: appVersion,
          id: id,
          tenantId: tenantId,
          userId: userId,
        },
        { arrayFormat: 'repeat' },
      )}`,
  );
};
/**
 * 免密登录
 *
 * @param app 当前应用的名称:[应用平台:poit-cloud,服务平台:poit-service,区域平台:poit-region,运营平台:poit-operation-platform,APP平台:poit-app]
 * @param appId 应用唯一ID，一个租户可以重复购买一款软件，每次购买appId都不同
 * @param appVersion 客户端|请求端版本号|应用版本号
 * @param id 该次访问唯一标示符（幂等验证ID）
 * @param tenantId IoT平台标识一个租户的唯一ID
 * @param tenantSubUserId IoT平台租户组织架构中的员工唯一ID，当员工账号免登时填写
 * @param userId SaaS标识一个租户的唯一ID，对应的appid不同返回的userid也不同
 * @returns OK
 */
export const _ali_ssourl_get_POST = (
  app?: string,
  appId?: string,
  appVersion?: string,
  id?: string,
  tenantId?: string,
  tenantSubUserId?: string,
  userId?: string,
): Promise<MianMiDengLuDiZhiFanHuiCanShu> => {
  return httpClient.post(
    '/poit-oauth/ali/ssourl/get' +
      `?${qs.stringify(
        {
          app: app,
          appId: appId,
          appVersion: appVersion,
          id: id,
          tenantId: tenantId,
          tenantSubUserId: tenantSubUserId,
          userId: userId,
        },
        { arrayFormat: 'repeat' },
      )}`,
  );
};
/**
 * 登录配置列表
 *
 * @returns OK
 */
export const _cloud_config_login_list_POST = (): Promise<ApiResp_List_CloudLoginConfigResp__> => {
  return httpClient.post('/poit-oauth/cloud/config/login/list');
};
/**
 * 登录配置开关
 *
 * @returns OK
 */
export const _cloud_config_login_switch_POST = (): Promise<ApiResp_int_> => {
  return httpClient.post('/poit-oauth/cloud/config/login/switch');
};
/**
 * 添加子账号
 *
 * @param token token
 * @returns OK
 */
export const _lian_tong_add_GET = (token: string): Promise<LTResp_boolean_> => {
  return httpClient.get(
    '/poit-oauth/lian/tong/add' + `?${qs.stringify({ token: token }, { arrayFormat: 'repeat' })}`,
  );
};
/**
 * 联通云跳转地址:免密跳转地址
 *
 * @param token token
 * @returns OK
 */
export const _lian_tong_get_login_uri_GET = (token: string): Promise<LTResp_LianTongLoginResp_> => {
  return httpClient.get(
    '/poit-oauth/lian/tong/get/login/uri' +
      `?${qs.stringify({ token: token }, { arrayFormat: 'repeat' })}`,
  );
};
/**
 * 登出
 *
 * @param token token
 * @returns OK
 */
export const _lian_tong_logout_GET = (token: string): Promise<LTResp_boolean_> => {
  return httpClient.get(
    '/poit-oauth/lian/tong/logout' +
      `?${qs.stringify({ token: token }, { arrayFormat: 'repeat' })}`,
  );
};
/**
 * 账号登陆
 *
 * @param req req
 * @returns OK
 */
export const _oauth_account_login_POST = (
  req: AccountLoginReq,
): Promise<ApiResp_YongHuDengLuXiangYingTi_> => {
  return httpClient.post('/poit-oauth/oauth/account/login', { body: req });
};
/**
 * 用户以登录方式加入到-企业
 *
 * @param req req
 * @returns OK
 */
export const _oauth_account_login_join_org_POST = (
  req: AccountLoginJoinOrgReq,
): Promise<ApiResp_long_> => {
  return httpClient.post('/poit-oauth/oauth/account/login/join-org', { body: req });
};
/**
 * 用户退出-清除token
 *
 * @param req req
 * @returns OK
 */
export const _oauth_account_loginOut_POST = (req: TokenReq): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-oauth/oauth/account/loginOut', { body: req });
};
/**
 * 阿里云免密登录:内部调用
 *
 * @param req req
 * @returns OK
 */
export const _oauth_aliyun_login_POST = (
  req: ThirdLoginReqDto,
): Promise<ApiResp_YongHuDengLuXiangYingTi_> => {
  return httpClient.post('/poit-oauth/oauth/aliyun/login', { body: req });
};
/**
 * 校验token
 *
 * @param req req
 * @returns OK
 */
export const _oauth_check_token_POST = (req: TokenReq): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-oauth/oauth/check/token', { body: req });
};
/**
 * 根据用户id检查用户密码强度是否符合审计要求，true : 符合， false：不符合
 *
 * @param req req
 * @returns OK
 */
export const _oauth_checkUserPassword_POST = (req: UserIdReq): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-oauth/oauth/checkUserPassword', { body: req });
};
/**
 * 删除用户所有的token
 *
 * @param req req
 * @returns OK
 */
export const _oauth_delete_token_byUserId_POST = (req: UserIdReq): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-oauth/oauth/delete/token/byUserId', { body: req });
};
/**
 * 获取免责声明
 *
 * @param req req
 * @returns OK
 */
export const _oauth_disclaimers_get_POST = (req: GetDisclaimersReq): Promise<ApiResp_FileInfo_> => {
  return httpClient.post('/poit-oauth/oauth/disclaimers/get', { body: req });
};
/**
 * 华为工业去平台IMC免密登录
 *
 * @param req req
 * @returns OK
 */
export const _oauth_imc_login_POST = (
  req: ImcLoginReqDto,
): Promise<ApiResp_YongHuDengLuXiangYingTi_> => {
  return httpClient.post('/poit-oauth/oauth/imc/login', { body: req });
};
/**
 * 联通云免密登录:内部调用
 *
 * @param req req
 * @returns OK
 */
export const _oauth_liantong_login_POST = (
  req: ThirdLoginReqDto,
): Promise<ApiResp_YongHuDengLuXiangYingTi_> => {
  return httpClient.post('/poit-oauth/oauth/liantong/login', { body: req });
};
/**
 * 手机验证码登录
 *
 * @param req req
 * @returns OK
 */
export const _oauth_mobile_login_POST = (
  req: MobileLoginReq,
): Promise<ApiResp_YongHuDengLuXiangYingTi_> => {
  return httpClient.post('/poit-oauth/oauth/mobile/login', { body: req });
};
/**
 * 用户以登录方式加入到-企业
 *
 * @param req req
 * @returns OK
 */
export const _oauth_mobile_login_join_org_POST = (
  req: MobileLoginJoinOrgReq,
): Promise<ApiResp_long_> => {
  return httpClient.post('/poit-oauth/oauth/mobile/login/join-org', { body: req });
};
/**
 * 依据账号-切换混合云domain
 *
 * @param req req
 * @returns OK
 */
export const _oauth_origin_switch_get_POST = (
  req: SwitchUserOriginReq,
): Promise<ApiResp_UserDomainOrigin_> => {
  return httpClient.post('/poit-oauth/oauth/origin/switch/get', { body: req });
};
/**
 * 用户以注册形式加入到--企业
 *
 * @param req req
 * @returns OK
 */
export const _oauth_register_join_org_POST = (
  req: UserRegisterJoinOrgReq,
): Promise<ApiResp_long_> => {
  return httpClient.post('/poit-oauth/oauth/register/join-org', { body: req });
};
/**
 * 登录
 *
 * @param req req
 * @returns OK
 */
export const _oauth_third_cas_login_POST = (
  req: CasDengLuQingQiuCanShuDto,
): Promise<ApiResp_YongHuDengLuXiangYingTi_> => {
  return httpClient.post('/poit-oauth/oauth/third/cas/login', { body: req });
};
/**
 * 签名
 *
 * @param req req
 * @returns OK
 */
export const _oauth_third_cas_sign_POST = (
  req: CasQianMingQingQiuCanShuDto,
): Promise<ApiResp_CasQianMingXiangYingCanShuDto_> => {
  return httpClient.post('/poit-oauth/oauth/third/cas/sign', { body: req });
};
/**
 * token续费
 *
 * @param req req
 * @returns OK
 */
export const _oauth_token_refresh_POST = (req: TokenRefreshReq): Promise<ApiResp_UserAuthResp_> => {
  return httpClient.post('/poit-oauth/oauth/token/refresh', { body: req });
};
/**
 * 手机号|用户名 联合注册
 *
 * @param req req
 * @returns OK
 */
export const _oauth_union_register_POST = (
  req: UserNameMobileRegisterReq,
): Promise<ApiResp_long_> => {
  return httpClient.post('/poit-oauth/oauth/union/register', { body: req });
};
/**
 * 根据用户id检查用户密码强度是否已经过了有效期
 *
 * @param req req
 * @returns OK
 */
export const _oauth_userPassword_exprired_POST = (
  req: UserIdReq,
): Promise<ApiResp_YongHuMiMaGuoQiXiangYingShiTi_> => {
  return httpClient.post('/poit-oauth/oauth/userPassword/exprired', { body: req });
};
/**
 * 获取手机号验证码
 * 获取手机号验证码
 * @param req req
 * @returns OK
 */
export const _oauth_verification_code_get_POST = (
  req: GetVerificationReq,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-oauth/oauth/verification/code/get', { body: req });
};
/**
 * 图形验证码
 *
 * @param req req
 * @returns OK
 */
export const _oauth_verification_img_get_POST = (
  req: JiChu_QingQiuTi,
): Promise<ApiResp_Map_string_object__> => {
  return httpClient.post('/poit-oauth/oauth/verification/img/get', { body: req });
};
/**
 * 修改ip开启状态
 *
 * @param req req
 * @returns OK
 */
export const _organization_modify_POST = (req: organizationModifyReq): Promise<ApiResp> => {
  return httpClient.post('/poit-oauth/organization/modify', { body: req });
};
/**
 * 通过组织id获取详情
 *
 * @param req req
 * @returns OK
 */
export const _organization_orgId_get_POST = (
  req: OrgIdReqQingQiuDuiXiang_,
): Promise<ApiResp_OrganizationDuiXiang_> => {
  return httpClient.post('/poit-oauth/organization/orgId/get', { body: req });
};
/**
 * 查询用户所在的机构列表,简要数据
 *
 * @param req req
 * @returns OK
 */
export const _organization_user_org_list_POST = (
  req: OrgTypeReqQingQiuDuiXiang,
): Promise<ApiResp_List_OrganizationDuiXiang__> => {
  return httpClient.post('/poit-oauth/organization/user/org-list', { body: req });
};
/**
 * 添加机构ip表信息
 *
 * @param req req
 * @returns OK
 */
export const _organizationIp_add_POST = (
  req: OrganizationIpXinZengHuoZheBianJiQingQiuDuiXiang,
): Promise<ApiResp_long_> => {
  return httpClient.post('/poit-oauth/organizationIp/add', { body: req });
};
/**
 * 删除机构ip表信息
 *
 * @param req req
 * @returns OK
 */
export const _organizationIp_delete_POST = (
  req: OrganizationIpIdDelReq_,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-oauth/organizationIp/delete', { body: req });
};
/**
 * 根据orgId获取ip列表
 *
 * @param req req
 * @returns OK
 */
export const _organizationIp_getByOrgId_POST = (
  req: GetOrganizationIpReq,
): Promise<ApiResp_List_OrganizationIpDuiXiang__> => {
  return httpClient.post('/poit-oauth/organizationIp/getByOrgId', { body: req });
};
/**
 * 修改机构ip表信息
 *
 * @param req req
 * @returns OK
 */
export const _organizationIp_modify_POST = (
  req: OrganizationIpXinZengHuoZheBianJiQingQiuDuiXiang,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-oauth/organizationIp/modify', { body: req });
};
/**
 * 获取手机号信息进行判断是否绑定手机号了
 *
 * @param req req
 * @returns OK
 */
export const _user_acquire_phone_POST = (
  req: JiChuToken_QingQiuTi,
): Promise<ApiResp_UserPhoneResp_> => {
  return httpClient.post('/poit-oauth/user/acquire/phone', { body: req });
};
/**
 * 根据orgId查询企业用户列表-[天机报表]
 *
 * @param req req
 * @returns OK
 */
export const _user_batch_ids_POST = (req: UserIdListReq): Promise<ApiResp_List_PoitUser__> => {
  return httpClient.post('/poit-oauth/user/batch/ids', { body: req });
};
/**
 * 用户绑定手机号
 *
 * @param req req
 * @returns OK
 */
export const _user_bind_phone_POST = (req: BindPhoneReqDuiXiang): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-oauth/user/bind/phone', { body: req });
};
/**
 * 根据登录参数获取数据来源
 *
 * @param req req
 * @returns OK
 */
export const _user_get_origin_by_param_POST = (
  req: HuoQuShuJuLaiYuanQingQiuTi,
): Promise<ApiResp_int_> => {
  return httpClient.post('/poit-oauth/user/get/origin/by/param', { body: req });
};
/**
 * 根据orgId查询企业用户列表-[天机报表]
 *
 * @param req req
 * @returns OK
 */
export const _user_org_user_list_POST = (
  req: ListOrgUserListReqQingQiuDuiXiang_,
): Promise<ApiResp_List_PoitUser__> => {
  return httpClient.post('/poit-oauth/user/org/user-list', { body: req });
};
/**
 * 重置密码（需要输入手机验证码）
 *
 * @param req req
 * @returns OK
 */
export const _user_phone_password_reset_POST = (
  req: ZhongZhiMiMaQingQiuDuiXiangGenJuShouJiHaoMa,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-oauth/user/phone/password/reset', { body: req });
};
/**
 * 用户解绑检查|校验
 *
 * @param req req
 * @returns OK
 */
export const _user_unbind_phone_validate_POST = (
  req: UnBindPhoneReqDuiXiang,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-oauth/user/unbind/phone/validate', { body: req });
};
/**
 * 用户自主修改密码
 *
 * @param req req
 * @returns OK
 */
export const _user_userId_password_modify_POST = (
  req: ResetPasswordReq,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-oauth/user/userId/password/modify', { body: req });
};
/**
 * 微信小程序密文揭秘
 *
 * @param req req
 * @returns OK
 */
export const _wechat_mp_decrypt_POST = (req: WeChatMpDecryptReq): Promise<ApiResp_string_> => {
  return httpClient.post('/poit-oauth/wechat/mp/decrypt', { body: req });
};
