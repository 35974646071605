interface IRouterConfigModel {
	name?: string;
	path: string;
	icon?: string;
	component?: string;
  	children?: Array<Record<string, any>>;
	component_to?: string;
	routes?: any;
	exact?: boolean;
}
let RouterConfig: IRouterConfigModel[] = [
	{
		path: "/",
		component: "components/common/LoginError",
		exact: true
	},
	{
		path: "/Login",
		component: "components/common/Login",
		exact: true
	}
];


const funcChildrenRouterConfig = {
	// 总看板
	mainBoardRouter: [
		{
			name: "运营指标看板",
			path: "/operation_indicator_board",
			component: "components/lib/menu/OperationIndicatorBoard",
		},
		{
			name: "研发价值看板",
			path: "/value_board",
			component: "components/lib/menu/ValueBoard",
		},
	],
	// 数据配置子路由配置
	DataConfigRouter: [
		{
		  name: "运营看板数据维护",
		  path: "/exhibition_data_maintenance",
		  component: "components/lib/menu/ExhibitionDataMaintenance"
		},
		{
		  name: "研发价值看板配置",
		  path: "/bord_config",
		  component: "components/lib/menu/BordConfig"
		},
	],

	// 企业分析
	enterpriseAnalysis: [
		{
		  name:'企业明细',
		  path:'/enterprise_detail',
		  component:'components/lib/menu/EnterpriseDetail',
		},
    {
      name:'客户管理',
      path:'/customer-management',
      component:'components/lib/menu/customer-management',
    },
	],
	// 产品分析模块
	productAnalysis: [
		{
		  name:'整体概况',
		  path:'/overall_overview',
		  component:'components/lib/menu/OverallOverview',
		},
		{
		  name:'操作明细',
		  path:'/operation_detail',
		  component:'components/lib/menu/product-analysis/operation-detail.tsx',
		},
		{
		  name:'版本需求统计',
		  path:'/version_requirement_statistics',
		  component:'components/lib/menu/product-analysis/version-requirement-statistics.tsx',
		},
	],
  // 项目管理模块
	programManage: [
		{
		  name:'研发项目汇总',
		  path:'/program_gather',
		  component:'components/lib/menu/programManage/program-gather.tsx',
		},
		{
		  name:'工艺云项目明细表',
		  path:'/program_detail',
		  component:'components/lib/menu/programManage/program-detail.tsx',
		},
		{
			name:'环节进度',
			path:'/program_progress',
			component:'components/lib/menu/programManage/program-progress.tsx',
    },
	],
  companyOperation:[
    {
      name:'产研指标',
      path:'/production-and-research-indicators',
      component:'components/lib/menu/company-operation/production-and-research-indicators.tsx',
    },
  ],

	// 权限管理
	PermissionManagementRouter: [
		{
			name: "用户管理",
			path: "/userManagement",
			component: "components/lib/menu/UserManagement"
		},
		{
			name: "角色管理",
			path: "/roleManagement",
			component: "components/lib/menu/RoleManagement"
		},
	]
};

/* 功能路由配置 start */
export const FunctionalRouter: IRouterConfigModel[] = [
	{
		name:'总看板',
		path:'/totalMenu',
		icon: 'poi-zongkanban',
		children: funcChildrenRouterConfig.mainBoardRouter,
	},
	{
		name:'企业分析',
		path:'/enterpriseAnalysis',
		icon: 'poi-qiyefenxi',
		children: funcChildrenRouterConfig.enterpriseAnalysis,
	},
	{
		name:'产品分析',
		path:'/productAnalysis',
		icon: 'poi-chanpinfenxi',
		children: funcChildrenRouterConfig.productAnalysis,
	},
	{
		name:'项目管理',
		path:'/programManage',
		icon: 'poi-xiangmuguanli',
		children: funcChildrenRouterConfig.programManage,
	},
	{
		name: "数据配置",
		path: "/dataConfig",
		icon: 'poi-shujupeizhi',
		children: funcChildrenRouterConfig.DataConfigRouter,
	},
	{
		name: "权限配置",
		path: "/permissionManagement",
		icon: 'poi-quanxianguanli',
		children: funcChildrenRouterConfig.PermissionManagementRouter,
	},
	// {
	// 	name: "客户企业管理",
	// 	path: "/customer_enterp_manage",
	// 	component: "components/lib/menu/CustomerEnterpManage"
	// },
	// {
	// 	name: "客户项目管理",
	// 	path: "/customer_proj_manage",
	// 	component: "components/lib/menu/CustomerProjectManage"
	// },
	// {
	// 	name: "运营商管理",
	// 	path: "/agency_carrierManage",
	// 	component: "components/lib/menu/AgencyCarrierManage"
	// },
	// {
	// 	name: "项目实施结果管理",
	// 	path: "/systom_proj_result_manage",
	// 	component: "components/lib/menu/SystomProjResultManage"
	// },
	// {
	// 	name: "项目存在问题管理",
	// 	path: "/systom_proj_prob_manage",
	// 	component: "components/lib/menu/SystomProjProbManage"
	// },
	// {
	// 	name: "部门管理",
	// 	path: "/department_manage",
	// 	component: "components/lib/menu/DepartmentManage"
	// },
	// //  {
	// // 	name: "菜单管理",
	// // 	path: "/prod_menuManage",
	// // 	component: "components/lib/menu/ProdMenuManage"
	// // },
	// //  {
	// // 	name: "角色权限管理",
	// // 	path: "/role_ermissions_manage",
	// // 	component: "components/lib/menu/RolePermissionsManage"
	// // },
	// {
	// 	name: "用户数据分析",
	// 	path: "/user_data_analysis",
	// 	component: "components/lib/menu/UserDataAnalysis"
	// },{
	// 	name: "页面数据分析",
	// 	path: "/pages_data_analysis",
	// 	component: "components/lib/menu/PagesDataAnalysis"
	// },
	// {
	// 	name: "用户管理",
	// 	path: "/user_manage",
	// 	component: "components/lib/menu/UserManage"
	// },
	// {
	// 	name: "运营数据看板",
	// 	path: "/operation_index_board",
	// 	component: "components/lib/menu/OperationIndexBoard"
	// },
	// {
	// 	name: "运营排名看板",
	// 	path: "/operation_rank_board",
	// 	component: "components/lib/menu/OperationRankBoard"
	// },
	// {
	// 	name: "协作运营分析",
	// 	path: "/cooperation_operation_analysis",
	// 	component: "components/lib/menu/CooperationOperationAnalysis"
	// },
	// {
	// 	name: "协作事项管理",
	// 	path: "/cooperation_event_management",
	// 	component: "components/lib/menu/CooperationEventManagement"
	// },
	// {
	// 	name: "短信推送",
	// 	path: "/tools_sms_push",
	// 	component: "components/lib/menu/SMSPush"
	// },
];

const FuncChildrenRouter: IRouterConfigModel[] = [];
Object.values(funcChildrenRouterConfig).map(arr => arr.map(item => FuncChildrenRouter.push(item)));
export { FuncChildrenRouter };

// 加上功能主体框架 Main
// FunctionalRouter = JSON.parse(JSON.stringify(FunctionalRouter)
// 	.replace(/("component_to")/g, `"component": "components/common/MainFun",$1`));

export const BoardRouterList: any[] = [
  {path: "/operation_indicator_board"},
  {path: "/value_board"},
];

/* 看板路由配置 end */


RouterConfig = [
	//@ts-ignore
	...RouterConfig,  // 基础路由
	...(FunctionalRouter.filter(item => !item.children)),  // 功能路由
  	...FuncChildrenRouter,
	{  // 其他路由
		path: "/*",
		component: "components/common/LoginError",
		exact: true
	}
];

export default RouterConfig;
