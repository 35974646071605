import { dispatchFun } from 'src/assets/js/com';
import { postRequest } from 'src/assets/js/apiConfig/request';
import { Api } from 'src/assets/js/apiConfig/urlConfig';
import { message } from 'antd';


export const actionType: Record<string, symbol> = {
	DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
	INIT_DATA: Symbol('INIT_DATA')
}
export const defaultState: Record<string, any> = {
	loading: false,
	dataSource: [],
	detailList: [],
	orgId: '',
	orgFullName: '',

	isDetail: false,
	event: 'share',
}

const type = actionType.DEFAULT_REPLACE;

export const searchTable = ({ dateTime, keyWordObj, event }: Record<string, any>) => {
	return async (dispatch: any, getState: any) => {
		dispatch({ type, payload: { loading: true } })
		const { isDetail, orgId } = getState().CooperationOperationAnalysisReducer;
		let params: Record<string, any> = {
			"beginTime": `${dateTime[0]} 00:00:00`,
			"endTime": `${dateTime[1]} 23:59:59`,
			...keyWordObj,
		};

		isDetail && ( params.orgId = orgId );

		const { retMsg, retCode, data } = await postRequest(Api.cooperation[ !isDetail ? 'MATTERANALYSIS_LIST' :  event === 'share' ? 'MATTERANALYSIS_PAGE' : 'MATTERANALYSIS_DEVICE' ], { params });
		if (retCode !== '0') {
			dispatch({ type, payload: { loading: false, event, [ isDetail ? 'detailList' : 'dataSource' ]: [] } })
			message.error(retMsg);
			return;
		}
		dispatchFun({ dispatch, type, res: { loading: false, event, [ isDetail ? 'detailList' : 'dataSource' ]: data } })
	}
}

export const changeDataArr = ( payload: Record<string, any> ) => {
	return (dispatch: any) => {
		dispatch({ type, payload });
	};
}

export const initData = () => {
	return (dispatch: any) => {
		dispatch({ type: actionType.INIT_DATA });
	};
}
