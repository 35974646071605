import { actionType } from '../actionCreators/agencyCarrierManage_AC';

const defaultState: Record<string, any> = {
	loading: true,

	isChange: false,
	dataSource: [],

	roleOptions: [],
	areaOptions: [],//{ area: '广州市' }, { area: '广州市1' }, { area: '广州市2' }
	orgDetailInfo: {},//弹窗查看的时候调用
	dataConfig: {},
	oriDataConfig: {
		shortName: '',
		allName: '',
		code: '',
		area: '',
		address: '',
		name: '',
		phone: '',
		creatName: '',
		creatTime: '',
		updataTime: '',
		manage_name: '',
		manage_phone: '',
		manage_user: '',
		manage_roles: '',
	},

	visible: false,
	modalType: 'add',
};
defaultState.roleOptions = [
	{ label: '角色1', value: 1, },
	{ label: '角色3', value: 10, },
	{ label: '角色2', value: 112, }];
export const AgencyCarrierManageReducer = (state: Record<string, any> = defaultState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case actionType.DEFAULT_REPLACE:
			return { ...state, ...payload };
		default:
			return state;
	}
}