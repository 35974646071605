import { dispatchFun, RegExpReport } from 'src/assets/js/com';

import { Api } from 'src/assets/js/apiConfig/urlConfig';
import { postRequest } from 'src/assets/js/apiConfig/request';
import { message } from 'antd';

export const actionType: Record<string, symbol> = {
	DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
	INIT_DATA: Symbol('INIT_DATA'),
}

const type = actionType.DEFAULT_REPLACE;

export const search = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerEnterpManage.search, { params });

		if (retCode !== '0') { message.error(retMsg); return; }

		// message.success(retMsg);
		const obj = { dataSource: res.list, loading: false };
		dispatchFun({ dispatch, type, res: obj })
	};
}

//机构下用户信息
export const directorLit = (params?: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.department.directorLit, { params });
		if (retCode !== '0') { message.error(retMsg); return; }
		res.data.map((item: Record<string, any>) => {
			item.label = item.nickName;
			item.value = item.userId;
			return item;
		})
		const obj = { orgIdUserOptions: res.data };
		dispatchFun({ dispatch, type, res: obj })
	};
}
//修改企业信息
export const modifyEnter = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerEnterpManage.modifyEnter, { params });

		if (retCode !== '0') { message.error(retMsg); return; }

		console.log(res);
		const obj = { visible: false };
		dispatchFun({ dispatch, type, res: obj })
	};
}
export const emenuList = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerEnterpManage.emenuList, { params });

		if (retCode !== '0') { message.error(retMsg); return; }


		res.data.levelList.map((item: { label: any; desc: any; value: any; code: any; }) => {
			item.label = item.desc;
			item.value = item.code;
			return item;
		})
		res.data.popertyList.map((item: { label: any; desc: any; value: any; code: any; }) => {
			item.label = item.desc;
			item.value = item.code;
			return item;
		}
		)


		const obj = { institutionsOptions: res.data.levelList, popertyList: res.data.popertyList };
		dispatchFun({ dispatch, type, res: obj })
	};
}
export const newContactInfo = ({ data }: Record<string, any>) => {
	// dispatchFun({ dispatch, type, res: obj })
	console.log(data)
	return async (dispatch: any) => {
		const obj = {
			contactInfoList: data
		};
		dispatchFun({ dispatch, type, res: obj })
	}
}

//所属行业树
export const tradeTree = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerEnterpManage.tradeTree, { params });

		if (retCode !== '0') { message.error(retMsg); return; }
		if (res.tradeModelList) {
			const tradeTreeOptions = RegExpReport(res.tradeModelList, ["tradeName", "tradeId", "childTrade"], ["label", "value", "children"])
			const obj = { tradeTreeOptions };
			dispatchFun({ dispatch, type, res: obj })
		}
	};
}
//区域树
export const geoLlist = (params?: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.department.geoLlist, { params });
		// if (retCode !== '0') { message.error(retMsg); return; }
		if (res.provinces) {
			res.provinces = JSON.parse(JSON.stringify(res.provinces).replace(/("countyCode":"\d+",)("cityCode":"\d+",)/g, '$1'));
			const areaOptions = RegExpReport(res.provinces,
				['provinceName', 'provinceCode', 'cities', 'cityName', 'cityCode', 'countyList', 'countyName', 'countyCode', /\[\]/],
				['label', 'value', 'children', 'label', 'value', 'children', 'label', 'value', null]);
			const obj = { areaOptions };
			dispatchFun({ dispatch, type, res: obj })
		}
	};
}
//文件列表
export const fileList = (params: Record<string, any>, orgId?: any) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.file.list, { params });

		if (retCode !== '0') { message.error(retMsg); return; }
		// uid: '-1',
		// name: 'xxx.png',
		// status: 'done',
		// url: 'http://www.baidu.com/xxx.png',

		res.data.map((item: Record<string, any>, index: any, ) => {
			item.status = "done";
			// item.url = item.storagePath;
			item.name = item.originName;
			item.uid = item.fileId;
			return item;
		})

		const obj = { attachmentFileList: res.data, orgId };
		dispatchFun({ dispatch, type, res: obj })
	};
}
//修改联系人
export const modify = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerEnterpManage.modify, { params });

		if (retCode !== '0') { message.error(retMsg); return; }

		console.log(res);
		// const obj = { visible: false };
		// dispatchFun({ dispatch, type, res: obj })
	};
}
//新增联系人
export const addContactInfo = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerEnterpManage.add, { params });

		console.log(res);
		// if (retCode !== '0') { message.error(retMsg); return; }
		// const obj = {
		// 	contactInfoList: res.data
		// };
		// dispatchFun({ dispatch, type, res: obj })
	};
}

//联系人列表
export const contactList = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerEnterpManage.contactList, { params });

		if (retCode !== '0') { message.error(retMsg); return; }

		res.data.map((item: Record<string, any>, index: any, ) => {
			item.label = item.userName;
			item.value = item.userId;
			item.key = index;
			return item;
		})
		const obj = { contactInfoList: res.data, modalLoading: false };
		dispatchFun({ dispatch, type, res: obj })
	};
}
export const deleteData = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg }: any = await postRequest(Api.customerEnterpManage.delete, { params });
		if (retCode !== '0') { message.error(retMsg); return; }
		// message.success(retMsg);
	};
}

export const openModal = ({ modalType, visible, record, associatedId, modalLoading }: Record<string, any>) => {
	let obj = {
		visible: { visible: true, },
		add: { addVisible: true, },
		attachment: { attachmentVisible: true },
		contactInfo: { contactInfoVisible: true }
	}
	const popup: object = obj[visible];
	// console.log(record);
	return async (dispatch: any) => {
		dispatchFun({ dispatch, type, res: { ...popup, closedModal: visible, dataConfig: record, modalType, associatedId, modalLoading } })
	}
}

export const modalCallBack = ({ data }: Record<string, any>) => {
	let obj = {
		visible: { visible: false, },
		add: { addVisible: false, },
		attachment: { attachmentVisible: false },
		contactInfo: { contactInfoVisible: false }
	}
	return async (dispatch: any, getState: any) => {
		// isChange
		const { oriDataConfig, closedModal, dataSource, modalType } = getState().CustomerEnterpManageReducer;
		const popup: object = obj[closedModal];

		const res: Record<string, any> = { ...popup, dataConfig: oriDataConfig, dataSource };

		if (modalType === 'updata') {
			res.dataSource.push(data);
			// res.isChange = !isChange;
		}
		dispatchFun({ dispatch, type, res })
	}
}



export const ContactList = ({ data }: Record<string, any>) => {

	return async (dispatch: any, getState: any) => {

		const res: Record<string, any> = { contactInfoList: data }

		dispatchFun({ dispatch, type, res })
	}
}