
import * as storage from './localStorage'
import * as common from './common'

// 获得当前页面的地址 (URL)，并根据参数路径新建窗口
function openWindow(path:string) {
  const url = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + path;
  window.open(url);
  return;
}

// 延迟重新加载
function delayReload(time = 500) {
  setTimeout(function () {
    window.location.reload();
  }, time);
  return;
}

function logout(org?: string) {
  console.log('退出登录')
  clearStorageExcept();
  localStorage.clear();
  console.log(org)
  if(org){
    storage.set(common.OrgTag, org)
  }
  if(window.location.pathname !== '/Login'){
    window.location.pathname = '/Login';
  }
 
}

// 清除除project外的storage
function clearStorageExcept() {
  // 清除LocalStorage
  for (const tag of common.DelLocalStorageTag) {
    storage.del(tag);
  }
}

// 更新企业模型
function delRedMsg(data:any) {
  const tag = ['retCode', 'retMsg']
  for (const val of tag) {
    delete data[val]
  }
  return data
}

// 检查对象是否为空
function checkNotEmpty(obj:any) {
	let res = false;
	if((obj !=='')&&(obj !==undefined)&&(obj !==null)){
		res = true;
  }
	return res;
}

// 检查数组中是否有为空
function checkArrNotEmpty(arr:any) {
  for(const val of arr){
    if(!checkNotEmpty(val)){
      return false;
    }
  }
	return true;
}

// 根据地址打开应用
function openApp(path:string) {
  // window.open(path, '_blank')
  window.open(path, '_self')
}

/**
 * 拼接对象为请求字符串
 * @param {Object} obj - 待拼接的对象
 * @returns {string} - 拼接成的请求字符串
 */
function encodeSearchParams(obj:any) {
  const params:any = []

  Object.keys(obj).forEach((key) => {
    let value = obj[key]
    // 如果值为undefined我们将其置空
    if (typeof value === 'undefined') {
      value = ''
    }
    // 对于需要编码的文本（比如说中文）我们要进行编码
    params.push([key, encodeURIComponent(value)].join('='))
  })

  return '?' + params.join('&')
}


export {
  openWindow,
  delayReload,
  logout,
  clearStorageExcept,
  openApp,
  checkNotEmpty,
  checkArrNotEmpty,
  encodeSearchParams,
  delRedMsg
}

