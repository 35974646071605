
import {postRequest} from "../../assets/js/apiConfig/request";
import {Api} from "../../assets/js/apiConfig/urlConfig";
import {message} from "antd";
import { getSevenDayActiveNum } from "src/assets/js/httpcomfunction";

export const actionType: Record<string, symbol> = {
  INIT_DATA: Symbol('INIT_DATA'),
  DATA_UPDATE: Symbol('DATA_UPDATE'),
}

export const defaultState: Record<string, any> = {
  loading: false,
  boardItem: {},
  listPageRankItem: [],
  newPageRankListItem: [],
  listDayUserVisitItem: [],
  pageInfoFeatureTrendListItem: [],
};


const dataUpdate = actionType.DATA_UPDATE;

export const getBoardItem = (params: Record<string, string>) => {
  return async (dispatch: any, getState: any) => {
    const { boardItem } = getState().ValueBoardReducer;
    const { retCode, retMsg, data }: any = await postRequest(Api.pageInfoController.getConfig, { params });
    if (retCode !== '0') { message.error(retMsg); return; }
    // console.log(data);
    const { key, value } = data || {};
    let JsonVal: Record<string, any> = {};
    try {
      JsonVal = JSON.parse(value);
    } catch (e) { console.log(e);}
    const res = { boardItem: { ...boardItem, [key]: JsonVal } };
    dispatch({ type: dataUpdate, payload: { ...res } });
    return res.boardItem;
  };
};

export const getListPageRank = (params: Record<string, string>) => {
  return async (dispatch: any) => {
    const { retCode, retMsg, data }: any = await postRequest(Api.pageInfoController.productTop10, { params: { ...params, appType: params.platform === 'PC' ? 3600 : 3200 } });
    if (retCode !== '0') { message.error(retMsg); return; }
    const res = { listPageRankItem: data || [] };
    dispatch({ type: dataUpdate, payload: { ...res } });
    return res;
  };
};

export const getNewPageRankList = (params: Record<string, string>) => {
  return async (dispatch: any) => {
    const { retCode, retMsg, data }: any = await postRequest(Api.pageInfoController.newProductTop6, { params: { ...params, appType: params.platform === 'PC' ? 3600 : 3200 } });
    if (retCode !== '0') { message.error(retMsg); return; }
    const res = { newPageRankListItem: data || [] };
    dispatch({ type: dataUpdate, payload: { ...res } });
    return res;
  };
};

export const getListDayUserVisit = (params: Record<string, string>) => {
  return async (dispatch: any) => {

    const listDayUserVisitItem = await getSevenDayActiveNum();
    console.log(3344, listDayUserVisitItem);
    const res = { listDayUserVisitItem };
    dispatch({ type: dataUpdate, payload: { ...res } });
    return res;
  };
};


export const getPageInfoFeatureTrendList = (params: Record<string, string | number>) => {
  return async (dispatch: any) => {
    const url = params.featureType === "1" ? Api.pageInfoController.sevenDayAllAccessTop6 : Api.pageInfoController.sevenDayNewAccessTop6;
    const { retCode, retMsg, data }: any = await postRequest(url, {params: { appType: params.platform === 'PC' ? 3600 : 3200 } });
    if (retCode !== '0') { message.error(retMsg); return; }
    const res = { pageInfoFeatureTrendListItem: data || {} };
    dispatch({ type: dataUpdate, payload: { ...res } });
    return res;
  };
};
