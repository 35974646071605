// import { getRequest, api } from 'src/assets/js/apiConfig/request';
import { dispatchFun } from 'src/assets/js/com';
// import { message } from 'antd';

export const actionType: Record<string, symbol> = {
   DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
}

const type = actionType.DEFAULT_REPLACE;

export const openModal = ({ modalType, record }: Record<string, any>) => {
	return async (dispatch: any, getState: any) => {
      const { oriDataConfig } = getState().ProdMenuManageReducer;
		dispatchFun({ dispatch, type, res: { visible: true, dataConfig: record || oriDataConfig, modalType } })
	}
}

export const modalCallBack = ({ modalType, data }: Record<string, any>) => {
	return async (dispatch: any, getState: any) => {
		const { oriDataConfig, dataSource, isChange } = getState().ProdMenuManageReducer;
		const res: Record<string, any> = { visible: false, dataConfig: oriDataConfig, dataSource };
		if (modalType === 'updata') {
			res.dataSource.push(data);
			res.isChange = !isChange;
		}
		dispatchFun({ dispatch, type, res })
	}
}

export const searchTable = ({ product, keyWord }: Record<string, any>) => {
	return async (dispatch: any, getState: any) => {
		const { isSearch } = getState().ProdMenuManageReducer;
      console.log(product, keyWord)
		dispatchFun({ dispatch, type, res: { isSearch: !isSearch } })
	}
}