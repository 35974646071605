import { RsaPublicKey } from './common';
import JSEncrypt from 'jsencrypt'
import moment from 'moment';
import { message } from 'antd';
import { RangePickerPresetRange } from 'antd/lib/date-picker/interface';

// 设置日期/月份/年份加减、设置返回格式
const dateMessage = (date: Date, days: number = 1, format: string = "ymdhms", messageType: string = 'day', type: string = '-'): string => {

  if (days === undefined || days === null) {
    days = 1;
  }

  const newDate = dateInterval(date, messageType, days);

  const resDate = dateTimeFormat(newDate, format, type)

  return resDate;
}

const getInitDate = (arg: number): string => {
  if (!arg && arg !== 0) {
    return "";
  }
  let data: string = arg.toString();
  if (data.length < 2) {
    data = `0${data}`;
  }

  return data;
};

// 将指定年/月/日 加减
const dateInterval = (date: Date, messageType: string, days: number) => {
  const newDate = new Date(date);
  if (messageType === "day") {
    newDate.setDate(date.getDate() + days);
  } else if (messageType === "month") {
    newDate.setMonth(date.getMonth() + days);
  } else if (messageType === "year") {
    newDate.setFullYear(date.getFullYear() + days);
  } else if (messageType === "hour") {
    newDate.setHours(date.getHours() + days);
  } else if (messageType === "minute") {
    newDate.setMinutes(date.getMinutes() + days);
  } else if (messageType === "second") {
    newDate.setSeconds(date.getSeconds() + days);
  }

  return newDate;
};


const dateTimeFormat = (date: Date, format: string, type = "-"): string => {

  const y = date.getFullYear();
  const M = getInitDate(date.getMonth() + 1);
  const d = getInitDate(date.getDate());
  const h = getInitDate(date.getHours());
  const m = getInitDate(date.getMinutes());
  const s = getInitDate(date.getSeconds());

  let resDate = '';

  if (format === 'ymdhms') { resDate = `${y}${type}${M}${type}${d} ${h}:${m}:${s}` }
  else if (format === 'ymdhm') { resDate = `${y}${type}${M}${type}${d} ${h}:${m}` }
  else if (format === 'ymdh') { resDate = `${y}${type}${M}${type}${d} ${h}` }
  else if (format === 'ymd') { resDate = `${y}${type}${M}${type}${d}` }
  else if (format === 'ym') { resDate = `${y}${type}${M}` }
  else if (format === 'y') { resDate = `${y}` }

  return resDate
}

const rsaEncrypt = (content: string) => {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(RsaPublicKey);
  const encrypted = encrypt.encrypt(String(content));
  return encrypted;
}

const deepCopy = (obj1: any) => {
  const type = Object.prototype.toString.call(obj1)
  let resObj: any = null;
  // 引用类型
  if (type.includes('object')) {
    if (type === '[object Array]') {
      resObj = []
      for (let i = 0; i < obj1.length; i++) {
        if (typeof obj1[i] === 'object') {
          resObj[i] = deepCopy(obj1[i])
        } else {
          resObj[i] = obj1[i]
        }
      }
    }
    if (type === '[object Object]') {
      resObj = {}
      for (const key in obj1) {
        if (typeof obj1[key] === 'object') {
          resObj[key] = deepCopy(obj1[key])
        } else {
          resObj[key] = obj1[key]
        }
      }
    }
  } else {
    // 基本类型
    resObj = obj1
  }
  return resObj
}

// 千分符处理
const thousandsNumFun = (num: number, limit: number = 0) => {
  if (typeof num !== 'number') {
    return num;
  }
  return num.toLocaleString('us', { maximumFractionDigits: limit });
}

const toFixedFun = ({ data, num = 2, failShow = null }: Record<string, any>) => {
  return (data !== null && data !== undefined && data !== {}) ? (Math.round(Number(data) * Math.pow(10, num)) / Math.pow(10, num)) : failShow;
}

/*
  功能-节流防抖
	immediate : 是否立即执行
	delay: 延时时间
	callback: 回调函数
*/
export class Debounce {
  private isDelay: boolean = false;
  private timer: any = null;

  // 防抖
  public debounce(callback: any, delay: number = 200) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      return callback();
    }, delay);
  }
  // 节流
  public throttle(callback: any, delay: number = 200) {
    if (!this.isDelay) {
      setTimeout(() => {
        this.isDelay = false;
      }, delay);
      this.isDelay = true;
      return callback();
    }
  }
}

// 获取对象指定属性
const getObjProps = (obj: any, prop: string) => {
  const propList: string[] = [];
  for (const data of obj) {
    propList.push(data[prop]);
  }
  return propList;
}

// redux => dispatch
const dispatchFun = ({ dispatch, res, type }: Record<string, any>) => {
  dispatch({
    type,
    payload: { ...res }
  });
}

//数据替换
export const RegExpReport = (obj: any, oriData: any[], newData: any[], type = "g") => {
  let oriObj = deepCopy(obj);
  for (let i = 0; i < oriData.length; i++) {
    oriObj = JSON.parse(JSON.stringify(oriObj).replace(new RegExp(oriData[i], type), newData[i]))
  }
  return oriObj
}

export const resDateRangeFormat = ({num, type = 'day', dateFormat='YYYY/MM/DD HH:mm:ss'}: Record<string, any>) => {
  return [
     moment().subtract(num, type).format(dateFormat),
     moment().format(dateFormat),
  ]
}

//JSONTree 组装
const assembledJson = (data: any) => {
  const toParse = (data: any[]) => {
    data.forEach((item: any) => {
      if (!item.value) {
        item['value'] = item.departmentId;
        item['key'] = item.departmentId;
        item['title'] = item.name;
      }
      if (item.childList && Array.isArray(item.childList)) {
        item['value'] = item.departmentId;
        item['key'] = item.departmentId;
        item['title'] = item.name;
        item['children'] = item.childList
        toParse(item.childList)
      }
      delete item.childList;
    })
    return data
  }
  return toParse(data)
}

//三个月后
const willThreeMonth = () => {
  var d = new Date();
  d.setMonth(d.getMonth() + 3);
  var yy1 = d.getFullYear();
  var mm1: any = d.getMonth() + 1;//因为getMonth（）返回值是 0（一月） 到 11（十二月） 之间的一个整数。所以要给其加1
  var dd1: any = d.getDate();
  if (mm1 < 10) {
    mm1 = '0' + mm1;
  }
  if (dd1 < 10) {
    dd1 = '0' + dd1;
  }
  return `${yy1}-${mm1}-${dd1}`
}
//将来一年
const willOneYear = () => {
  const date = new Date()
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}


export const fileDownload = ({data, name = `导出-${moment().format('YYYY/MM/DD HH:mm:ss')}`, type='xlsx' }: Record<string, any>) => {
  try {
    let blob = new Blob([data], {
        type: "application/octet-stream"
    });
    let filename = `${name}.${type}` || "filename.xls";
    if (typeof window.navigator.msSaveBlob !== "undefined") {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        var blobURL = window.URL.createObjectURL(blob);
        var tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        tempLink.setAttribute("download", filename);
        if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }
    return true
  } catch (error) {
    return false
  }
}



function AutoLoading({ text = '接口请求中...', loadStr = '' } = {}) {
    return function (target: any, property: any, descriptor: any) {
		console.log('AutoLoading: ', {target, property, descriptor})
        const raw = descriptor.value;
        let hide: any = '';
        descriptor.value = async function (...args: any) {
            try {
              if (!!loadStr) {
                this.setState({ [loadStr]: true })
                await raw.call(this, ...args);
                this.setState({ [loadStr]: false })
              } else {
                hide = message.loading(text, 0);
                await raw.call(this, ...args);
                hide();
              }
            }
            catch (error) {
              if (!!loadStr) this.setState({ [loadStr]: false });
              else hide();
            }

        }
    };
};

const dateRange = ():{
  [range: string]: RangePickerPresetRange;
}  => {
  const dateFormat = 'YYYY-MM-DD';
  let weekOfday = parseInt(moment().format('d'))

  return {
    '昨日': [
        moment(`${moment().subtract(1, 'days').format(dateFormat)} 00:00:00`),
        moment(`${moment().subtract(1, 'days').format(dateFormat)} 23:59:59`)
    ],
    '今日': [
      moment(`${moment().format(dateFormat)} 00:00:00`),
      moment(`${moment().format(dateFormat)} 23:59:59`)
    ],
    '近七日': [
        moment(`${moment().subtract(7, 'days').format(dateFormat)} 00:00:00`),
        moment(`${moment().format(dateFormat)} 23:59:59`)
    ],
    '上周': [
        moment(`${moment().subtract(weekOfday + 6, 'days').format(dateFormat)} 00:00:00`),
        moment(`${moment().subtract(weekOfday, 'days').format(dateFormat)} 23:59:59`)
    ],
    '本月': [
      moment(`${moment().month(moment().month()).startOf('month').format(dateFormat)} 00:00:00`),
      moment(`${moment().format(dateFormat)} 23:59:59`)
    ],
    '上月': [
      moment(`${moment().month(moment().month() - 1).startOf('month').format(dateFormat)} 00:00:00`),
      moment(`${moment().month(moment().month() - 1).endOf('month').format(dateFormat)} 23:59:59`)
    ],
  }
}

/*
	递归根据 ID 查找级联结构对象 - HZW
*/
class findNode {
	public resNode: Record<string, any> = {};
	public idList: number[] = [];
	public nodeList: Record<string, any>[] = [];
	public indexList: number[] = [];

	constructor() { /* */ }

	public fun = ({ nodes, id, idType = "id", childrenType = "children" }: Record<string, any>) => {
		for (var i = 0; i < nodes.length; i++) {
			if (this.resNode[idType]) {
				return;
			}

			this.idList.push(nodes[i][idType]);
			this.nodeList.push(nodes[i]);
			this.indexList.push(i);

			if (nodes[i][idType] == id) {
				this.resNode = nodes[i];
				return;
			}
			if (!nodes[i][childrenType] || nodes[i][childrenType].length === 0) {
				this.idList.splice(this.idList.length - 1, 1);
				this.nodeList.splice(this.nodeList.length - 1, 1);
				this.indexList.splice(this.indexList.length - 1, 1);
			}
			if (nodes[i][childrenType] && !!nodes[i][childrenType]) {
				if (nodes[i][childrenType].length > 0) this.fun({ nodes: nodes[i][childrenType], id, idType, childrenType });
			}
			if (i == nodes.length - 1 && !this.resNode[idType]) {
				this.idList.splice(this.idList.length - 1, 1);
				this.nodeList.splice(this.nodeList.length - 1, 1);
				this.indexList.splice(this.indexList.length - 1, 1);
			}
		}
	};
}

/**
 * Watermark
 */

 export class Watermark {
  $el: any;
  _watermarkImage: any;
  _targetElement: any;
  constructor({ content = '' }) {
    this._watermarkImage = this._createImage(content)
    this.$el = this._createWatermark()
    this._targetElement = document.getElementById('__poit-watermark__')
  }

  _createImage(content:string) {
    const $canvas = document.createElement('canvas')
    const ctx: CanvasRenderingContext2D = $canvas.getContext('2d')!;
    ctx.font = '16px serif';
    const text = ctx.measureText(content)
    const width = Math.max(text.width, 240)
    $canvas.width = width * 1.2
    $canvas.height = width / 2;
    ctx.rotate(-15 * Math.PI / 180)
    ctx.font = '16px serif';
    ctx.fillStyle = 'rgba(192, 196, 204, 0.16)';
    ctx.textAlign = 'left';
    ctx.translate(0, $canvas.height);
    ctx.fillText(content, 0, - (46 / 2));
    return $canvas.toDataURL('image/png', 0.5);
  }

  _createWatermark() {
    const $div: HTMLElement = document.getElementById('__poit-watermark__') || document.createElement('div');
    $div.id = '__poit-watermark__';
    const style = $div.style
    style.position = 'fixed';
    style.top = '0';
    style.right = '0';
    style.bottom = '0';
    style.left = '0';
    style.zIndex = '999999';
    style.pointerEvents = 'none';
    style.background = `url(${this._watermarkImage}) left top repeat`;
    return $div
  }

  appendTarget(target = document.body) {
    target.appendChild(this.$el);
    this._targetElement = target;
    return this;
  }

  update({ content = '' }) {
    this.remove();
    this._watermarkImage = this._createImage(content);
    this.$el = this._createWatermark();
    return this;
  }

  remove() {
    try {
      this._targetElement && this._targetElement.removeChild(this.$el);
    } catch(error) {
      console.log('Watermark:',this, error)
    }
    return this;
  }
}

export const createWatermark=()=>{
  const nickName=localStorage.getItem('nickName');

  if(nickName){
    let phone=localStorage.getItem('phoneSuffix')||'';
    if(phone){
      phone = phone.slice(-4);
    }
    const watermark = new Watermark({content: '水印内容'});
    watermark.update({content: `${nickName} ${phone}`}).appendTarget();
  }
};

export {
  dateMessage,
  dateTimeFormat,
  dateInterval,
  getInitDate,
  deepCopy,
  rsaEncrypt,
  thousandsNumFun,
  toFixedFun,
  getObjProps,
  dispatchFun,
  assembledJson,
  willThreeMonth,
  willOneYear,
  AutoLoading,
  dateRange,
  findNode,
};
