import { dispatchFun, RegExpReport } from 'src/assets/js/com';
import { Api } from 'src/assets/js/apiConfig/urlConfig';
import { postRequest } from 'src/assets/js/apiConfig/request';
import { message } from 'antd';


export const actionType: Record<string, symbol> = {
	DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
}

const type = actionType.DEFAULT_REPLACE;

//部门列表-Tree-Table
export const organizationList = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.department.organizationList, { params });
		if (retCode !== '0') { message.error(retMsg); return; }


		const obj = { dataSource: res.data, loading: false };
		dispatchFun({ dispatch, type, res: obj })
	};
}
//区域树
export const geoLlist = (params?: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.department.geoLlist, { params });
		// if (retCode !== '0') { message.error(retMsg); return; }
		if (res.provinces) {
			res.provinces = JSON.parse(JSON.stringify(res.provinces).replace(/("countyCode":"\d+",)("cityCode":"\d+",)/g, '$1'));
			const areaOptions = RegExpReport(res.provinces,
				['provinceName', 'provinceCode', 'cities', 'cityName', 'cityCode', 'countyList', 'countyName', 'countyCode', /\[\]/],
				['label', 'value', 'children', 'label', 'value', 'children', 'label', 'value', null]);
			const obj = { areaOptions };
			dispatchFun({ dispatch, type, res: obj })
		}
	};
}

//组织详情信息-包括弹窗下面两部分
export const orgDetail = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.department.orgDetail, { params });
		if (retCode !== '0') { message.error(retMsg); return; }

		dispatchFun({ dispatch, type, res: { orgDetailInfo: res.data } })
	};
}
//修改组织
export const organizationModify = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg }: any = await postRequest(Api.department.organizationModify, { params });
		if (retCode !== '0') { message.error(retMsg); return; }

		const obj = { visible: false };
		dispatchFun({ dispatch, type, res: obj })
	};
}

export const openModal = ({ modalType, record }: Record<string, any>) => {
	return async (dispatch: any) => {
		dispatchFun({ dispatch, type, res: { visible: true, dataConfig: record, modalType } })
	}
}

export const modalCallBack = ({ modalType, data }: Record<string, any>) => {
	return async (dispatch: any, getState: any) => {
		const { oriDataConfig, dataSource, isChange } = getState().AgencyCarrierManageReducer;
		const res: Record<string, any> = { visible: false, dataConfig: oriDataConfig, dataSource };
		if (modalType === 'updata') {
			res.dataSource.push(data);
			res.isChange = !isChange;
		}
		dispatchFun({ dispatch, type, res })
	}
}