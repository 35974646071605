import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from 'redux-thunk'

import { loginReducer } from './reducers/loginReducer';
import { sideMenuReducer } from './reducers/sideMenuReducer';
import { headReducer } from './reducers/headReducer';
import { AgencyCarrierManageReducer } from './reducers/agencyCarrierManageReducer';
import { CustomerEnterpManageReducer } from './reducers/CustomerEnterpManageReducer';
import { DepartmentManageReducer } from './reducers/DepartmentManageReducer';
import { ProdMenuManageReducer } from './reducers/ProdMenuManageReducer';
import { RolePermissionsManageReducer } from './reducers/RolePermissionsManageReducer';
import { UserManageReducer } from './reducers/UserManageReducer';
import { CustomerProjectManageReducer } from './reducers/CustomerProjectManageReducer';
import { UserDataAnalysisReducer } from './reducers/UserDataAnalysisReducer';
import { PagesDataAnalysisReducer } from './reducers/PagesDataAnalysisReducer';
import { CooperationOperationAnalysisReducer } from './reducers/CooperationOperationAnalysisReducer';
import { SMSPushReducer } from './reducers/SMSPushReducer';
import { ValueBoardReducer } from './reducers/ValueBoardReducer';

// 合并多个reducers
const multiReducers = combineReducers({
   loginReducer,
   sideMenuReducer,
   headReducer,
   AgencyCarrierManageReducer,
   CustomerEnterpManageReducer,
   DepartmentManageReducer,
   ProdMenuManageReducer,
   RolePermissionsManageReducer,
   UserManageReducer,
   CustomerProjectManageReducer,
   UserDataAnalysisReducer,
   PagesDataAnalysisReducer,
   CooperationOperationAnalysisReducer,
   SMSPushReducer,
   ValueBoardReducer,
})

/*
   applyMiddleware 使用中间件
   compose 创造一个增强函数
*/

// thunk 模式
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
   (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const store = createStore(multiReducers, enhancer); // 创建数据存储仓库

export default store;
