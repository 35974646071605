import { actionType } from '../actionCreators/login_AC';

const defaultState = {
	loading: false,
	title: null, 
	logoImg: { presignedUrl: null },
	isPassward: true,
	loginName: '',
	pwd: '',
	mobile: '',
	verCode: '',
	downNum: 60,
	isSendCode: false,
};

export const loginReducer = (state: Record<string, any> = defaultState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case actionType.DEFAULT_REPLACE:
			return { ...state,  ...payload };
		case actionType.INIT_DATA:
			return { ...state, ...defaultState }
		default:
			return state;
	}
}