
import { actionType } from '../actionCreators/DepartmentManage_AC';

const defaultState: Record<string, any> = {
   loading: true,

   isChange: false,
   dataSource: [],

   dataConfig: {},
   oriDataConfig: {
      shortName: '',
      allName: '',
      code: '',
      area: '',
      address: '',
      name: '',
      phone: '',
      creatName: '',
      creatTime: '',
      updataTime: '',
      manage_name: '',
      manage_phone: '',
      manage_user: '',
      manage_roles: '',
   },
   visible: false,//编辑查看
   addVisible: false,//新增
   closedModal: '',//关闭弹窗做一个展示
   //modal
   subordinateDepartmentsOptions: [],//  所属部门 
   departmentHeadOptions: [],//部门主管-可模糊查找
   departmentListIfOptions: [],//机构选择部门（去除当前部门子树）
};

export const DepartmentManageReducer = (state: Record<string, any> = defaultState, action: any) => {
   const { type, payload } = action;
   switch (type) {
      case actionType.DEFAULT_REPLACE:
         return { ...state, ...payload };
      default:
         return state;
   }
}