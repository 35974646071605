import { postRequest } from 'src/assets/js/apiConfig/request';
import { dispatchFun, deepCopy, fileDownload } from 'src/assets/js/com';
import { Api } from 'src/assets/js/apiConfig/urlConfig';
import { message } from 'antd';

export const defaultState: Record<string, any> = {
	loading: false,

	productList: [
		{ label: 'PC', value: 'PC', children: null },
		{
			label: 'APP', value: 'APP',
			children: [
				{ label: '全部操作系统', value: 'allAPP', children: null },
				{ label: 'Android', value: 'Android', children: [{ label: '全部版本', value: '全部版本' }] },
				{ label: 'IOS', value: 'IOS', children: [{ label: '全部版本', value: '全部版本' }] }
			],
		}
	],
	pagesList: [],
	userConfig: [
		{ desc: '全部用户', value: 'all', children: null },
		{ desc: '用户岗位', value: 'userPositionConditions', children: [] },
		{ desc: '企业简称', value: 'orgIdConditions', children: [] },
		{ desc: '企业所属行业', value: 'tradeConditions', children: [] },
		{ desc: '企业等级', value: 'enterpriseLevelConditions', children: [] },
	],
	isInit: false,
	searchParams: {},

	showTrend: 'active',
	trendType: {
		increase: { name: '增长趋势', analyseMode: 1, api_analysis: 'USER_GROW_SUMMARY_LIST',
			all: { api_export: 'USER_GROW_SUMMARY_EXPORT' },
			detail: { get_detail: 'USER_GROW_DETAIL_LIST', api_export: 'USER_GROW_DETAIL_EXPORT' },
		},
		active: { name: '活跃趋势', analyseMode: 2, api_analysis: 'USER_ACTIVE_SUMMARY_LIST', 
			all: { api_export: 'USER_ACTIVE_SUMMARY_EXPORT' },
			detail: { get_detail: 'USER_ACTIVE_DETAIL_LIST', api_export: 'USER_ACTIVE_DETAIL_EXPORT' }
		},
	},
	chartsTypeSelect: {
		increase: 0,
		active: 0,
	},
	chartsTypeConfig: {
		increase: [
			{ name: '新增用户数', type: 'newUserCount' },
			{ name: '累计用户数', type: 'accumulatedUserCount' },
		],
		active: [
			{ name: '活跃用户数', type: 'activeUserCount' },
			{ name: '活跃用户率', type: 'activeUserRatio' },
		],
	},
	markNum: '1',
	timeRange: "1",
	dateRange: {
		1: '日', 2: '周', 3: '月'
	},
	resData: [],

	showTable: 'all',
	markTable: '1',
	tableDetail: [],
}

export const actionType: Record<string, symbol> = {
	DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
}

const type = actionType.DEFAULT_REPLACE;

export const getData = () => {
	return async (dispatch: any) => {
		dispatch({ type, payload: { loading: true } })

		const [
			{ retCode: retCode1, data: appVersions },
			{ retCode: retCode2, data: oirUserPositionConditions },
			{ retCode: retCode3, data: oldorgIdConditions },
			{ retCode: retCode4, data: tradeConditions },
			{ retCode: retCode5, data: enterpriseLevelConditions },
		] = await Promise.all([
			postRequest(Api.analysis.APP_VERSION),
			postRequest(Api.userManage.positionList, { params: { orgId: null } }),
			postRequest(Api.department.organizationList, {params: { pageNum: 1, pageSize: 999, orgType: 300 }}),
			postRequest(Api.analysis.TRADE),
			postRequest(Api.analysis.ENTER_LEVEL),
		]);
		if (retCode1 !== "0" || retCode2 !== "0" || retCode3 !== "0" || retCode4 !== "0" || retCode5 !== "0" ) {
			dispatch({ type, payload: { loading: false } })
			message.error('网络错误，请重新刷新');
			return;
		}

		const userPositionConditions = oirUserPositionConditions.map((item: Record<string, string>) => { return { desc: item.positionName, id: item.positionId } })
		const orgIdConditions = oldorgIdConditions.map((item: Record<string, string>) => { return { desc: item.orgName, id: item.orgId } })

		let { productList, userConfig, isInit } = deepCopy(defaultState);
		const { androidVersions, iosVersions } = appVersions;
		productList = await makeProductList({ productList, androidVersions, iosVersions });
		userConfig = await makeUserConfig({ userConfig, resData: { userPositionConditions, orgIdConditions, tradeConditions, enterpriseLevelConditions } });

		dispatch({ type, payload: { productList, userConfig, isInit: !isInit, loading: false } })
	};
}

export const analysisData = (resData: Record<string, any>) => {
	return async (dispatch: any, getState: any) => {
		dispatch({ type, payload: { loading: true } })
		const { showTrend, trendType, timeRange } = getState().UserDataAnalysisReducer;
		let params = await makeParams(resData, timeRange);
		// console.log('analysisData-params: ', params);

		analysisRequest({
			API: Api.analysis[trendType[showTrend].api_analysis],
			params,
			dispatch
		});

	};
}

export const analysisDataTabs = ({ paramsType, value }: Record<string, string>) => {
	return async (dispatch: any, getState: any) => {
		dispatch({ type, payload: { loading: true, [paramsType]: value } })
		let { showTrend, trendType, searchParams } = getState().UserDataAnalysisReducer;
		if (paramsType === 'timeRange') searchParams = { ...searchParams, [paramsType]: value };

		analysisRequest({
			API: Api.analysis[trendType[showTrend].api_analysis],
			params: searchParams,
			dispatch
		});
	};
}

export const getDetail = (record: Record<string, any>, value: string) => {
	return async (dispatch: any, getState: any) => {
		const { showTrend, trendType, searchParams } = getState().UserDataAnalysisReducer;
		// const { begin: beginTimestamp, end: endTimestamp, desc } = record;
		const { desc } = record;
		const { retMsg, retCode, data } = await postRequest(Api.analysis[trendType[showTrend][value].get_detail],
			{ params: {
				...searchParams,
				beginTimestamp: 1550505600000,
				endTimestamp: 1582128000000,
				desc,
			} });
		if (retCode !== '0') {
			message.error(retMsg);
			return;
		};
		
		const mark = Math.random().toString();
		dispatch({ type, payload: { tableDetail: data, showTable: value, markTable: mark } })
	}
}

export const exportAnalysis = () => {
	return async (dispatch: any, getState: any) => {
		dispatch({ type, payload: { loading: true } })
		const { showTrend, trendType, showTable, searchParams } = getState().UserDataAnalysisReducer;
		const data = await postRequest(Api.analysis[trendType[showTrend][showTable].api_export], { params: searchParams });
		const isSuccess = await fileDownload({data, name: '报表导出', type: 'xls'});
		message[isSuccess ? 'success' : 'error'](isSuccess ? '导出成功' : '导出失败')
		dispatch({ type, payload: { loading: false } })
	};
}

export const changeData = (dataType: string, value: string | number | boolean | string[]) => {
	return (dispatch: any) => {
		dispatchFun({ dispatch, type, res: { [dataType]: value } })
	};
}

export const changeDataArr = ( payload: Record<string, any> ) => {
	return (dispatch: any) => {
		dispatch({ type, payload });
	};
}

const makeProductList = ({ productList, androidVersions, iosVersions }: Record<string, any>) => {
	const android = androidVersions.map((item: string) => {
		return { label: item, value: item };
	})
	const ios = iosVersions.map((item: string) => {
		return { label: item, value: item };
	})
	productList[1].children[1].children = [...productList[1].children[1].children, ...android];
	productList[1].children[2].children = [...productList[1].children[2].children, ...ios];

	return productList;
}

const makeUserConfig = ({ userConfig, resData }: Record<string, any>) => {
	userConfig.map((item: Record<string, any>) => {
		if (resData[item.value]) item.children = resData[item.value];
		// if (resData[item.value]) item.children = [...resData[item.value], { desc: 'test', id: '112233' }];
		return null;
	})
	return userConfig;
}

const makeParams = async (resData: Record<string, any>, timeRange: string) => {
	const appVersion = resData.productArr.length === 0 ? '' :
		/\./.test(resData.productArr[resData.productArr.length - 1]) ? resData.productArr[resData.productArr.length - 1] : '';

	const platformCode = resData.productArr.length === 0 ? 0 : await makeplatformCode(resData);
	let userConfig: Record<string, Record<string, string>[]> = {
		enterpriseLevelConditions: [],
		orgIdConditions: [],
		tradeConditions: [],
		userPositionConditions: [],
	}
	userConfig = resData.userArr[0].type === 'all' ? userConfig : await makeUserList(resData.userArr, userConfig);

	return {
		"beginTime": resData.date[0],
		"endTime": resData.date[1],
		appVersion,
		platformCode,
		...userConfig,
		"timeRange": Number(timeRange),
	};
}

const makeplatformCode = ({ productArr }: Record<string, any>) => {
	const codeConfig = {
		IOS: 1,
		Android: 2,
		PC: 3,
		allAPP: 4,
	}
	return productArr[0] === "PC" ? 3 : codeConfig[productArr[1]]
}

const makeUserList = (userArr: Record<string, any>, userConfig: Record<string, Record<string, string>[]>) => {
	userArr.map((item: Record<string, any>) => {
		if (item.select.length > 0) {
			let uesrList: Record<string, string>[] = [];
			item.list.map((item1: Record<string, any>) => {
				if (item.select.findIndex((item2: string) => item2 === item1.desc) >= 0) {
					uesrList.push({ value: item1.id, op: item.condition });
				}
				return null;
			})
			userConfig[item.type] = [
				...userConfig[item.type],
				...uesrList
			]
		}
		return null
	})
	return userConfig;
}


const analysisRequest = async ({API, params, dispatch}: Record<string, any>) => {
	const { retMsg, retCode, data } = await postRequest(API, { params });
	if (retCode !== '0') {
		dispatch({ type, payload: { loading: false } })
		message.error(retMsg);
		return;
	}
	
	// console.log('analysisData-res: ', data)
	const mark = Math.random().toString();
	dispatch({ type, payload: { loading: false, searchParams: params, resData: data, markNum: mark, markTable: mark, showTable: 'all' } })
}