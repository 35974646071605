
import { actionType } from '../actionCreators/CustomerProjectManage_AC';

const defaultState: Record<string, any> = {
	loading: true,
	modalLoading:true,

	isChange: false,
	dataSource: [],
	// proStatusOptions: [],//项目状态
	proQuickOptions: [],//项目问题
	// modal
	subordinateAgentOptions: [],	// 所属代理商 
	belongEnterpriseOptions: [],// 所属企业
	projectStatusOptions: [],// 项目状态
	projectManagerOptions: [],// 项目经理
	payProductsOptions: [],// 请选择付	费产品
	implementationResultsOptions: [],// 实施结果 checkobx
	functionScopeTree: [],// 功能范围	- Tree
	radio: [],// 有无 -
	CheckBox: [],// 结果列表- 
	attachmentFileList: [],
	projectId: "",//附件Id 项目Id
	projectRenewList: [],//续签列表
	editPayProduct:'',//编辑付费项目拿到后端初始化的值
	dataConfig: {},
	oriDataConfig: {
		shortName: '',
		allName: '',
		code: '',
		area: '',
		address: '',
		name: '',
		phone: '',
		creatName: '',
		creatTime: '',
		updataTime: '',
		manage_name: '',
		manage_phone: '',
		manage_user: '',
		manage_roles: '',
	},

	visible: false,//编辑查看
	addVisible: false,//新增
	attachmentVisible: false,//附件
	contractVisible: false,//续签
	modalType: 'add',
	closedModal: '',//关闭弹窗做一个展示

	// 实施评价 	integer($int32) 类别 0：项目实施结果 1：项目存在问题
	getGoodsClassListMgmtDepotGoods: [],//项目实施结果
	GetCurrentProjectName: [], //项目存在问题
	//项目实施评价 默认值-编辑查看
	multiProject: {},

};

defaultState.projectManagerOptions = [
	{ label: '项目经理', value: 1, },
	{ label: '完成', value: 10, },
	{ label: '未完成', value: 112, }];
export const CustomerProjectManageReducer = (state: Record<string, any> = defaultState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case actionType.DEFAULT_REPLACE:
			return { ...state, ...payload };
		default:
			return state;
	}
}