import { dispatchFun, rsaEncrypt } from 'src/assets/js/com';
import * as common from 'src/assets/js/common';
import { message } from 'antd';
import { postRequest } from 'src/assets/js/apiConfig/request';
import { Api } from 'src/assets/js/apiConfig/urlConfig';
import { _oauth_account_login_POST, _oauth_mobile_login_POST, _oauth_verification_code_get_POST } from 'src/assets/js/d_ts/oauth/api';
import { ApiResp_YongHuDengLuXiangYingTi_ } from 'src/assets/js/d_ts/oauth/definitions';
import { _resource_permission_org_list_POST } from 'src/assets/js/d_ts/operation/api';

export const actionType: Record<string, symbol> = {
	DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
	INIT_DATA: Symbol('INIT_DATA'),
}

const type = actionType.DEFAULT_REPLACE;

export const changeData = (dataType: string, value: string | number | boolean) => {
	return (dispatch: any) => {
		dispatchFun({ dispatch, type, res: { [dataType]: value } })
	};
}

export const loginFun = () => {
	return async (dispatch: any, getState: any) => {
		const { loginReducer: { isPassward, account, pwd, mobile, verCode } } = getState();
		let res: ApiResp_YongHuDengLuXiangYingTi_ | null = null;
		if (isPassward) {
			if (!account || !pwd) {
				message.warning('请输入账号、密码');
				return false;
			}
			dispatchFun({ dispatch, type, res: { loading: true } })
			res = await password(account, pwd);
		} else {
			if (!mobile || !verCode) {
				message.warning('请输入手机号码、验证码');
				return false;
			}
			dispatchFun({ dispatch, type, res: { loading: true } })
			res = await verificationCode(mobile, verCode);
		}

		dispatchFun({ dispatch, type, res: { loading: false } });

		if (res.retCode !== '0') {
			message.warning(`登录失败-${res.retMsg}`);
			return false;
		}
    // @ts-ignore
		const {token, openUserId, userId, nickName,phoneSuffix,} = res.data || {};
		localStorage.setItem('userId', userId + '');
		localStorage.setItem(common.AccessTokenTag, token);
		localStorage.setItem('openUserId', openUserId);
		localStorage.setItem('userId', userId + '');
		localStorage.setItem('operateUserId', userId + '');
		localStorage.setItem('nickName', nickName);
		localStorage.setItem('phoneSuffix', phoneSuffix||'');

		const { retCode, retMsg, data } = await _resource_permission_org_list_POST({
			operateUserId: userId,
		})

		if (retCode !== '0') {
			message.warning(`登录失败-${retMsg}`);
			return false;
		}

		const { orgId, orgName } = data[0] || {};
		localStorage.setItem('orgId', orgId + '');
		localStorage.setItem('orgName', orgName);

		return true;
	};
}

const syscode = 'poit-operation-platform';
// 密码登录
const password = async (account: string, pwd: string) => {
	const params = {
		app: syscode,
		loginName: rsaEncrypt(account),
		pwd: rsaEncrypt(pwd),
	};
	return await _oauth_account_login_POST(params);
}

// 密码登录
const verificationCode = async (mobile: string, verCode: string) => {
	const params = {
		app: syscode,
		phone: rsaEncrypt(mobile),
		// verificationCode: rsaEncrypt(verCode),
		verificationCode: verCode,
	};
	return await _oauth_mobile_login_POST(params);
}

// 手机验证码发送倒计时处理
export const downCode = (timer: any) => {
	return async (dispatch: any, getState: any) => {
		const { loginReducer: { mobile, isSendCode } } = getState();

		if (!mobile) {
			message.warning(`请输入手机号码`);
			return
		}

		if (!isSendCode) {
			// 倒计时
			clearInterval(timer);
			timer = setInterval(() => {
				const { loginReducer: { downNum } } = getState();
				if (downNum === 1) {
					clearInterval(timer);
					dispatch({ type, payload: { isSendCode: false, downNum: 60 } });
				}
				dispatch({ type, payload: { 'downNum': downNum - 1 } });
			}, 1000);

			dispatch({ type, payload: { 'isSendCode': true } });

			const data: Record<string, any> = await sendVerCode(mobile);
			if (data.retCode !== '0') {
				message.warning(`验证码发送失败-${data.retMsg}`);
			}
		}
		return true;
	};
}

// 发送验证码
const sendVerCode = async (mobile: string) => {
	return await _oauth_verification_code_get_POST({
		phone: rsaEncrypt(mobile),
		verificationType: "login",
	});
}

// 登陆后清除 redux 账号密码信息
export const initData = () => {
	return (dispatch: any) => {
		dispatch({ type: actionType.INIT_DATA });
	};
}
