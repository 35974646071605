import { actionType } from '../actionCreators/sideMenu_AC';

const defaultState = {
	listUserMenus: [],
	defalutKey: [],
	defalutOpenKeys: [],
	isHide: false,
};

export const sideMenuReducer = (state: Record<string, any> = defaultState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case actionType.DEFAULT_REPLACE:
			return { ...state,  ...payload };
		default:
			return state;
	}
}