import * as React from "react";
import {Menu, Icon, Badge, Button, Tooltip} from "antd";
import { connect } from 'react-redux';

import { getInitDate } from 'src/assets/js/com';
import * as common from 'src/assets/js/common'
import { logout } from 'src/assets/js/misc';

import "../lib/css/Head.scss";

interface IState {
	dateTime: any;
	menus: any;
	openKeys: string[];
	rootSubmenuKeys: string[];
}

const { SubMenu }: Record<string, any> = Menu;
let timer: any = null;
const title = localStorage.getItem('title');
// const logoImg = localStorage.getItem('logoImg');
const org = localStorage.getItem(common.OrgTag);

class Head extends React.Component<any, IState> {
	public constructor(props: any) {
		super(props);

		this.state = {
			dateTime: {
				fullyear: "",
				month: "",
				nowday: "",
				hours: "",
				minutes: "",
				seconds: "",
			},
			menus: [], // 菜单配置的路由
			openKeys: [], // 选中的菜单项
			rootSubmenuKeys: [], // 所有二级菜单选项
		};

		// console.log(props)
	}

	public componentWillUnmount() {
		clearTimeout(timer);
	}

	// 获取时间方法
	public gettime() {
		const date = new Date();
		const dateTime = {
			fullyear: date.getFullYear().toString(),
			month: getInitDate(date.getMonth() + 1),
			nowday: getInitDate(date.getDate()),
			hours: getInitDate(date.getHours()),
			minutes: getInitDate(date.getMinutes()),
			seconds: getInitDate(date.getSeconds())
		};
		this.setState({ dateTime });

		clearTimeout(timer);
		timer = setTimeout(() => {
			this.gettime();
		}, 1000);
	}

  public render() {
    const {titleName, backFunc} = this.props.headReducer;
    const routerName = this.props.name;
    let tips = null;

    if ((routerName === '操作明细'||routerName === '企业明细'||routerName==='整体概况')&&!titleName) {
      let title=null;
      if(routerName === '操作明细'){
         title = <div className="fz12">
          <div className="mb5 fontBold fz14">操作明细</div>
          <div className="mb5 fontBold fz14">◆点击企业</div>
          筛选范围内，点击该操作的企业数量（去重）
          <div className="mb5 mt5 fontBold fz14">◆点击次数</div>
          筛选范围内，用户点击该操作的次数
          <div className="mb5 mt5 fontBold fz14">◆点击人数</div>
          筛选范围内，点击该操作的用户数量（去重）
        </div>
      } else if(routerName === '企业明细'){
        title = <div className="fz12">
          <div className="mb5 fontBold fz14">企业明细</div>
          <div className="mb5 fontBold fz14">◆用户人数</div>
          筛选结束日期时，企业新增的企业用户总数
          <div className="mb5 mt5 fontBold fz14">◆活跃人数</div>
          筛选范围内，企业有使用系统功能的企业用户数
          <div className="mb5 mt5 fontBold fz14">◆平均日活人数</div>
          筛选范围内，每日企业有使用系统功能的企业用户数合计/筛选天数
        </div>
      }else if(routerName === '整体概况'){
        title = <div className="fz12">
          <div className="mb5 fontBold fz14">整体概况</div>
          <div className="mb5 fontBold fz14">◆访问企业数</div>
          筛选范围下，有用户使用功能的企业数量汇总（去重）
          <div className="mb5 mt5 fontBold fz14">◆访问次数</div>
          筛选范围下，用户访问功能次数汇总
          <div className="mb5 mt5 fontBold fz14">◆访问人数</div>
          筛选范围下，用访问功能的用户数量汇总（去重）
        </div>
      }

      tips = <>
        <Tooltip placement="rightTop" title={title}>
          <Icon className="ml5 fz14" type="question-circle" theme="filled"/>
        </Tooltip>
      </>
    }

    return (
      <div className="Head SourceHanSansCN">
        <div className="left">
          {!!backFunc &&
          <Button type="link" onClick={backFunc} size="large">&lt; 返回</Button>}{titleName || this.props.name || title}
          {tips}

        </div>
        <div className="right">
          <div className="badge">
            <Badge count={3}>
              <Icon type="bell" style={{fontSize: '20px', color: '#5864FF'}}/>
            </Badge>
          </div>
          <div className="user-name">
            Richie
          </div>
          <div className="logout">
            <div className="imgIcon">
              <img className="logout-img" title="点我退出系统" onClick={() => logout(org as string)} alt="点我退出"
                   src={require('src/assets/images/head-right-icon.jpg')}/>
            </div>
            {/* <img className="logout-img" onClick={() => logout(org as string)} alt="点我退出" src={require('src/assets/images/head-right-icon.jpg')} /> */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ headReducer }: any) => ({ headReducer });
const mapDispatchToProps = (dispatch: any) => ({ ...dispatch })
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Head)
