import { dispatchFun } from 'src/assets/js/com';

export const actionType: Record<string, symbol> = {
	DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
	INIT_DATA: Symbol('INIT_DATA'),
}

const type = actionType.DEFAULT_REPLACE;

export const setTitleHandler = ({titleName, backFunc}: ISetTitleHandler) => {
	return (dispatch: any) => {
		dispatchFun({ dispatch, type, res: { titleName, backFunc } })
	};
}