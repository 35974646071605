import * as React from 'react'
import Loadable from 'react-loadable'
import RenderRoutesMap from './RenderRoutesMap'

import * as storage from 'src/assets/js/localStorage'
import * as common from '../assets/js/common'
import { checkArrNotEmpty, clearStorageExcept } from '../assets/js/misc'
import { Debounce } from 'src/assets/js/com'

const DebounceFunc = new Debounce();
class RouterGuard extends React.Component<any> {
    public constructor(props: any) {
        super(props);
        this.routerGuardFun();
    }
    componentDidMount() {
        console.log(11123323);
        document.addEventListener('DOMNodeInserted', this.eventListenerHandler,false);
        document.addEventListener('DOMNodeRemoved', this.eventListenerHandler,false);
    }

    componentWillUnmount() {
        console.log(9999999);
        document.removeEventListener('click',() => { console.log("移除 click 事件") },false);
        document.removeEventListener('DOMNodeInserted',() => { console.log("移除 DOMNodeInserted 事件") },false);
        document.removeEventListener('DOMNodeRemoved',() => { console.log("移除 DOMNodeInserted 事件") },false);
    }

    eventListenerHandler = (e: any) => {
        DebounceFunc.debounce(() => this.updatedHandler('event'), 100);
    }

    menuUnUsableBtnArr = [];
    unReload = false;
    targetArr: any[] = [];
    async updatedHandler(type: string) {
        const dom = document.querySelector('.MainContent');
        if (!dom) return;

        this.menuUnUsableBtnArr = this.getRouterUnUsableBtn();
        

        await this.recursionDom(dom.childNodes);
    
        // 设置移除状态 & 遍历需要移除的节点，统一执行
        this.unReload = true;
        // console.log('targetArr: ', this.targetArr);
        this.targetArr.map(item => {
          item.remove();
        }) 
        this.targetArr = [];
    }

    // 获取菜单需移除的操作id清单
    getRouterUnUsableBtn() {
        const menuBtnConfig = JSON.parse(sessionStorage.getItem('menuBtnConfig') || '{}');
        return menuBtnConfig?.[window.location.pathname]?.filter((item: Record<string, any>) => !item.isUsable)?.map((item: Record<string, any>) => item.resourcesCode) || [];
    }

    // 递归获取需移除的节点
    recursionDom(doms: any) {
        for (const item of doms) {
            const { buriedpointid, target }: Record<string, any> = this.getOwnBuriedpointid(item);
            // console.log({buriedpointid});
            if (!!buriedpointid) {
                //@ts-ignore
                this.menuUnUsableBtnArr.includes(buriedpointid) && this.targetArr.push(target);
            }
            if (!!item.childNodes) this.recursionDom(item.childNodes)
        }
    }

    getOwnBuriedpointid(target: Record<string, any>) {
        const { attributes } = target;
        if (!!attributes?.buriedpointid) return { buriedpointid: attributes.buriedpointid.value, target }
        return {};
    }

    public render() {
        const { component, routes = [] } = this.props
        const LoadableComponent = Loadable({
            loader: () => import(`../${component}`),
            loading: () => (
                <span />
            )
        })
        return (
            <div>
                <LoadableComponent {...this.props} />
                {RenderRoutesMap(routes)}
            </div>

        )
    }

    public async routerGuardFun() {
        const checkMsg: any[] = [];
        let OrgTag: string = '';
        // 路由守卫配置
        checkMsg[0] = await storage.get(common.AccessTokenTag);
        checkMsg[1] = await storage.get(common.OrgIdTag);
        OrgTag = await storage.get(common.OrgTag);
        // console.log(this.props, OrgTag);
        this.routerMessage(checkMsg, OrgTag);
    }

    public routerMessage(checkMsg: string[], localOrg: string) {
        const { location: { pathname }, history: { push }, path } = this.props;
        const pathOrg = pathname.substr(1);

        if (path === '/*') {
            // 登录处理
            this.loginMessage(pathOrg, checkMsg, localOrg);
            // }else if(path!=='/'&&path!=='/Login'&&!checkArrNotEmpty(checkMsg)){
            //     // 登录路径为菜单路由且'不为登录项目跳转登录路由login'，但是无token
            //     // 存在企业编码则重定向到登录项目
            //     // 若无企业编号则跳转到 '/'
            //     if(localOrg){
            //         message.warning('登录信息缺失(token)，请重新登录');
            //         push('/Login')
            //     }else{
            //         push('/')
            //     }
            // }

        } else if (path === '/') {//temp
            push('enterprise_detail')
        } else {
            push(path)
        }
    }

    public async loginMessage(pathOrg: string, checkMsg: string[], localOrg: string) {
        const { history: { push } } = this.props;
        // 登录路径为非菜单路由，统一默认为项目代号，并存storage
        // 实现同个项目的自动登录功能
        // console.log('localOrg: ', localOrg, pathOrg, checkMsg)
        if ((localOrg !== null) && (localOrg === pathOrg) && checkArrNotEmpty(checkMsg)) {
            push('/agency_carrierManage')
        } else {
            // 新项目登录
            clearStorageExcept()
            await storage.set(common.OrgTag, pathOrg);
            push('/Login')
            // switchToPoiUnion(pathOrg)
        }
    }
}

export default RouterGuard;
