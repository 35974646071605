import { actionType } from '../actionCreators/head_AC';

const defaultState = {
	titleName: '',
    backFunc: null
};

export const headReducer = (state: Record<string, any> = defaultState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case actionType.DEFAULT_REPLACE:
			return { ...state,  ...payload };
        case actionType.INIT_DATA:
            return { ...state, ...defaultState }
		default:
			return state;
	}
}