import { dispatchFun, deepCopy, fileDownload } from 'src/assets/js/com';
import { postRequest } from 'src/assets/js/apiConfig/request';
import { Api } from 'src/assets/js/apiConfig/urlConfig';
import { message } from 'antd';

export const defaultState: Record<string, any> = {
	loading: false,

	productList: [
		{ label: 'PC', value: 'PC', children: null },
		{
			label: 'APP', value: 'APP',
			children: [
				{ label: '全部操作系统', value: 'allAPP', children: null },
				{ label: 'Android', value: 'Android', children: [{ label: '全部版本', value: '全部版本' }] },
				{ label: 'IOS', value: 'IOS', children: [{ label: '全部版本', value: '全部版本' }] }
			],
		}
	],
	pagesList: [],
	userConfig: [
		{ desc: '全部用户', value: 'all', children: null },
		{ desc: '用户岗位', value: 'userPositionConditions', children: [] },
		{ desc: '企业简称', value: 'orgIdConditions', children: [] },
		{ desc: '企业所属行业', value: 'tradeConditions', children: [] },
		{ desc: '企业等级', value: 'enterpriseLevelConditions', children: [] },
	],
	isInit: false,
	searchParams: {},

	showTrend: 'pages',
	trendType: {
		pages: { name: '按页面', analyseMode: 1, api_analysis: 'PAGE_STATISTICS_GET',
			all: { api_export: 'PAGE_STATISTICS_EXPORT' },
			detail: { get_detail: 'PAGE_DETAIL_GET', api_export: 'PAGE_DETAIL_EXPORT' },
		},
		time: { name: '按时间', analyseMode: 2, api_analysis: 'TIME_STATISTICS_GET', 
			all: { api_export: 'TIME_STATISTICS_EXPORT' },
			detail: { get_detail: 'PAGE_DETAIL_GET', api_export: 'TIME_DETAIL_EXPORT' }
		},
	},
	trendTypeSelect: 'visitCount',
	trendTypeConfig: {
		visitCount: '页面访问次数',
		userCount: '页面访问用户数',
		userAvgPageDurationDesc: '天人均页面使用时长',
		pageAvgDurationDesc: '次均页面使用时长'
	},
	summary: {},
	pages: [],
	markNum: '1',
	timeRange: "1",
	dateRange: {
		1: '日', 2: '周', 3: '月'
	},

	markTable: '1',
	showTable: 'all',
	tableDetail: [],
}

export const actionType: Record<string, symbol> = {
	DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
}

const type = actionType.DEFAULT_REPLACE;

export const getData = () => {
	return async (dispatch: any) => {
		dispatch({ type, payload: { loading: true } })

		const [
			{ retCode: retCode1, data: appVersions },
			{ retCode: retCode2, data: resPages },
			{ retCode: retCode3, data: oirUserPositionConditions },
			{ retCode: retCode4, data: oldorgIdConditions },
			{ retCode: retCode5, data: tradeConditions },
			{ retCode: retCode6, data: enterpriseLevelConditions },
		] = await Promise.all([
			postRequest(Api.analysis.APP_VERSION),
			postRequest(Api.analysis.PAGE),
			postRequest(Api.userManage.positionList, { params: { orgId: null } }),
			postRequest(Api.department.organizationList, {params: { pageNum: 1, pageSize: 999, orgType: 300 }}),
			postRequest(Api.analysis.TRADE),
			postRequest(Api.analysis.ENTER_LEVEL),
		]);
		if (retCode1 !== "0" || retCode2 !== "0" || retCode3 !== "0" || retCode4 !== "0" || retCode5 !== "0" || retCode6 !== "0" ) {
			dispatch({ type, payload: { loading: false } })
			message.error('网络错误，请重新刷新');
			return;
		}

		const userPositionConditions = oirUserPositionConditions.map((item: Record<string, string>) => { return { desc: item.positionName, id: item.positionId } })
		const orgIdConditions = oldorgIdConditions.map((item: Record<string, string>) => { return { desc: item.orgName, id: item.orgId } })

		let { productList, userConfig, isInit } = deepCopy(defaultState);
		const { androidVersions, iosVersions } = appVersions;
		productList = await makeProductList({ productList, androidVersions, iosVersions });
		userConfig = await makeUserConfig({ userConfig, resData: { userPositionConditions, orgIdConditions, tradeConditions, enterpriseLevelConditions } });

		dispatch({ type, payload: { productList, pagesList: resPages, userConfig, isInit: !isInit, loading: false } })
	};
}

export const analysisData = (resData: Record<string, any>) => {
	return async (dispatch: any, getState: any) => {
		dispatch({ type, payload: { loading: true } })
		const { showTrend, trendType, timeRange } = getState().PagesDataAnalysisReducer;
		let params = await makeParams(resData, trendType[showTrend].analyseMode, timeRange);
		// console.log('analysisData-params: ', params);

		analysisRequest({
			API: Api.analysis[trendType[showTrend].api_analysis],
			params,
			dispatch
		});

	};
}

export const analysisDataTabs = ({ paramsType, value }: Record<string, string>) => {
	return async (dispatch: any, getState: any) => {
		dispatch({ type, payload: { loading: true, [paramsType]: value } })
		let { showTrend, trendType, searchParams } = getState().PagesDataAnalysisReducer;
		if (paramsType === 'timeRange') searchParams = { ...searchParams, [paramsType]: value };

		analysisRequest({
			API: Api.analysis[trendType[showTrend].api_analysis],
			params: searchParams,
			dispatch
		});
	};
}

export const getDetail = (record: Record<string, any>, value: string) => {
	return async (dispatch: any, getState: any) => {
		const { showTrend, trendType, searchParams } = getState().PagesDataAnalysisReducer;
		// const { begin: beginTimestamp, end: endTimestamp, desc } = record;
		const { desc } = record;
		const { retMsg, retCode, data } = await postRequest(Api.analysis[trendType[showTrend][value].get_detail],
			{ params: {
				...searchParams,
				beginTimestamp: 1550505600000,
				endTimestamp: 1582128000000,
				desc,
			} });
		if (retCode !== '0') {
			message.error(retMsg);
			return;
		};
		
		const mark = Math.random().toString();
		dispatch({ type, payload: { tableDetail: data, showTable: value, markTable: mark } })
	}
}

export const exportAnalysis = () => {
	return async (dispatch: any, getState: any) => {
		dispatch({ type, payload: { loading: true } })
		const { showTrend, trendType, showTable, searchParams } = getState().PagesDataAnalysisReducer;
		const data = await postRequest(Api.analysis[trendType[showTrend][showTable].api_export], { params: searchParams, type: "export" });
		const isSuccess = await fileDownload({data, name: '报表导出'});
		message[isSuccess ? 'success' : 'error'](isSuccess ? '导出成功' : '导出失败')
		dispatch({ type, payload: { loading: false } })
	};
}

export const changeData = (dataType: string, value: string | number | boolean | string[]) => {
	return (dispatch: any) => {
		dispatchFun({ dispatch, type, res: { [dataType]: value } })
	};
}

export const changeDataArr = ( payload: Record<string, any> ) => {
	return (dispatch: any) => {
		dispatch({ type, payload });
	};
}

const makeProductList = ({ productList, androidVersions, iosVersions }: Record<string, any>) => {
	const android = androidVersions.map((item: string) => {
		return { label: item, value: item };
	})
	const ios = iosVersions.map((item: string) => {
		return { label: item, value: item };
	})
	productList[1].children[1].children = [...productList[1].children[1].children, ...android];
	productList[1].children[2].children = [...productList[1].children[2].children, ...ios];

	return productList;
}

const makeUserConfig = ({ userConfig, resData }: Record<string, any>) => {
	userConfig.map((item: Record<string, any>) => {
		if (resData[item.value]) item.children = resData[item.value];
		// if (resData[item.value]) item.children = [...resData[item.value], { desc: 'test', id: '112233' }];
		return null;
	})
	return userConfig;
}

const makeParams = async (resData: Record<string, any>, analyseMode: number, timeRange: string) => {
	const appVersion = resData.productArr.length === 0 ? '' :
		/\./.test(resData.productArr[resData.productArr.length - 1]) ? resData.productArr[resData.productArr.length - 1] : '';

	const platformCode = resData.productArr.length === 0 ? 0 : await makeplatformCode(resData);
	let userConfig: Record<string, Record<string, string>[]> = {
		enterpriseLevelConditions: [],
		orgIdConditions: [],
		tradeConditions: [],
		userPositionConditions: [],
	}
	userConfig = resData.userArr[0].type === 'all' ? userConfig : await makeUserList(resData.userArr, userConfig);

	return {
		analyseMode,
		"beginTime": resData.date[0],
		"endTime": resData.date[1],
		appVersion,
		platformCode,
		"pageIds": resData.pageArr,
		...userConfig,
		"timeRange": Number(timeRange),
	};
}

const makeplatformCode = ({ productArr }: Record<string, any>) => {
	const codeConfig = {
		IOS: 1,
		Android: 2,
		PC: 3,
		allAPP: 4,
	}
	return productArr[0] === "PC" ? 3 : codeConfig[productArr[1]]
}

const makeUserList = (userArr: Record<string, any>, userConfig: Record<string, Record<string, string>[]>) => {
	userArr.map((item: Record<string, any>) => {
		if (item.select.length > 0) {
			let uesrList: Record<string, string>[] = [];
			item.list.map((item1: Record<string, any>) => {
				if (item.select.findIndex((item2: string) => item2 === item1.desc) >= 0) {
					uesrList.push({ value: item1.id, op: item.condition });
				}
				return null;
			})
			userConfig[item.type] = [
				...userConfig[item.type],
				...uesrList
			]
		}
		return null
	})
	return userConfig;
}

const analysisRequest = async ({API, params, dispatch}: Record<string, any>) => {
	const { retMsg, retCode, data } = await postRequest(API, { params });
	if (retCode !== '0') {
		dispatch({ type, payload: { loading: false } })
		message.error(retMsg);
		return;
	}
	
	// console.log('analysisData-res: ', data)
	const mark = Math.random().toString();
	dispatch({ type, payload: { loading: false, searchParams: params, summary: data.summary || {}, pages: data.pages || [], markNum: mark, markTable: mark, showTable: "all"} })
}