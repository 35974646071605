
import { actionType } from '../actionCreators/RolePermissionsManage_AC';

const defaultState: Record<string, any> = {
	loading: false,

	isChange: false,
	dataSource: [],
	permissionsTree: [],//	权限-Tree  
	dataConfig: {},
	oriDataConfig: {
		shortName: '',
		allName: '',
		code: '',
		area: '',
		address: '',
		name: '',
		phone: '',
		creatName: '',
		creatTime: '',
		updataTime: '',
		manage_name: '',
		manage_phone: '',
		manage_user: '',
		manage_roles: '',
	},

	visible: false,//编辑查看
	addVisible: false,
	contactInfoVisible: false,//联系人信息弹窗
	attAchmentVisible: false, //附件
	modalType: 'add',
	closedModal: '',//关闭弹窗做一个展示
};
defaultState.permissionsTree.push(
	{
		title: '测试数据01test',
		key: '0-0',
		children: [
			{
				title: '0-0-0',
				key: '0-0-0',
				children: [
					{ title: '0-0-0-0', key: '0-0-0-0' },
					{ title: '0-0-0-1', key: '0-0-0-1' },
					{ title: '0-0-0-2', key: '0-0-0-2' },
				],
			},
			{
				title: '0-0-1',
				key: '0-0-1',
				children: [
					{ title: '0-0-1-0', key: '0-0-1-0' },
					{ title: '0-0-1-1', key: '0-0-1-1' },
					{ title: '0-0-1-2', key: '0-0-1-2' },
				],
			},
			{
				title: '0-0-2',
				key: '0-0-2',
			},
		],
	},
	{
		title: '测试数据02',
		key: '0-1',
		children: [
			{ title: '0-1-0-0', key: '0-1-0-0' },
			{ title: '0-1-0-1', key: '0-1-0-1' },
			{ title: '0-1-0-2', key: '0-1-0-2' },
		],
	},
	{
		title: '测试数据03',
		key: '0-2',
	},
)
defaultState.dataSource.push({
	shortName: '博依特科技',
	allName: '广州博依特智能信息科技有限公司',
	code: 'POI-TECH',
	area: '广州市',
	address: '',
	name: '',
	phone: '',
	creatName: '',
	creatTime: '2019-11-24 11:00',
	updataTime: '',
	manage_name: '张三',
	manage_phone: '13611112222',
	manage_user: 'poi-techAdmin',
	manage_roles: '运营商机构管理员',
}, {
	shortName: '简一',
	allName: '简一陶瓷',
	code: 'POI-JY',
	area: '清远',
	address: '',
	name: '',
	phone: '',
	creatName: '',
	creatTime: '2019-11-24 11:00',
	updataTime: '',
	manage_name: '李四',
	manage_phone: '13611112222',
	manage_user: 'poi-techAdmin',
	manage_roles: '运营商机构管理员',
});

export const RolePermissionsManageReducer = (state: Record<string, any> = defaultState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case actionType.DEFAULT_REPLACE:
			return { ...state, ...payload };
		default:
			return state;
	}
}