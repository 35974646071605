import { actionType } from '../actionCreators/ProdMenuManage_AC';

const defaultState: Record<string, any> = {
	loading: false,

   isChange: false,
   isSearch: false,
	dataSource: [],

	keyWord: '',
   product: '客户运营平台',
   preList: [],
   productList: [{ name: '客户运营平台' }, { name: '云平台' }, { name: 'App' }],
	dataConfig: {},
	oriDataConfig: {
      type: '',
      name: '',
      url: '',
      preName: '',
      order: '',
      code: '',
	},
	
	visible: false,
	modalType: 'add',
};
defaultState.dataSource.push({
	name: '机构管理',
   order: '1',
   type: '模块',
   url: null,
   preName: null,
   code: null,
	children: [{
      name: '运营商管理',
      order: '1',
      type: '菜单',
      url: '/agency_carrierManage',
      preName: '机构管理',
      code: null,
      children: [
         {
            name: '新增',
            type: '操作',
            url: null,
            preName: '运营商管理',
            code: 'add',
         },
         {
            name: '删除',
            type: '操作',
            url: null,
            preName: '运营商管理',
            code: 'delete',
         }
      ]},
      {
         name: '岗位管理',
         order: '12',
         type: '菜单',
         children: null,
         url: '/agency_GW',
         preName: '机构管理',
         code: null,
      }
   ],
},{
	name: '用户及权限管理',
   order: '2',
   type: '模块',
   url: null,
   preName: null,
   code: null,
	children: [{
      name: '用户管理',
      order: '1',
      type: '菜单',
      url: '/userManage',
      preName: '用户及权限管理',
      code: null,
      children: [
         {
            name: '新增',
            type: '操作',
            url: null,
            preName: '用户管理',
            code: 'add',
         },
      ]},
      {
         name: '角色管理',
         type: '菜单',
         url: '/userManage',
         preName: '用户及权限管理',
         code: null,
      }
   ],
},);

export const ProdMenuManageReducer = (state: Record<string, any> = defaultState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case actionType.DEFAULT_REPLACE:
			return { ...state,  ...payload };
		default:
			return state;
	}
}