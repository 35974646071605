
// 客户端类型
// const ClientType = 'group'
const ClientType = 'cloud'

// 客户端编码储存标签
const ClientTag = 'client'

// 组织编码储存标签
const OrgTag = 'org'

// 组织编码ID储存标签
const OrgIdTag = 'regionId'

// 应用访问令牌储存标签
const AccessTokenTag = 'accessToken'

// 请求响应成功返回编码
const SuccessCode = '0'

// 企业模型储存标签
const EModelName = 'eModel'

// 全局变量state默认值储存标签
const DefaultStateTag = 'defaultState'

// 版本号储存标签
const VersionTag = 'version'
//接口版本好 ，每个接口必传
const version = 'string01'
// 当前路由储存标签
const CurRouterTag = 'curRouter'

// 产品3.0登录页面RSA加密公钥
const RsaPublicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKoda9SvdvUDvF8CFSlrnYIQqWe/TVxK0S6IzjWjS4PSIOgDI4zvzlclkOWdzXfDTlK/gHIGsIDFwqUPzjBn0v8CAwEAAQ==';

// 打开新页面展示操作文档手册路由
const DocRouter = '/static/html/'

// Token失效或无效时，后端返回编码
const TokenInvalidCode = '010001'

// 定义退出登录时需要清除LocalStorage标签
const DelLocalStorageTag = [OrgIdTag, OrgTag, AccessTokenTag, EModelName, CurRouterTag, DefaultStateTag, 'menus', 'rootSubmenuKeys'];

// 设置当localStorage待存储值为Json时，储存时需转化为String，获取时再解析为Json
const StorageJson = [EModelName, DefaultStateTag]


const env_config= {
	dev: {
		url: '//192.168.100.31/',
		env_text: '测试',
	},
	test: {
		url: '//poit-gateway-test.poi-t.cn/',
		env_text: '测试环境',
	},
	pre_k8s: {
		url: '//api-pre.poi-t.cn/',
		env_text: '预发布-k8s',
	},
	pro: {
		url: '//api.poi-t.cn/',
		env_text: '生产',
	},
}

export {
	version,
	ClientType,
	ClientTag,
	OrgTag,
	OrgIdTag,
	AccessTokenTag,
	SuccessCode,
	DefaultStateTag,
	VersionTag,
	EModelName,
	CurRouterTag,
	DocRouter,
	TokenInvalidCode,
	DelLocalStorageTag,
	StorageJson,
	RsaPublicKey,
	env_config,
}
