
import { actionType } from '../actionCreators/CustomerEnterpManage_AC';

const defaultState: Record<string, any> = {
	loading: true,
	modalLoading:true,

	isChange: false,
	dataSource: [],
	institutionsOptions: [],//企业等级
	popertyList: [],//企业性质
	orgIdUserOptions: [],//机构下面的用户
	attachmentFileList: [], //附件列表
	//modal
	tradeTreeOptions: [],//所属行业
	higherInstitutionsOptions: [],//上级机构
	areaOptions: [],//所在地区
	agenciesUseOptions: [],//机构用途
	roleOptions: [], //角色
	dataConfig: {},
	contactInfoList: [],//联系人信息
	oriDataConfig: {
		shortName: '',
		allName: '',
		code: '',
		area: '',
		address: '',
		name: '',
		phone: '',
		creatName: '',
		creatTime: '',
		updataTime: '',
		manage_name: '',
		manage_phone: '',
		manage_user: '',
		manage_roles: '',
	},
	associatedId: "",//附件Id
	visible: false,//编辑查看
	addVisible: false,//增加客户
	attachmentVisible: false, //附件
	contactInfoVisible: false,//联系人订单管理
	modalType: 'add',
	closedModal: '',//关闭弹窗做一个展示
};

defaultState.higherInstitutionsOptions = [
	{ label: '上级机构', value: 1, },
	{ label: '完成', value: 10, },
	{ label: '未完成', value: 112, }];
defaultState.agenciesUseOptions = [
	{ label: '机构用途', value: 1, },
	{ label: '完成', value: 10, },
	{ label: '未完成', value: 112, }];
defaultState.roleOptions = [
	{ label: '角色1', value: 1, },
	{ label: '角色2', value: 10, },
	{ label: '角色3', value: 112, }];

export const CustomerEnterpManageReducer = (state: Record<string, any> = defaultState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case actionType.DEFAULT_REPLACE:
			return { ...state, ...payload };
		default:
			return state;
	}
}