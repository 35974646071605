import { actionType, defaultState } from '../actionCreators/ValueBoard_AC';

export const ValueBoardReducer = (state: Record<string, any> = defaultState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case actionType.DATA_UPDATE:
      return { ...state,  ...payload };
    default:
      return state;
  }
}
