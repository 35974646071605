import * as React from 'react'
import * as ReactDOM from 'react-dom'
import AppRouter from './router/AppRouter'
import './main.scss'
import { Provider } from 'react-redux'
import store from './redux/store'
import {createWatermark} from './assets/js/com';
// antd 组件中文配置
import zh_CN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

ReactDOM.render(
  <Provider store={ store }>
    <ConfigProvider locale={ zh_CN }>
      <AppRouter />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement,
  ()=>{
    createWatermark();
  },
);
