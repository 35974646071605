
import { actionType } from '../actionCreators/UserManage_AC';

const defaultState: Record<string, any> = {
	loading: true,

	isChange: false,
	dataSource: [],
	menuTree: [],//部门树
	defaultSelectedVal: '',//侧边数默认值
	departmentOptions: [],//部门下拉-接口
	roleOptions: [],//角色下拉-接口
	statuSelected: '',
	//moda
	subordinateDepartmentsOptions: [],//所属部门
	jobsOptions: [],//岗位下拉-接口
	statusOptions: [],//状态
	dataConfig: {},
	oriDataConfig: {
		shortName: '',
		allName: '',
		code: '',
		area: '',
		address: '',
		name: '',
		phone: '',
		creatName: '',
		creatTime: '',
		updataTime: '',
		manage_name: '',
		manage_phone: '',
		manage_user: '',
		manage_roles: '',
	},
	userDetailInfo: {},//单条数据信息
	visible: false,//编辑查看
	roleManageVisible: false,//角色管理
	resetPwdVisible: false, //重置密码
	addVisible: false,
	modalType: 'add',
	closedModal: '',//关闭弹窗做一个展示

};

defaultState.statusOptions = [
	{ label: '启用', value: 1, },
	{ label: '停用', value: 0, }]

defaultState.roleOptions = [
	{ label: '角色1', value: 1, },
	{ label: '角色3', value: 10, },
	{ label: '角色2', value: 112, }]
export const UserManageReducer = (state: Record<string, any> = defaultState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case actionType.DEFAULT_REPLACE:
			return { ...state, ...payload };
		default:
			return state;
	}
}