import { env_config } from "../common";

const env = process.env.REACT_APP_ENV || 'dev';
const url = env_config[env]?.url + 'poit-operation-platform/';

export const Api = {
  department: {
    // 部门树
    departmentListTree: `${url}organization/department/tree/org/list`,
    //部门options
    departmentList: `${url}organization/department/list`,
    departmentListIf: `${url}organization/department/tree/select/list`, // 机构选择部门（去除当前部门子树）
    deleteDepartment: `${url}organization/department/delete`,
    add: `${url}organization/department/add`,
    //修改部门
    modify: `${url}organization/department/modify`,
    orgDetail: `${url}organization/user/get`,
    // 获取组织详情
    getOrganization: `${url}organization/organization/get`,
    // 组织列表
    organizationList: `${url}organization/list`,
    //修改组织信息
    organizationModify: `${url}organization/modify`,
    // 根据组织id和职位名称搜索-岗位
    positionList: `${url}organization/position/list`,
    directorLit: `${url}organization/user/list`,// 主管
    geoLlist: `${url}enterprise/geo/list`, //区域树

  },
  userManage: {
    add: `${url}user/add`,
    deleteUser: `${url}user/delete`,
    get: `${url}user/get`,//用户管理--查看单个用户信息
    modify: `${url}user/modify`,
    search: `${url}organization/department/user/list`,
    reset: `${url}user/password/reset`, //重置密码
    positionList: `${url}organization/position/list `,//岗位下拉
    state: `${url}user/modify/state`,//用户状态修改
  },
  customerEnterpManage: {
    search: `${url}enterprise/list`,//客户企业列表
    genList: `${url}enterprise/geo/list`,//区域信息
    modify: `${url}enterprise/contact/modify`,//修改
    contactList: `${url}enterprise/contact/list`,//获取客户企业用户联系人信息列表
    delete: `${url}enterprise/contact/delete`,// 删除客户企业用户联系人信息列表
    add: `${url}enterprise/contact/add`,   //修改客户企业用户联系人信息列表
    modifyEnter: `${url}enterprise/modify`,    //修改客户企业
    tradeTree: `${url}enterprise/trade/tree`,    //企业行业树
    emenuList: `${url}base/enum/list`,

  },
  customerProjectManage: {
    search: `${url}project/enterpriseProject/list`,//table
    delete: `${url}project/enterpriseProject/delete`,
    projectRenewList: `${url}project/projectRenew/list`,//续签-list
    projectRenewAdd: `${url}project/projectRenew/add`,//续签-add
    projectRenewModify: `${url}project/projectRenew/modify`,//修改-edit
    projectRenewDelete: `${url}project/projectRenew/delete`,//续签-del
    // menuList
    productList: `${url}project/product/list`,//付费产品
    projectScopeTree: `${url}project/projectScope/tree`,//项目范围菜单树
    projectScopeAdd: `${url}project/projectScope/add`,//项目范围-add
    projectScopeEdit: `${url}project/projectScope/modify`,//项目范围-add


    projectEvaluateGet: `${url}project/enterpriseProject/projectEvaluate/get`,//项目管理--实施评价
    projectEvaluateModify: `${url}project/enterpriseProject/projectEvaluate/modify`,//项目管理--实施评价修改
    projectEvaluateAdd: `${url}project/enterpriseProject/projectEvaluate/add`,//项目管理--实施评价新增
    projectEvaluateDel: `${url}project/enterpriseProject/projectEvaluate/delete`,//项目管理--实施评价删除

    projectInfoGet: `${url}project/enterpriseProject/projectInfo/get`,//项目管理--基本信息
    projectInfoedit: `${url}project/enterpriseProject/projectInfo/modify`, //项目管理--基本信息修改
    projectInfoAdd: `${url}project/enterpriseProject/projectInfo/add`,//项目管理--基本信息新增
    projectInfoDel: `${url}project/enterpriseProject/projectInfo/delete`,//项目管理--基本信息删除
    orgCustomerEnterList: `${url}organization/children/list`,//..机构下所属企业

    feedList: `${url}/project/feedback/list`,//项目实施结果+项目存在问题项列表
  },
  common: {
    formData: 'http://120.77.86.1:6502/gz-api/supervise/uploadSuperviseReleaseDocumentResp',
    upload: 'http://test.poi-t.cn:8310/cloud-biz-enterprise/file/upload'
  },
  // 文件相关
  file: {
    upload: `${url}file/organization/upload`,
    delete: `${url}/file/organization/delete`,
    list: `${url}file/organization/list`,
    url: `${url}file/url`,//获取文件URL
    imgUnload: `${url}file/upload`,
  },
  login: {
    GET_PASSWARD: `${url}user/account/login`,  // 密码登录接口
    GET_SENDVERCODE: `${url}user/verification/code/get`,  // 获取手机登录验证码
    GET_VERIFICATIONCODE: `${url}user/mobile/login`,  // 手机验证码登录接口
  },
  menu: {
    GET_LISTUSERMENUS: `${url}resource/listUserMenus`
  },
  project: {
    addProject: `${url}project/feedback/add`, // 项目实施结果+项目存在问题项新增
    deleteProject: `${url}project/feedback/delete`, // 项目实施结果+项目存在问题项删除
    getProjectList: `${url}project/feedback/list`, // 项目实施结果+项目存在问题项列表
    modifyProject: `${url}project/feedback/modify`, // 项目实施结果+项目存在问题项修改
  },
  analysis: {
    APP_VERSION: `${url}data/analyse/app/version/list`, // 获取APP版本信息列表
    ENTER_LEVEL: `${url}data/analyse/enterprise/level/list`, // 获取企业等级列表
    ENTER_LIST: `${url}data/analyse/enterprise/list`, // 获取企业简称列表
    PAGE: `${url}data/analyse/page/list`, // 获取企业等级列表
    TRADE: `${url}data/analyse/trade/list`, // 获取企业所属行业列表
    USER_POSITION: `${url}data/analyse/user/position/list`, // 获取用户岗位列表

    PAGE_STATISTICS_GET: `${url}page/analyse/page/statistics/get`, // 获取页面数据分析结果，按页面
    PAGE_STATISTICS_EXPORT: `${url}page/analyse/page/statistics/export`, // 导出页面数据分析结果，按页面
    PAGE_DETAIL_GET: `${url}page/analyse/detail/list`, // 获取页面数据分析明细表
    PAGE_DETAIL_EXPORT: `${url}page/analyse/page/detail/export`, // 导出页面数据分析明细表，按页面
    TIME_STATISTICS_GET: `${url}page/analyse/time/statistics/get`, // 获取页面数据分析结果，按时间
    TIME_STATISTICS_EXPORT: `${url}page/analyse/time/statistics/export`, // 导出页面数据分析结果，按时间
    TIME_DETAIL_EXPORT: `${url}page/analyse/time/detail/export`, // 导出页面数据分析明细表，按时间

    USER_ACTIVE_DETAIL_EXPORT: `${url}user/analyse/active/trend/detail/export`, // 导出用户活跃趋势明细表
    USER_ACTIVE_DETAIL_LIST: `${url}user/analyse/active/trend/detail/list`, // 获取用户活跃趋势明细表
    USER_ACTIVE_SUMMARY_EXPORT: `${url}user/analyse/active/trend/summary/export`, // 导出用户活跃趋势汇总表
    USER_ACTIVE_SUMMARY_LIST: `${url}user/analyse/active/trend/summary/list`, // 获取用户活跃趋势汇总表
    USER_GROW_DETAIL_EXPORT: `${url}user/analyse/grow/trend/detail/export`, // 导出用户增长趋势明细表
    USER_GROW_DETAIL_LIST: `${url}user/analyse/grow/trend/detail/list`, // 获取用户增长趋势明细表
    USER_GROW_SUMMARY_EXPORT: `${url}user/analyse/grow/trend/summary/export`, // 导出用户增长趋势汇总表
    USER_GROW_SUMMARY_LIST: `${url}user/analyse/grow/trend/summary/list`, // 获取用户增长趋势汇总表
  },
  cooperation: {
    MATTERANALYSIS_DEVICE: `${url}cooperation/matterAnalysis/device`, // 协作--拍照事项（设备)
    MATTERANALYSIS_LIST: `${url}cooperation/matterAnalysis/list`, // 协作--企业运营分析（主页）
    MATTERANALYSIS_PAGE: `${url}cooperation/matterAnalysis/page`, // 协作--分享事项（页面）
    MATTERMANAGEMENT_PAGE: `${url}cooperation/matterManagement/page`, // 协作 -- 拍照事项(分页)
    MATTERMANAGEMENT_DELETE: `${url}cooperation/matterManagement/delete`, // 协作 -- 拍照事项(删除)
    MATTERMANAGEMENT_FILE_LIST: `${url}cooperation/file/list`, // 协作 -- 拍照事项(文件列表)

  },
  smsPush: {
    ADD: `${url}smsPush/add`, // 添加
    DELETE: `${url}smsPush/delete`, // 删除
    GET: `${url}smsPush/get`, // 获取详情
    MODIFY: `${url}smsPush/modify`, // 修改
    PAGE: `${url}smsPush/page` // 分页
  },
  configControll: {
    getConfig: `${url}config/get`,
    saveConfig: `${url}config/save`,

    listBaseIndex: `${url}home/page/basic/index/list`, // 指标看板基础统计信息，如采集数据量、接入设备等
    getOrgDistribution: `${url}home/page/org/orgDistribution/get`, // 企业各省分布
    listOrgRank: `${url}home/page/org/rank/list`, // 机构访问排名，取访问量前10的客户
    listPageRank: `${url}home/page/page/rank/list`, // 页面访问排名，取访问量前10的页面
    listPageVisitDepth: `${url}home/page/page/visit/depth/list`, // 平均访问深度趋势
    getUserCount: `${url}home/page/user/count/get`, // 用户日活数量
    listDayUserVisit: `${url}home/page/user/visit/list`, // 用户日活趋势
    userActiveNum: `${url}board/event/sevenDay/user/create/active`, // 近7天企业用户数和活跃用户数

    moduleTree: `${url}pageInfo/module/tree/get`,
    enterpriseCount: `${url}value/board/key/enterprise/count`,
  },
  pageInfoController: {
    getConfig: `${url}config/get`,
    listPageRank: `${url}home/page/page/rank/list`, // 页面访问排名，取访问量前10的页面
    sevenDayOrgTop10: `${url}board/event/sevenDay/org/top10`, // 客户排行TOP10
    productTop10: `${url}board/event/sevenDay/all/product/top10`, // 近7天产品功能pv-TOP10，暨页面排行TOP10
    pageInfoNewPageRankList: `${url}pageInfo/new/page/rank/list`,  // 获取新页面排名
    newProductTop6: `${url}board/event/sevenDay/new/product/top6`,  // 近7天新产品功能pv-TOP6
    listDayUserVisit: `${url}home/page/user/visit/list`, // 用户日活趋势
    sevenDayActiveNum: `${url}board/event/sevenDay/active/user`, // 近7天用户活跃度,暨用户日活趋势（近7天）
    pageInfoFeatureTrendList: `${url}pageInfo/feature/trend/list`, // 关注与核心功能趋势
    sevenDayAllAccessTop6: `${url}board/event/sevenDay/all/access/top6`, // 核心模块访问趋势
    sevenDayNewAccessTop6: `${url}board/event/sevenDay/new/access/top6`, // 新功能访问趋势

    sevenDayDv: `${url}board/event/sevenDay/dv`, // 平均访问深度（近7天）
  },
  enterpriseDetail: {
    eventPageOrgDetail: `${url}event/page/org/detail`, // 企业明细分页
    eventExportOrgDetail: `${url}event/export/org/detail`, // 企业明细分页-导出
    eventDownOrgDetail: `${url}event/down/org/detail`, // 企业明细下钻
    eventPagePvOrgDetail: `${url}event/page/pv/org/detail`, // 企业明细具体pv
    eventExportPvOrgDetail: `${url}event/export/pv/org/detail`, // 企业明细具体pv-导出
  },
  OverallOverview: {
    eventPageOverallDetail: `${url}event/page/overall/overview`, // 整体概况分页
    eventExportOverallOverview: `${url}event/export/overall/overview`, // 整体概况分页-导出
    eventPagePVOverallOverview: `${url}event/page/pv/overall/overview`, // 整体概况具体pv
    eventExportPVOverallOverview: `${url}event/export/pv/overall/overview`, // 整体概况具体pv-导出
    eventDownOverallOverview: `${url}event/down/overall/overview`, // 整体概况下钻
  },
  // 操作明细
  operationDetail:{
    getList:`${url}event/page/operation/detail`, // 操作明细分页
    exportData:`${url}event/export/operation/detail`, // 操作明细分页-导出
  },
  // 版本需求统计
  versionRequirementStatistics:{
    getList:`${url}demand/version/statistics/page`, //分页teambition需求版本统计数据
  },
  // 项目管理
  programManageStatistics: {
    getList: `${url}program/manage/report/list`, // 列表
    download: `${url}program/manage/report/download`, // 下载
  },
  productionAndIndex:{
    getList:`${url}demand/version/statistics/research/index/page`, //产研指标
  },
  // 客户管理
  customerManagement:{
    getList:`${url}customer/management/page`,
    exportData:`${url}customer/management/export`,
    importData:`${url}customer/management/import`,
    createItem:`${url}customer/management/add`,
    deleteItem:`${url}customer/management/delete`,
    editItem:`${url}customer/management/edit`,
    getSelectOptions:`${url}customer/management/field/value`, // 客户管理--字段筛选数据
    getTableHeader:`${url}customer/management/fields/list`, // 用户设置显示字段-列表
    setTableHeader:`${url}customer/management/fields/save`, // 用户设置显示字段-保存
  },
}
