import { dispatchFun } from 'src/assets/js/com';

// import { getRequest, api } from 'src/assets/js/apiConfig/request';
// import { message } from 'antd';

export const actionType: Record<string, symbol> = {
	DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
	INIT_DATA: Symbol('INIT_DATA'),
}

const type = actionType.DEFAULT_REPLACE;

const obj = {
	visible: { visible: true, },
	contactInfo: { contactInfoVisible: true, },
	attAchment: { attAchmentVisible: true },
	add: { addVisible: true, },
}


export const openModal = ({ modalType, visible, record }: Record<string, any>) => {
	const popup: object = obj[visible];
	return async (dispatch: any) => {
		dispatchFun({ dispatch, type, res: { ...popup, closedModal: visible, dataConfig: record, modalType } })
	}
}

export const modalCallBack = ({ modalType, data }: Record<string, any>) => {
	const obj2 = {
		visible: { visible: false, },
		contactInfo: { contactInfoVisible: false, },
		attAchment: { attAchmentVisible: false },
		add: { addVisible: false, },
	}
	return async (dispatch: any, getState: any) => {
		// isChange
		const { oriDataConfig, closedModal, dataSource, modalType } = getState().RolePermissionsManageReducer;
		const popup: object = obj2[closedModal];
		console.log(popup);
		const res: Record<string, any> = { ...popup, dataConfig: oriDataConfig, dataSource };
		console.log(modalType)
		if (modalType === 'updata') {
			res.dataSource.push(data);
			// res.isChange = !isChange;
		}
		dispatchFun({ dispatch, type, res })
	}
}