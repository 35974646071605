import { dispatchFun, assembledJson } from 'src/assets/js/com';

import { Api } from 'src/assets/js/apiConfig/urlConfig';
import { postRequest } from 'src/assets/js/apiConfig/request';
import { message } from 'antd';

export const actionType: Record<string, symbol> = {
	DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
	INIT_DATA: Symbol('INIT_DATA'),
}

const type = actionType.DEFAULT_REPLACE;


export const add = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg }: any = await postRequest(Api.userManage.add, { params });

		if (retCode !== '0') { message.error(retMsg); return; }

		const obj = { addVisible: false };
		dispatchFun({ dispatch, type, res: obj })
	};
}
export const modify = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg }: any = await postRequest(Api.userManage.modify, { params });

		if (retCode !== '0') { message.error(retMsg); return; }

		const obj = { visible: false };
		dispatchFun({ dispatch, type, res: obj })
	};
}
export const userStates = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg }: any = await postRequest(Api.userManage.state, { params });

		if (retCode !== '0') { message.error(retMsg); return; }

		const obj = { visible: false };
		dispatchFun({ dispatch, type, res: obj })
	};
}
export const search = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.userManage.search, { params });

		if (retCode !== '0') { message.error(retMsg); return; }
		const obj = { dataSource: res.list, loading: false };

		dispatchFun({ dispatch, type, res: obj, })
	};
}


export const deleteData = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg }: any = await postRequest(Api.userManage.deleteUser, { params });
		if (retCode !== '0') { message.error(retMsg); return; }

	};
}

export const get = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.userManage.get, { params });

		if (retCode !== '0') { message.error(retMsg); return; }


		const obj = { userDetailInfo: res.data };

		dispatchFun({ dispatch, type, res: obj })
	};
}
export const reset = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, }: any = await postRequest(Api.userManage.reset, { params });

		if (retCode !== '0') { message.error(retMsg); return; }
		const obj = { resetPwdVisible: false };
		dispatchFun({ dispatch, type, res: obj })
	};
}
// 岗位下拉
export const positionList = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.userManage.positionList, { params });

		if (retCode !== '0') { message.error(retMsg); return; }

		const obj = { jobsOptions: res.data };
		dispatchFun({ dispatch, type, res: obj })
	};
}
export const departmentList = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.department.departmentList, { params });

		if (retCode !== '0') { message.error(retMsg); return; }
	 
		const obj = { subordinateDepartmentsOptions: res.data };
		dispatchFun({ dispatch, type, res: obj })
	};
}
//部门列表-Tree
export const departmentListTree = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.department.departmentListTree, { params });
		if (retCode !== '0') { message.error(retMsg); return; }

		const newData = assembledJson(res.data);
		const obj = { menuTree: newData, defaultSelectedVal: [`${res.data.length > 0 ? res.data[0].departmentId : ''}`] };
		dispatchFun({ dispatch, type, res: obj })
	};
}
const obj = {
	visible: { visible: true, },
	roleManage: { roleManageVisible: true },
	resetPwd: { resetPwdVisible: true },
	add: { addVisible: true, },
}


export const openModal = ({ modalType, visible, record }: Record<string, any>) => {
	const popup: object = obj[visible];
	return async (dispatch: any) => {
		// closedModal=?关闭Modal依赖
		dispatchFun({ dispatch, type, res: { ...popup, closedModal: visible, dataConfig: record, modalType } })
	}
}

export const modalCallBack = ({ modalType, data }: Record<string, any>) => {
	const obj2 = {
		visible: { visible: false, },
		roleManage: { roleManageVisible: false },
		resetPwd: { resetPwdVisible: false },
		add: { addVisible: false, },
	}
	return async (dispatch: any, getState: any) => {
		// isChange
		const { oriDataConfig, closedModal, dataSource, modalType } = getState().UserManageReducer;
		const popup: object = obj2[closedModal];
		const res: Record<string, any> = { ...popup, dataConfig: oriDataConfig, dataSource };
		if (modalType === 'updata') {
			res.dataSource.push(data);
			// res.isChange = !isChange;
		}
		dispatchFun({ dispatch, type, res })
	}
}