import { dispatchFun, assembledJson } from 'src/assets/js/com';
import { Api } from 'src/assets/js/apiConfig/urlConfig';
import { postRequest } from 'src/assets/js/apiConfig/request';
import { message } from 'antd';

export const actionType: Record<string, symbol> = {
	DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
	INIT_DATA: Symbol('INIT_DATA'),
}

const type = actionType.DEFAULT_REPLACE;



//部门列表-selsect
export const departmentList = (params?: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.department.departmentList, { params });
		if (retCode !== '0') { message.error(retMsg); return; }

		const obj = { subordinateDepartmentsOptions: res.data };
		dispatchFun({ dispatch, type, res: obj })
	};
}

//机构选择部门（去除当前部门子树）
export const departmentListIf = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.department.departmentListIf, { params });
		if (retCode !== '0') { message.error(retMsg); return; }
		// const obj = { departmentListIfOptions: res.data };
		const newData = assembledJson(res.data);
		// console.log(newData);
		const obj = { departmentListIfOptions: newData };
		dispatchFun({ dispatch, type, res: obj })
	};
}

//部门主管
export const directorLit = (params?: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.department.directorLit, { params });
		if (retCode !== '0') { message.error(retMsg); return; }

		const obj = { departmentHeadOptions: res.data };
		dispatchFun({ dispatch, type, res: obj })
	};
}
export const add = (params?: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg }: any = await postRequest(Api.department.add, { params });
		if (retCode !== '0') { message.error(retMsg); return; }

		const obj = { addVisible: false };
		dispatchFun({ dispatch, type, res: obj })
	};
}
export const deleteData = (params?: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg }: any = await postRequest(Api.department.deleteDepartment, { params });
		if (retCode !== '0') { message.error(retMsg); return; }
		console.log(retMsg)
		// message.success(retMsg);

		// dispatchFun({ dispatch, type, res: obj })
	};
}
export const modify = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg }: any = await postRequest(Api.department.modify, { params });
		if (retCode !== '0') { message.error(retMsg); return; }
		const obj = { visible: false };
		dispatchFun({ dispatch, type, res: obj })
	};
}
//部门列表-Tree-Table
export const departmentListTree = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.department.departmentListTree, { params });
		if (retCode !== '0') { message.error(retMsg); return; }

		const newData = assembledJson(res.data);
		// console.log(newData);
		const obj = { dataSource: newData, loading: false  };
		dispatchFun({ dispatch, type, res: obj })
	};
}
const obj = {
	visible: { visible: true, },
	add: { addVisible: true, },
}

export const openModal = ({ modalType, visible, record }: Record<string, any>) => {
	const popup: object = obj[visible];
	return async (dispatch: any) => {
		dispatchFun({ dispatch, type, res: { ...popup, closedModal: visible, dataConfig: record, modalType } })
	}
}
// {
//   "departmentId": 0,
//   "operateUserId": 0,
//   "orgId": 0,
//   "version": "string"
// }
export const modalCallBack = ({ modalType, data }: Record<string, any>) => {
	const obj2 = {
		visible: { visible: false },
		add: { addVisible: false },
	}
	return async (dispatch: any, getState: any) => {
		// isChange
		const { oriDataConfig, closedModal, dataSource, modalType } = getState().DepartmentManageReducer;
		const popup: object = obj2[closedModal];
		const res: Record<string, any> = { ...popup, dataConfig: oriDataConfig, dataSource };
		if (modalType === 'updata') {
			res.dataSource.push(data);
			// res.isChange = !isChange;
		}
		dispatchFun({ dispatch, type, res })
	}
}