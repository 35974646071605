import { postRequest } from 'src/assets/js/apiConfig/request';
import { Api } from 'src/assets/js/apiConfig/urlConfig';
import { dispatchFun } from 'src/assets/js/com';
import { message } from 'antd';


export const actionType: Record<string, symbol> = {
   DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
}
export const defaultState: Record<string, any> = {
	loading: false,
	keyWord: '',
	pageNum: 1,
	pageSize: 10,
	dataSource: {},

	markTable: false,
	isOpen: false,
	ModalType: 'add',
	record: null,
	isOpenResult: false,
}

const type = actionType.DEFAULT_REPLACE;

export const searchTable = ({ keyWord, pageNum, pageSize }: Record<string, any> = {}) => {
	return async (dispatch: any, getState: any) => {
		dispatch({ type, payload: { loading: true } });

		const { markTable, dataSource } = getState().SMSPushReducer;
		const params = {
			searchWord: keyWord,
			pageNum: pageNum || dataSource.current || 1,
			pageSize: pageSize || dataSource.size || 10
		 };

		const { retMsg, retCode, data } = await postRequest(Api.smsPush.PAGE, { params });
		if (retCode !== '0') {
			dispatch({ type, payload: { loading: false } })
			message.error(retMsg);
			return;
		}
		dispatch({ type, payload: { loading: false, markTable: !markTable, keyWord, dataSource: data } });
	}
}

export const addSmsPush= (params: Record<string, string | string[]>) => {
	return async (dispatch: any, getState: any) => {
		dispatch({ type, payload: { loading: true } });

		smsPush({ API: 'ADD', params, dispatch, getState });
	}
}

export const modifySmsPush = (params: Record<string, string | string[]>) => {
	return async (dispatch: any, getState: any) => {
		dispatch({ type, payload: { loading: true } });
		const { record: { taskId } } = getState().SMSPushReducer;
		
		smsPush({ API: 'MODIFY', params: { ...params, taskId }, dispatch, getState });
	}
}

const smsPush = async ({ API, params, dispatch, getState }: Record<string, any>) => {
	const { retMsg, retCode } = await postRequest(Api.smsPush[API], { params });
	if (retCode !== '0') {
		dispatch({ type, payload: { loading: false } })
		message.error(retMsg);
		return;
	}
	message.success(`${API === 'ADD' ? '新增' : '修改'}推送成功`);
	dispatch({ type, payload: { loading: false, 'isOpen': false } });
	const { keyWord } = getState().SMSPushReducer;
	const { pageNum, pageSize } = defaultState;
	dispatch(searchTable({ keyWord, pageNum, pageSize }));
}

export const deleteFun = ({ taskId }: Record<string, any>) => {
	return async (dispatch: any, getState: any) => {
		const { retMsg, retCode } = await postRequest(Api.smsPush.DELETE, { params: { taskId } });
		if (retCode !== '0') {
			dispatch({ type, payload: { loading: false } })
			message.error(retMsg);
			return;
		}
		message.success('删除成功');
		const { keyWord } = getState().SMSPushReducer;
		dispatch(searchTable({ keyWord }));
	}
}


export const changeData = (payload: Record<string, any>) => {
	return (dispatch: any) => {
      dispatch({ type, payload });
	};
}

export const openModal = ({ record }: Record<string, any>) => {
	return async (dispatch: any) => {
		dispatchFun({ dispatch, type, res: { isDetail: true, detailList: record.detailList } })
	}
}