// import { getRequest } from 'src/assets/js/apiConfig/request';
import { deepCopy, dispatchFun } from 'src/assets/js/com';
import { message } from 'antd';
// import { FunctionalRouter, } from 'src/router/RouterConfig';
import { _resource_user_userId_tree_POST } from 'src/assets/js/d_ts/operation/api';
import { FunctionalRouter } from 'src/router/RouterConfig';

export const actionType: Record<string, symbol> = {
   DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
}

const type = actionType.DEFAULT_REPLACE;
let menuBtnConfig = {};

// 获取菜单数据
export const getListUserMenus = () => {
	return async (dispatch: any) => {
		const { retCode, retMsg, data } = await _resource_user_userId_tree_POST({});
		if (retCode === '0') {

			localStorage.setItem('menus', JSON.stringify(data))
			data.forEach((item: Record<string, any>) => {
				item.icon = FunctionalRouter.find(item1 => item1.path === item.resourcesUri)?.icon;
			})

			// 收集功能操作集合
			menuBtnConfig = {};
			RecurrenceDelBtnFunc(data);
			sessionStorage.setItem('menuBtnConfig', JSON.stringify(menuBtnConfig));
			
			dispatchFun({ dispatch, type, res: { listUserMenus: data } })
		} else {
			message.error(retMsg);
		}
	};
}

export const changeData = (dataType: string, value: string | number | boolean | string[]) => {
	return (dispatch: any) => {
		dispatchFun({ dispatch, type, res: { [dataType]: value } })
	};
}


const RecurrenceDelBtnFunc = (menu: Record<string, any>[], pre_menu: Record<string, any> = {}) => {
    if (menu[0]?.resourcesType === 2) {
      menuBtnConfig[pre_menu.resourcesUri] = pre_menu.childResources;

      pre_menu.children = deepCopy(pre_menu.childResources);
      pre_menu.childResources = [];
    }

    menu.map(item => {
      if (item.childResources?.length > 0) RecurrenceDelBtnFunc(item.childResources, item);
    })
  }
