import { dispatchFun } from 'src/assets/js/com';

import { Api } from 'src/assets/js/apiConfig/urlConfig';
import { postRequest } from 'src/assets/js/apiConfig/request';
import { message } from 'antd';

export const actionType: Record<string, symbol> = {
	DEFAULT_REPLACE: Symbol('DEFAULT_REPLACE'),
	INIT_DATA: Symbol('INIT_DATA'),
}

const type = actionType.DEFAULT_REPLACE;


export const search = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerProjectManage.search, { params });

		if (retCode !== '0') { message.error(retMsg); return; }

		// message.success(retMsg);
		const obj = { dataSource: res.list, loading: false };
		dispatchFun({ dispatch, type, res: obj })
	};
}

//table-列表删除
export const tableListDelete = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerProjectManage.delete, { params });

		if (retCode !== '0') { message.error(retMsg); return; }

		// message.success(retMsg);
		const obj = { dataSource: res.list };
		dispatchFun({ dispatch, type, res: obj })
	};
}

//续签相关-projectRenew-list
export const projectRenewList = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerProjectManage.projectRenewList, { params });

		if (retCode !== '0') { message.error(retMsg); return; }
		res.data.map((item: Record<string, any>, index: any, ) => {
			item.key = index;
			return item;
		})
		// message.success(retMsg);
		// console.log(res.data)
		const obj = { projectRenewList: res.data, modalLoading: false };
		dispatchFun({ dispatch, type, res: obj })
	};
}
//续签相关-projectRenew-add
export const projectRenewAdd = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerProjectManage.projectRenewAdd, { params });

		if (retCode !== '0') { message.error(retMsg); return; }

		// message.success(retMsg);
		const obj = { dataSource: res.list };
		dispatchFun({ dispatch, type, res: obj })
	};
}
//续签相关-projectRenew-edit
export const projectRenewModify = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerProjectManage.projectRenewModify, { params });

		if (retCode !== '0') { message.error(retMsg); return; }

		// message.success(retMsg);
		const obj = { dataSource: res.list };
		dispatchFun({ dispatch, type, res: obj })
	};
}
//续签相关-del
export const projectRenewDelete = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerProjectManage.projectRenewDelete, { params });

		if (retCode !== '0') { message.error(retMsg); return; }
		console.log(res)
		// message.success(retMsg);
		// const obj = { dataSource: res.list };
		// dispatchFun({ dispatch, type, res: obj })
	};
}
//项目问题-projectProblemList
export const emenuList = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerEnterpManage.emenuList, { params });

		if (retCode !== '0') { message.error(retMsg); return; }

		// {code: 0, desc: "未启动"}
		//项目状态
		res.data.projectStatusList.map((item: { label: any; desc: any; value: any; code: any; }) => {
			item.label = item.desc;
			item.value = item.code;
			return item;
		})

		res.data.projectProblemList.map((item: { label: any; desc: any; value: any; code: any; }) => {
			item.label = item.desc;
			item.value = item.code ? "yes" : "no";
			return item;
		})

		const obj = { proQuickOptions: res.data.projectProblemList, projectStatusOptions: res.data.projectStatusList };
		dispatchFun({ dispatch, type, res: obj })
	};
}
//文件列表
export const fileList = (params: Record<string, any>, orgId?: any) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.file.list, { params });

		if (retCode !== '0') { message.error(retMsg); return; }
		res.data.map((item: Record<string, any>, index: any, ) => {
			item.status = "done";
			// item.url = item.storagePath;
			item.name = item.originName;
			item.uid = item.fileId;
			return item;
		})

		const obj = { attachmentFileList: res.data, orgId };
		dispatchFun({ dispatch, type, res: obj })
	};
}
//付费产品
export const productList = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerProjectManage.productList, { params });

		if (retCode !== '0') { message.error(retMsg); return; }
		res.data.map((item: { productId: any; name: any; value: any; label: any; }) => {
			item.label = item.name;
			item.value = item.productId.toString();
			return item;
		})
		// console.log(res.data);
		const obj = { payProductsOptions: res.data };
		dispatchFun({ dispatch, type, res: obj })
	};
}
//项目实施评价-查看编辑读取值
export const projectEvaluateGet = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerProjectManage.projectEvaluateGet, { params });
		if (retCode !== '0') { message.error(retMsg); return; }
		res.data.resultIdsArr = res.data.resultIds !== '' ? res.data.resultIds.split(",") : []
		res.data.problemIdsArr = res.data.problemIds !== '' ? res.data.problemIds.split(",") : []

		const obj = { multiProject: res.data };

		dispatchFun({ dispatch, type, res: obj })
	};
}
//项目范围菜单树
export const projectScopeTree = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerProjectManage.projectScopeTree, { params });

		if (retCode !== '0') { message.error(retMsg); return; }
		res.data.list.length && res.data.list.map((item: any) => {
			item.key = item.code;
			item.list.map((item2: any) => {
				item2.children = item2.childResources;
				item2.key = item2.resourcesId;
				item2.isSelected = true;
				item2.children.map((item3: any) => {
					// item3.children = item3.childResources;
					item3.key = item3.resourcesId;
				})
			})
		})
		const obj = { functionScopeTree: res.data.list, editPayProduct: res.data.payProduct };
		dispatchFun({ dispatch, type, res: obj })
	};
}
//代理商列表
export const organizationList = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.department.organizationList, { params });
		if (retCode !== '0') { message.error(retMsg); return; }

		res.data.map((item: { orgId: any; orgName: any; value: any; label: any; }) => {
			item.label = item.orgName;
			item.value = item.orgId;
			return item;
		})

		const obj = { subordinateAgentOptions: res.data };
		dispatchFun({ dispatch, type, res: obj })
	};
}

// 项目管理--实施评价新增
export const projectEvaluateAdd = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		// const { retCode, retMsg, ...res }: any = await postRequest(Api.department.projectEvaluateAdd, { params });
		// if (retCode !== '0') { message.error(retMsg); return; }

		// res.data.map((item: { orgId: any; orgName: any; value: any; label: any; }) => {
		// 	item.label = item.orgName;
		// 	item.value = item.orgId;
		// 	return item;
		// })

		const obj = {} //{ subordinateAgentOptions: res.data };
		dispatchFun({ dispatch, type, res: obj })
	};
}

export const feedListEnd = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerProjectManage.feedList, { params });
		if (retCode !== '0') { message.error(retMsg); return; }
		res.data.map((item: Record<string, any>) => {
			item.projectFeedbackId = item.projectFeedbackId.toString();
			return item;
		})
		const obj = { getGoodsClassListMgmtDepotGoods: res.data };
		dispatchFun({ dispatch, type, res: obj })
	};
}
export const feedListPro = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerProjectManage.feedList, { params });
		if (retCode !== '0') { message.error(retMsg); return; }
		//与上面那个接口相仿
		// 类别 0：项目实施结果 1：项目存在问题
		// getGoodsClassListMgmtDepotGoods: [],//项目实施结果
		// GetCurrentProjectName: [], //项目存在问题
		console.log(res.data);
		res.data.map((item: Record<string, any>) => {
			item.projectFeedbackId = item.projectFeedbackId.toString();
			return item;
		})
		const obj = { GetCurrentProjectName: res.data };
		dispatchFun({ dispatch, type, res: obj })
	};
}


//机构下所属企业-还没有对接完毕-所属企业
export const orgCustomerEnterList = (params?: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.customerProjectManage.orgCustomerEnterList, { params });
		if (retCode !== '0') { message.error(retMsg); return; }

		res.data.map((item: { orgId: string }) => {
			item.orgId = item.orgId.toString();
		})
		const obj = { belongEnterpriseOptions: res.data };
		dispatchFun({ dispatch, type, res: obj })
	};
}


export const deleteData = (params: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg }: any = await postRequest(Api.customerProjectManage.delete, { params });
		if (retCode !== '0') { message.error(retMsg); return; }
		// message.success(retMsg);
	};
}

export const contactList = ({ data }: Record<string, any>) => {

	return async (dispatch: any, getState: any) => {

		const res: Record<string, any> = { projectRenewList: data }

		dispatchFun({ dispatch, type, res })
	}
}

export const openModal = ({ modalType, visible, record, projectId, modalLoading }: Record<string, any>) => {
	let obj = {
		add: { addVisible: true },
		visible: { visible: true },
		attachment: { attachmentVisible: true },
		contract: { contractVisible: true },
	}
	const popup: object = obj[visible];
	return async (dispatch: any) => {
		console.log(record)
		dispatchFun({ dispatch, type, res: { ...popup, closedModal: visible, dataConfig: record, modalType, projectId, modalLoading } })
	}
}
//项目经理
export const directorLit = (params?: Record<string, any>) => {
	return async (dispatch: any) => {
		const { retCode, retMsg, ...res }: any = await postRequest(Api.department.directorLit, { params });
		if (retCode !== '0') { message.error(retMsg); return; }
		res.data.map((item: Record<string, any>) => {
			item.label = item.userName;
			item.value = item.userId;
			return item;
		})

		const obj = { projectManagerOptions: res.data };
		dispatchFun({ dispatch, type, res: obj })
	};
}
export const modalCallBack = ({ modalType, data }: Record<string, any>) => {
	let obj = {
		visible: { visible: false, },
		add: { addVisible: false },
		attachment: { attachmentVisible: false },
		contract: { contractVisible: false },
	}
	return async (dispatch: any, getState: any) => {
		// isChange
		const { oriDataConfig, closedModal, dataSource, modalType } = getState().CustomerProjectManageReducer;
		const popup: object = obj[closedModal];

		const res: Record<string, any> = { ...popup, dataConfig: oriDataConfig, dataSource, projectRenewList: [], attachmentFileList: [] };

		if (modalType === 'updata') {
			res.dataSource.push(data);
			// res.isChange = !isChange;
		}
		dispatchFun({ dispatch, type, res })
	}
}