import { actionType, defaultState } from '../actionCreators/UserDataAnalysis_AC';

export const UserDataAnalysisReducer = (state: Record<string, any> = defaultState, action: any) => {
	const { type, payload } = action;
	switch (type) {
		case actionType.DEFAULT_REPLACE:
			return { ...state,  ...payload };
		default:
			return state;
	}
}