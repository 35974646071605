import { postRequest } from "./apiConfig/request";
import { Api } from "./apiConfig/urlConfig";
import {message} from "antd";

export const getSevenDayActiveNum = async () => {
    const [{ retCode, retMsg, data }, { data: appData }]: any = await Promise.all([
        postRequest(Api.pageInfoController.sevenDayActiveNum, { params: { appType: 3600 } }),
        postRequest(Api.pageInfoController.sevenDayActiveNum, { params: { appType: 3200 } })
    ]);
    if (retCode !== '0') { message.error(retMsg); return; }

    return data.map((item: Record<string, any>, index: number) => ({
        date: item.statDate.replace(/20\d\d-/, ''),
        app: appData[index].activeUserNum,
        pc: item.activeUserNum,
    }))
}

export const getSevenDayDv = async () => {
    const [{ retCode, retMsg, data }, { data: appData }]: any = await Promise.all([
        postRequest(Api.pageInfoController.sevenDayDv, { params: { appType: 3600 } }),
        postRequest(Api.pageInfoController.sevenDayDv, { params: { appType: 3200 } })
    ]);
    if (retCode !== '0') { message.error(retMsg); return; }

    return data.map((item: Record<string, any>, index: number) => ({
        date: item.statDate.replace(/20\d\d-/, ''),
        app: appData[index].dv,
        pc: item.dv,
    }))
}