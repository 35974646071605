import * as React from 'react';
import { Route } from "react-router-dom";
import RouterGuard from './RouterGuard';
import store from '../redux/store';  // 为路由组件添加 props => dispatch
import { FunctionalRouter, FuncChildrenRouter, BoardRouterList } from './RouterConfig';
import { getObjProps } from 'src/assets/js/com';
import SideMenu from 'src/components/common/SideMenu';
import Head from 'src/components/common/Head';

const openMain: string[] = getObjProps([...FunctionalRouter, ...FuncChildrenRouter], 'path')
const boardMain: string[] = getObjProps(BoardRouterList, 'path');

const RenderRoutesMap = (routes: any) => ( routes.map(( route: any, index: number ) => (
    <Route exact={ route.exact || false } key={index} path={route.path} render={ props => {
        const isFunc = openMain.findIndex( key => key === route.path ) >= 0;
        const isBoard = boardMain.findIndex(key => key === route.path ) >= 0;

        return (
            <div className="MainFunContainer">
                {
                    isBoard ? (
                        <div className="BoardContent">
                            <RouterGuard dispatch = {store.dispatch} {...route} {...props} />
                        </div>
                    ) : (
                        <React.Fragment>
                            {isFunc ? <SideMenu push={ props.history.push } /> : null}
                            <div className={`MainContent ${isFunc && 'isFun' }`}>
                                {isFunc ? <Head {...route} {...props} /> : null}
                                <RouterGuard dispatch = {store.dispatch} {...route} {...props} />
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
        )
        // return <RouterGuard dispatch = {store.dispatch} {...route} {...props} />
    }}/>
    ))
)

export default RenderRoutesMap;
