//@ts-nocheck
    /**
 * title: POI-CLOUD 运营平台
 * description: POI-CLOUD 运营平台API
 * version: 0.0.1
 * contact:
 *  */
import qs from 'qs';
import {
  JiChu_QingQiuTi,
  ApiResp_ListEnumResp_,
  Jin7TianYongHuHuoYueDuQingQiuDuiXiang,
  ApiResp_List_YongHuHuoYueDuDuiXiang__,
  ChanPinGongNengPvQingQiuDuiXiang,
  ApiResp_ChanPinFangWenCiShuFanHuiDuiXiang_,
  ApiResp_List_ChanPinGongNengPvFanHuiDuiXiang__,
  ApiResp_List_PingJunFangWenShenDuDuiXiang__,
  ApiResp_List_QiYeFangWenShuDuiXiang__,
  ApiResp_Jin7TianQiYeYongHuShuHeHuoYueYongHuShuFanHuiDuiXiang_,
  GetConfigReq,
  ApiResp_ConfigItem_,
  SaveConfigReq,
  ApiResp_boolean_,
  FileJsonReqQingQiuDuiXiang,
  ApiResp_List_FileUrl__,
  ListDeviceAnalysisReqQingQiuDuiXiang,
  ApiResp_List_DeviceAnalysisDuiXiang__,
  ListEnterpriseAnalysisReqQingQiuDuiXiang,
  ApiResp_List_CooperationAnalysisDuiXiang__,
  ListPageAnalysisReqQingQiuDuiXiang,
  ApiResp_List_PageAnalysisDuiXiang__,
  MatterIDQingQiuDuiXiang,
  PageManagementReqQingQiuDuiXiang,
  ApiResp_PageMatterManagementResp_,
  CustomerManagementSaveReqXinZengQingQiuDuiXiang,
  KeHuGuanLiShanChu,
  CustomerManagementExportReqQingQiuDuiXiang,
  CustomerManagementFieldValueReqQingQiuDuiXiang,
  ApiResp_List_FieldValueInfo__,
  JiChuFeignAuth_QingQiuTi,
  ApiResp_List_FieldListResp__,
  CustomerManagementFieldSaveReqXinZengQingQiuDuiXiang,
  CustomerManagementOneReqQingQiuDuiXiang,
  ApiResp_CustomerInfoV2_,
  CustomerManagementPageReqQingQiuDuiXiang,
  ApiResp_Page_CustomerInfoV2__,
  JiChuFeign_QingQiuTi,
  ApiResp_AppVersion_,
  ApiResp_List_EnterpriseLevelVO__,
  ApiResp_List_PageVO__,
  ApiResp_List_TradeVO__,
  ApiResp_List_UserPosition__,
  DelayTaskXinZengQingQiuDuiXiang,
  ApiResp_long_,
  TaskIdReqQingQiuDuiXiang_,
  ApiResp_DelayTaskDuiXiang_,
  ListDelayTaskLieBiaoQingQiuDuiXiang,
  ApiResp_List_DelayTaskDuiXiang__,
  DelayTaskGengXinQingQiuDuiXiang,
  ApiResp_IPage_DelayTaskDuiXiang__,
  DemandVersionStatisticsPageReqFenYeQingQiuDuiXiang,
  ApiResp_Page_XuQiuBanBenTongJiFanHuiDuiXiang__,
  ChanYanZhiBiaoFenYeQingQiuDuiXiang,
  ApiResp_Page_ChanYanZhiBiaoFanHuiDuiXiang__,
  AddEnterpriseContactReq,
  ApiResp,
  DeleteEnterpriseContactReq,
  ListEnterpriseContactReq,
  ApiResp_List_Contact__,
  GetGeoTreeResp,
  ListEnterpriseReq,
  PageListEnterpriseResp,
  ModifyEnterpriseReq,
  OrgIdReq,
  ListTradeResp,
  QiYeMingXiXiaZuanQingQiuDuiXiang,
  ApiResp_List_QiYeMingXiXiaZuanDuiXiang__,
  ZhengTiGaiKuangXiaZuanQingQiuDuiXiang,
  ApiResp_List_ZhengTiGaiKuangXiaZuanDuiXiang__,
  CaoZuoMingXiFenYeQingQiuDuiXiang,
  QiYeMingXiFenYeQingQiuDuiXiang,
  ZhengTiGaiKuangFenYeQingQiuDuiXiang,
  QiYeMingXiJuTiPvFenYeQingQiuDuiXiang,
  ZhengTiGaiKuangJuTiPvFenYeQingQiuDuiXiang,
  ApiResp_Page_CaoZuoMingXiFanHuiDuiXiang__,
  ApiResp_Page_QiYeMingXiFanHuiDuiXiang__,
  ApiResp_Page_ZhengTiGaiKuangFanHuiDuiXiang__,
  ApiResp_Page_QiYeMingXiJuTiPvDuiXiang__,
  ApiResp_Page_ZhengTiGaiKuangJuTiPvDuiXiang__,
  FileIdReq,
  ListFileReq,
  ApiResp_Attachment_,
  GetFileUrlReqWenJianQingQiuDuiXiang,
  ApiResp_BaseIndexVO_,
  ApiResp_OrgDistributionVO_,
  PingTaiXinXiReq,
  ApiResp_List_OrgVisitVO__,
  ApiResp_List_PageVisitVO__,
  ApiResp_List_PageVisitDepthVO__,
  ApiResp_UserCountVO_,
  ApiResp_List_DayUserVisitVO__,
  XiuGaiZiYuanXinXiJi,
  AppTypeReq,
  ApiResp_List_ResourcesModelDuiXiang__,
  OrgIdAndKeywordReq,
  ApiResp_List_OrganizationDuiXiang__,
  DepartmentXinZengQingQiuDuiXiang,
  BuMenIdQingQiuTi,
  JiGouQingQiuDuiXiang,
  ListDepartmentLieBiaoQingQiuDuiXiang,
  ApiResp_List_DepartmentDuiXiang__,
  DepartmentGengXinQingQiuDuiXiang,
  JiChuFenYe_QingQiuTi,
  DepartmentUserBuMenHeYongHuXinXiHeGuanXi,
  ApiResp_OrganizationDuiXiang_,
  ListOrganizationLieBiaoQingQiuDuiXiang,
  OrganizationGengXinQingQiuDuiXiang,
  ListPositionLieBiaoQingQiuDuiXiang,
  ApiResp_List_PositionUserResp__,
  ApiResp_UserResp_,
  RenYuanXinXiBiao,
  ApiResp_List_PageStatDetailVO__,
  ApiResp_PageAnalyseResultVO_,
  HuoQuHeXinGongNeng_GuanZhuGongNengQuShi,
  ApiResp_List_CurveData__,
  ApiResp_PlatformModules_,
  DeleteProjectReq_ShanChuKeHuXiangMu,
  KeHuXiangMuLieBiao,
  PageListEnterpriseProjectInfoResp,
  KeHuXiangMuTianJia__PingJia,
  EvaluateIdReq,
  ApiResp_ProjectEvaluateDuiXiang_,
  KeHuXiangMuTianJia,
  XiangMuIdQingQiuDuiXiang,
  ApiResp_DepartmentUserDuiXiang_,
  KeHuXiangMu__XiangMuShiShiJieGuo_XiangMuCunZaiWenTiXiangTianJia,
  KeHuXiangMu__XiangMuShiShiJieGuo_XiangMuCunZaiWenTiXiangShanChu,
  TypeReq,
  ApiResp_List_ProjectFeedbackResp__,
  KeHuXiangMu__XiangMuShiShiJieGuo_XiangMuCunZaiWenTiXiangXiuGai,
  KeywordReq,
  ApiResp_List_ProductDuiXiang__,
  XinZengXiangMuXuQianJiLuBiaoQingQiuDuiXiang,
  DeleteProjectRenewReq,
  ApiResp_List_ProjectRenew__,
  ProjectRenew_GengXinQingQiuDuiXiang,
  KeHuXiangMu__XiangMuFanWeiTianJia,
  KeHuXiangMuXiuGai,
  KeHuXiangMu__XiangMuFanWei,
  ApiResp_ProjectResourcesResp_,
  BaseEntReq,
  ApiResp_Collection_OrganizationDuiXiang__,
  YunYingPingTaiJiaoSeFenYeQingQiuDuiXiang,
  ApiResp_Page_OperationResourcesNameResp__,
  RoleIDQingQiuDuiXiang,
  ListDwmOperationOrganizationMsgLieBiaoQingQiuDuiXiang,
  ApiResp_List_SimpleOrgResp__,
  ListOperationOrgReq,
  FenYeJiChuQingQiuRuCan,
  ApiResp_Page_SimpleOrgResp__,
  ApiResp_List_ResourcesModel__,
  RoleShanChuQingQiuDuiXiang,
  ListOperationMenuReq,
  ApiResp_List_long__,
  YunYingPingTaiXinZengHuoGengXinJiaoSeQingQiuDuiXiang,
  JiChuFenYe_QingQiuTiDuiXiang,
  ApiResp_Page_OperationUserResp__,
  UserRoleXinZengQingQiuDuiXiang,
  ApiResp_SmsPushTaskDetail_,
  ApiResp_IPage_SmsPushTaskDetail__,
  LoginUserReq,
  ApiResp_LoginUserResp_,
  UserGengXinQingQiuDuiXiang,
  ApiResp_List_UserActiveTrendDetailItem__,
  ApiResp_List_UserActiveTrendSummaryItemVO__,
  ApiResp_List_UserGrowTrendDetailItem__,
  ApiResp_List_UserGrowTrendSummaryItem__,
  UserIdQingQiuDuiXiang,
  ApiResp_User_,
  MobileLoginUserCenterReq,
  YongHuCaoZuoQingQiuDuiXiang,
  ZhongZhiMiMaQingQiuDuiXiang,
  GetVerificationReq,
  ApiResp_int_,
} from './definitions';
import httpClient from '../../httpClient';
/**
 * 枚举数据集（LevelEnum：企业等级，PopertyEnum：客户性质）
 *
 * @param req req
 * @returns OK
 */
export const _base_enum_list_POST = (req: JiChu_QingQiuTi): Promise<ApiResp_ListEnumResp_> => {
  return httpClient.post('/poit-operation-platform/base/enum/list', { body: req });
};
/**
 * 近7天用户活跃度
 *
 * @param req req
 * @returns OK
 */
export const _board_event_sevenDay_active_user_POST = (
  req: Jin7TianYongHuHuoYueDuQingQiuDuiXiang,
): Promise<ApiResp_List_YongHuHuoYueDuDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/board/event/sevenDay/active/user', {
    body: req,
  });
};
/**
 * 核心模块访问趋势
 *
 * @param req req
 * @returns OK
 */
export const _board_event_sevenDay_all_access_top6_POST = (
  req: ChanPinGongNengPvQingQiuDuiXiang,
): Promise<ApiResp_ChanPinFangWenCiShuFanHuiDuiXiang_> => {
  return httpClient.post('/poit-operation-platform/board/event/sevenDay/all/access/top6', {
    body: req,
  });
};
/**
 * 近7天产品功能pv-TOP10，暨页面排行TOP10
 *
 * @param req req
 * @returns OK
 */
export const _board_event_sevenDay_all_product_top10_POST = (
  req: ChanPinGongNengPvQingQiuDuiXiang,
): Promise<ApiResp_List_ChanPinGongNengPvFanHuiDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/board/event/sevenDay/all/product/top10', {
    body: req,
  });
};
/**
 * 平均访问深度（近7天）
 *
 * @param req req
 * @returns OK
 */
export const _board_event_sevenDay_dv_POST = (
  req: ChanPinGongNengPvQingQiuDuiXiang,
): Promise<ApiResp_List_PingJunFangWenShenDuDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/board/event/sevenDay/dv', { body: req });
};
/**
 * 新功能访问趋势
 *
 * @param req req
 * @returns OK
 */
export const _board_event_sevenDay_new_access_top6_POST = (
  req: ChanPinGongNengPvQingQiuDuiXiang,
): Promise<ApiResp_ChanPinFangWenCiShuFanHuiDuiXiang_> => {
  return httpClient.post('/poit-operation-platform/board/event/sevenDay/new/access/top6', {
    body: req,
  });
};
/**
 * 近7天新产品功能pv-TOP6
 *
 * @param req req
 * @returns OK
 */
export const _board_event_sevenDay_new_product_top6_POST = (
  req: ChanPinGongNengPvQingQiuDuiXiang,
): Promise<ApiResp_List_ChanPinGongNengPvFanHuiDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/board/event/sevenDay/new/product/top6', {
    body: req,
  });
};
/**
 * 客户排行TOP10
 *
 * @param req req
 * @returns OK
 */
export const _board_event_sevenDay_org_top10_POST = (
  req: ChanPinGongNengPvQingQiuDuiXiang,
): Promise<ApiResp_List_QiYeFangWenShuDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/board/event/sevenDay/org/top10', { body: req });
};
/**
 * 近7天企业用户数和活跃用户数
 *
 * @returns OK
 */
export const _board_event_sevenDay_user_create_active_POST =
  (): Promise<ApiResp_Jin7TianQiYeYongHuShuHeHuoYueYongHuShuFanHuiDuiXiang_> => {
    return httpClient.post('/poit-operation-platform/board/event/sevenDay/user/create/active');
  };
/**
 * 获取配置
 *
 * @param req req
 * @returns OK
 */
export const _config_get_POST = (req: GetConfigReq): Promise<ApiResp_ConfigItem_> => {
  return httpClient.post('/poit-operation-platform/config/get', { body: req });
};
/**
 * 保存配置
 *
 * @param req req
 * @returns OK
 */
export const _config_save_POST = (req: SaveConfigReq): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/config/save', { body: req });
};
/**
 * 协作--事项管理（文件列表）
 *
 * @param req req
 * @returns OK
 */
export const _cooperation_file_list_POST = (
  req: FileJsonReqQingQiuDuiXiang,
): Promise<ApiResp_List_FileUrl__> => {
  return httpClient.post('/poit-operation-platform/cooperation/file/list', { body: req });
};
/**
 * 协作--拍照事项（设备）
 *
 * @param req req
 * @returns OK
 */
export const _cooperation_matterAnalysis_device_POST = (
  req: ListDeviceAnalysisReqQingQiuDuiXiang,
): Promise<ApiResp_List_DeviceAnalysisDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/cooperation/matterAnalysis/device', {
    body: req,
  });
};
/**
 * 协作--企业运营分析（主页）
 *
 * @param req req
 * @returns OK
 */
export const _cooperation_matterAnalysis_list_POST = (
  req: ListEnterpriseAnalysisReqQingQiuDuiXiang,
): Promise<ApiResp_List_CooperationAnalysisDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/cooperation/matterAnalysis/list', { body: req });
};
/**
 * 协作--分享事项（页面）
 *
 * @param req req
 * @returns OK
 */
export const _cooperation_matterAnalysis_page_POST = (
  req: ListPageAnalysisReqQingQiuDuiXiang,
): Promise<ApiResp_List_PageAnalysisDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/cooperation/matterAnalysis/page', { body: req });
};
/**
 * 协作--事项管理（删除）
 *
 * @param req req
 * @returns OK
 */
export const _cooperation_matterManagement_delete_POST = (
  req: MatterIDQingQiuDuiXiang,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/cooperation/matterManagement/delete', {
    body: req,
  });
};
/**
 * 协作--事项管理（分页）
 *
 * @param req req
 * @returns OK
 */
export const _cooperation_matterManagement_page_POST = (
  req: PageManagementReqQingQiuDuiXiang,
): Promise<ApiResp_PageMatterManagementResp_> => {
  return httpClient.post('/poit-operation-platform/cooperation/matterManagement/page', {
    body: req,
  });
};
/**
 * 客户管理--添加
 *
 * @param req req
 * @returns OK
 */
export const _customer_management_add_POST = (
  req: CustomerManagementSaveReqXinZengQingQiuDuiXiang,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/customer/management/add', { body: req });
};
/**
 * 客户管理--删除
 *
 * @param request request
 * @returns OK
 */
export const _customer_management_delete_POST = (
  request: KeHuGuanLiShanChu,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/customer/management/delete', { body: request });
};
/**
 * 客户管理--编辑
 *
 * @param req req
 * @returns OK
 */
export const _customer_management_edit_POST = (
  req: CustomerManagementSaveReqXinZengQingQiuDuiXiang,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/customer/management/edit', { body: req });
};
/**
 * 客户管理--导出
 *
 * @param request request
 * @returns OK
 */
export const _customer_management_export_POST = (
  request: CustomerManagementExportReqQingQiuDuiXiang,
): Promise<void> => {
  return httpClient.post('/poit-operation-platform/customer/management/export', { body: request });
};
/**
 * 客户管理--字段筛选数据
 *
 * @param request request
 * @returns OK
 */
export const _customer_management_field_value_POST = (
  request: CustomerManagementFieldValueReqQingQiuDuiXiang,
): Promise<ApiResp_List_FieldValueInfo__> => {
  return httpClient.post('/poit-operation-platform/customer/management/field/value', {
    body: request,
  });
};
/**
 * 用户设置显示字段-列表
 * 对应接口：/customer/management/page CustomerInfo对象的成员
 * @param request request
 * @returns OK
 */
export const _customer_management_fields_list_POST = (
  request: JiChuFeignAuth_QingQiuTi,
): Promise<ApiResp_List_FieldListResp__> => {
  return httpClient.post('/poit-operation-platform/customer/management/fields/list', {
    body: request,
  });
};
/**
 * 用户设置显示字段-保存
 *
 * @param request request
 * @returns OK
 */
export const _customer_management_fields_save_POST = (
  request: CustomerManagementFieldSaveReqXinZengQingQiuDuiXiang,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/customer/management/fields/save', {
    body: request,
  });
};
/**
 * 客户管理--导入
 *
 * @param excel 需导入的部位备件excel文件
 * @param operateUserId 请求操作者
 * @param version 请求版本
 * @returns OK
 */
export const _customer_management_import_POST = (
  excel: any,
  operateUserId?: number,
  version?: string,
): Promise<ApiResp_boolean_> => {
  return httpClient.post(
    '/poit-operation-platform/customer/management/import' +
      `?${qs.stringify(
        { operateUserId: operateUserId, version: version },
        { arrayFormat: 'repeat' },
      )}`,
    { formData: { excel: excel } },
  );
};
/**
 * 客户管理--查看
 *
 * @param request request
 * @returns OK
 */
export const _customer_management_one_POST = (
  request: CustomerManagementOneReqQingQiuDuiXiang,
): Promise<ApiResp_CustomerInfoV2_> => {
  return httpClient.post('/poit-operation-platform/customer/management/one', { body: request });
};
/**
 * 客户管理--列表
 *
 * @param request request
 * @returns OK
 */
export const _customer_management_page_POST = (
  request: CustomerManagementPageReqQingQiuDuiXiang,
): Promise<ApiResp_Page_CustomerInfoV2__> => {
  return httpClient.post('/poit-operation-platform/customer/management/page', { body: request });
};
/**
 * 获取APP版本信息列表
 *
 * @param req req
 * @returns OK
 */
export const _data_analyse_app_version_list_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<ApiResp_AppVersion_> => {
  return httpClient.post('/poit-operation-platform/data/analyse/app/version/list', { body: req });
};
/**
 * 获取企业等级列表
 *
 * @param req req
 * @returns OK
 */
export const _data_analyse_enterprise_level_list_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<ApiResp_List_EnterpriseLevelVO__> => {
  return httpClient.post('/poit-operation-platform/data/analyse/enterprise/level/list', {
    body: req,
  });
};
/**
 * 获取页面列表
 *
 * @param req req
 * @returns OK
 */
export const _data_analyse_page_list_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<ApiResp_List_PageVO__> => {
  return httpClient.post('/poit-operation-platform/data/analyse/page/list', { body: req });
};
/**
 * 获取企业所属行业列表
 *
 * @param req req
 * @returns OK
 */
export const _data_analyse_trade_list_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<ApiResp_List_TradeVO__> => {
  return httpClient.post('/poit-operation-platform/data/analyse/trade/list', { body: req });
};
/**
 * 获取用户岗位列表
 *
 * @param req req
 * @returns OK
 */
export const _data_analyse_user_position_list_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<ApiResp_List_UserPosition__> => {
  return httpClient.post('/poit-operation-platform/data/analyse/user/position/list', { body: req });
};
/**
 * 添加
 *
 * @param req req
 * @returns OK
 */
export const _delayTask_add_POST = (
  req: DelayTaskXinZengQingQiuDuiXiang,
): Promise<ApiResp_long_> => {
  return httpClient.post('/poit-operation-platform/delayTask/add', { body: req });
};
/**
 * 删除
 *
 * @param req req
 * @returns OK
 */
export const _delayTask_delete_POST = (
  req: TaskIdReqQingQiuDuiXiang_,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/delayTask/delete', { body: req });
};
/**
 * 获取详情
 *
 * @param req req
 * @returns OK
 */
export const _delayTask_get_POST = (
  req: TaskIdReqQingQiuDuiXiang_,
): Promise<ApiResp_DelayTaskDuiXiang_> => {
  return httpClient.post('/poit-operation-platform/delayTask/get', { body: req });
};
/**
 * 列表
 *
 * @param req req
 * @returns OK
 */
export const _delayTask_list_POST = (
  req: ListDelayTaskLieBiaoQingQiuDuiXiang,
): Promise<ApiResp_List_DelayTaskDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/delayTask/list', { body: req });
};
/**
 * 修改
 *
 * @param req req
 * @returns OK
 */
export const _delayTask_modify_POST = (
  req: DelayTaskGengXinQingQiuDuiXiang,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/delayTask/modify', { body: req });
};
/**
 * 分页
 *
 * @param req req
 * @returns OK
 */
export const _delayTask_page_POST = (
  req: ListDelayTaskLieBiaoQingQiuDuiXiang,
): Promise<ApiResp_IPage_DelayTaskDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/delayTask/page', { body: req });
};
/**
 * 分页teambition需求版本统计数据
 *
 * @param req req
 * @returns OK
 */
export const _demand_version_statistics_page_POST = (
  req: DemandVersionStatisticsPageReqFenYeQingQiuDuiXiang,
): Promise<ApiResp_Page_XuQiuBanBenTongJiFanHuiDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/demand/version/statistics/page', { body: req });
};
/**
 * researchIndexPage
 *
 * @param req req
 * @returns OK
 */
export const _demand_version_statistics_research_index_page_POST = (
  req: ChanYanZhiBiaoFenYeQingQiuDuiXiang,
): Promise<ApiResp_Page_ChanYanZhiBiaoFanHuiDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/demand/version/statistics/research/index/page', {
    body: req,
  });
};
/**
 * 修改客户企业用户联系人信息列表
 *
 * @param req req
 * @returns OK
 */
export const _enterprise_contact_add_POST = (req: AddEnterpriseContactReq): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/enterprise/contact/add', { body: req });
};
/**
 * 删除客户企业用户联系人信息列表
 *
 * @param req req
 * @returns OK
 */
export const _enterprise_contact_delete_POST = (
  req: DeleteEnterpriseContactReq,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/enterprise/contact/delete', { body: req });
};
/**
 * 获取客户企业用户联系人信息列表
 *
 * @param req req
 * @returns OK
 */
export const _enterprise_contact_list_POST = (
  req: ListEnterpriseContactReq,
): Promise<ApiResp_List_Contact__> => {
  return httpClient.post('/poit-operation-platform/enterprise/contact/list', { body: req });
};
/**
 * 修改客户企业用户联系人信息列表
 *
 * @param req req
 * @returns OK
 */
export const _enterprise_contact_modify_POST = (req: AddEnterpriseContactReq): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/enterprise/contact/modify', { body: req });
};
/**
 * 区域信息
 *
 * @param req req
 * @returns OK
 */
export const _enterprise_geo_list_POST = (req: JiChu_QingQiuTi): Promise<GetGeoTreeResp> => {
  return httpClient.post('/poit-operation-platform/enterprise/geo/list', { body: req });
};
/**
 * 客户企业列表客户企业管理列表
 *
 * @param req req
 * @returns OK
 */
export const _enterprise_list_POST = (req: ListEnterpriseReq): Promise<PageListEnterpriseResp> => {
  return httpClient.post('/poit-operation-platform/enterprise/list', { body: req });
};
/**
 * 修改客户企业
 *
 * @param req req
 * @returns OK
 */
export const _enterprise_modify_POST = (req: ModifyEnterpriseReq): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/enterprise/modify', { body: req });
};
/**
 * 企业行业树
 *
 * @param req req
 * @returns OK
 */
export const _enterprise_trade_tree_POST = (req: OrgIdReq): Promise<ListTradeResp> => {
  return httpClient.post('/poit-operation-platform/enterprise/trade/tree', { body: req });
};
/**
 * 企业明细下钻
 *
 * @param req req
 * @returns OK
 */
export const _event_down_org_detail_POST = (
  req: QiYeMingXiXiaZuanQingQiuDuiXiang,
): Promise<ApiResp_List_QiYeMingXiXiaZuanDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/event/down/org/detail', { body: req });
};
/**
 * 整体概况下钻
 *
 * @param req req
 * @returns OK
 */
export const _event_down_overall_overview_POST = (
  req: ZhengTiGaiKuangXiaZuanQingQiuDuiXiang,
): Promise<ApiResp_List_ZhengTiGaiKuangXiaZuanDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/event/down/overall/overview', { body: req });
};
/**
 * 操作明细分页-导出
 *
 * @param req req
 * @returns OK
 */
export const _event_export_operation_detail_POST = (
  req: CaoZuoMingXiFenYeQingQiuDuiXiang,
): Promise<void> => {
  return httpClient.post('/poit-operation-platform/event/export/operation/detail', { body: req });
};
/**
 * 企业明细分页-导出
 *
 * @param req req
 * @returns OK
 */
export const _event_export_org_detail_POST = (
  req: QiYeMingXiFenYeQingQiuDuiXiang,
): Promise<void> => {
  return httpClient.post('/poit-operation-platform/event/export/org/detail', { body: req });
};
/**
 * 整体概况分页-导出
 *
 * @param req req
 * @returns OK
 */
export const _event_export_overall_overview_POST = (
  req: ZhengTiGaiKuangFenYeQingQiuDuiXiang,
): Promise<void> => {
  return httpClient.post('/poit-operation-platform/event/export/overall/overview', { body: req });
};
/**
 * 企业明细具体pv-导出
 *
 * @param req req
 * @returns OK
 */
export const _event_export_pv_org_detail_POST = (
  req: QiYeMingXiJuTiPvFenYeQingQiuDuiXiang,
): Promise<void> => {
  return httpClient.post('/poit-operation-platform/event/export/pv/org/detail', { body: req });
};
/**
 * 整体概况具体pv-导出
 *
 * @param req req
 * @returns OK
 */
export const _event_export_pv_overall_overview_POST = (
  req: ZhengTiGaiKuangJuTiPvFenYeQingQiuDuiXiang,
): Promise<void> => {
  return httpClient.post('/poit-operation-platform/event/export/pv/overall/overview', {
    body: req,
  });
};
/**
 * 操作明细分页
 *
 * @param req req
 * @returns OK
 */
export const _event_page_operation_detail_POST = (
  req: CaoZuoMingXiFenYeQingQiuDuiXiang,
): Promise<ApiResp_Page_CaoZuoMingXiFanHuiDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/event/page/operation/detail', { body: req });
};
/**
 * 企业明细分页
 *
 * @param req req
 * @returns OK
 */
export const _event_page_org_detail_POST = (
  req: QiYeMingXiFenYeQingQiuDuiXiang,
): Promise<ApiResp_Page_QiYeMingXiFanHuiDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/event/page/org/detail', { body: req });
};
/**
 * 整体概况分页
 *
 * @param req req
 * @returns OK
 */
export const _event_page_overall_overview_POST = (
  req: ZhengTiGaiKuangFenYeQingQiuDuiXiang,
): Promise<ApiResp_Page_ZhengTiGaiKuangFanHuiDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/event/page/overall/overview', { body: req });
};
/**
 * 企业明细具体pv
 *
 * @param req req
 * @returns OK
 */
export const _event_page_pv_org_detail_POST = (
  req: QiYeMingXiJuTiPvFenYeQingQiuDuiXiang,
): Promise<ApiResp_Page_QiYeMingXiJuTiPvDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/event/page/pv/org/detail', { body: req });
};
/**
 * 整体概况具体pv
 *
 * @param req req
 * @returns OK
 */
export const _event_page_pv_overall_overview_POST = (
  req: ZhengTiGaiKuangJuTiPvFenYeQingQiuDuiXiang,
): Promise<ApiResp_Page_ZhengTiGaiKuangJuTiPvDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/event/page/pv/overall/overview', { body: req });
};
/**
 * 文件删除
 *
 * @param req req
 * @returns OK
 */
export const _file_organization_delete_POST = (req: FileIdReq): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/file/organization/delete', { body: req });
};
/**
 * 文件列表
 *
 * @param req req
 * @returns OK
 */
export const _file_organization_list_POST = (req: ListFileReq): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/file/organization/list', { body: req });
};
/**
 * 文件上传
 *
 * @param associatedId 关联对象id,机构orgId,企业项目
 * @param file 文件
 * @param fileScopeOrg ORG_FILE：组织、CLIENT_ENT：客户企业
 * @param operateUserId 请求操作者
 * @param version 请求版本
 * @returns OK
 */
export const _file_organization_upload_POST = (
  associatedId: number,
  file: any,
  fileScopeOrg: string,
  operateUserId?: number,
  version?: string,
): Promise<ApiResp> => {
  return httpClient.post(
    '/poit-operation-platform/file/organization/upload' +
      `?${qs.stringify(
        {
          associatedId: associatedId,
          file: file,
          fileScopeOrg: fileScopeOrg,
          operateUserId: operateUserId,
          version: version,
        },
        { arrayFormat: 'repeat' },
      )}`,
  );
};
/**
 * upload
 *
 * @param file file
 * @param operateUserId 请求操作者
 * @param version 请求版本
 * @returns OK
 */
export const _file_upload_POST = (
  file: any,
  operateUserId?: number,
  version?: string,
): Promise<ApiResp_Attachment_> => {
  return httpClient.post(
    '/poit-operation-platform/file/upload' +
      `?${qs.stringify(
        { operateUserId: operateUserId, version: version },
        { arrayFormat: 'repeat' },
      )}`,
    { formData: { file: file } },
  );
};
/**
 * 获取文件url
 *
 * @param req req
 * @returns OK
 */
export const _file_url_POST = (req: GetFileUrlReqWenJianQingQiuDuiXiang): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/file/url', { body: req });
};
/**
 * 基础统计信息，如采集数据量、接入设备等
 *
 * @param request request
 * @returns OK
 */
export const _home_page_basic_index_list_POST = (
  request: JiChuFeignAuth_QingQiuTi,
): Promise<ApiResp_BaseIndexVO_> => {
  return httpClient.post('/poit-operation-platform/home/page/basic/index/list', { body: request });
};
/**
 * 企业各省分布
 *
 * @param request request
 * @returns OK
 */
export const _home_page_org_orgDistribution_get_POST = (
  request: JiChuFeignAuth_QingQiuTi,
): Promise<ApiResp_OrgDistributionVO_> => {
  return httpClient.post('/poit-operation-platform/home/page/org/orgDistribution/get', {
    body: request,
  });
};
/**
 * 机构访问排名，取访问量前10的客户
 *
 * @param request request
 * @returns OK
 */
export const _home_page_org_rank_list_POST = (
  request: PingTaiXinXiReq,
): Promise<ApiResp_List_OrgVisitVO__> => {
  return httpClient.post('/poit-operation-platform/home/page/org/rank/list', { body: request });
};
/**
 * 页面访问排名，取访问量前10的页面
 *
 * @param request request
 * @returns OK
 */
export const _home_page_page_rank_list_POST = (
  request: PingTaiXinXiReq,
): Promise<ApiResp_List_PageVisitVO__> => {
  return httpClient.post('/poit-operation-platform/home/page/page/rank/list', { body: request });
};
/**
 * 平均访问深度趋势
 *
 * @param req req
 * @returns OK
 */
export const _home_page_page_visit_depth_list_POST = (
  req: JiChuFeignAuth_QingQiuTi,
): Promise<ApiResp_List_PageVisitDepthVO__> => {
  return httpClient.post('/poit-operation-platform/home/page/page/visit/depth/list', { body: req });
};
/**
 * 用户日活数
 *
 * @param request request
 * @returns OK
 */
export const _home_page_user_count_get_POST = (
  request: JiChuFeignAuth_QingQiuTi,
): Promise<ApiResp_UserCountVO_> => {
  return httpClient.post('/poit-operation-platform/home/page/user/count/get', { body: request });
};
/**
 * 用户日活趋势
 *
 * @param req req
 * @returns OK
 */
export const _home_page_user_visit_list_POST = (
  req: JiChuFeignAuth_QingQiuTi,
): Promise<ApiResp_List_DayUserVisitVO__> => {
  return httpClient.post('/poit-operation-platform/home/page/user/visit/list', { body: req });
};
/**
 * 菜单--新增
 *
 * @param req req
 * @returns OK
 */
export const _menu_add_POST = (req: XiuGaiZiYuanXinXiJi): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/menu/add', { body: req });
};
/**
 * 菜单--修改
 *
 * @param req req
 * @returns OK
 */
export const _menu_modify_POST = (req: XiuGaiZiYuanXinXiJi): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/menu/modify', { body: req });
};
/**
 * 菜单--菜单树
 *
 * @param req req
 * @returns OK
 */
export const _menu_tree_POST = (
  req: AppTypeReq,
): Promise<ApiResp_List_ResourcesModelDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/menu/tree', { body: req });
};
/**
 * 机构下企业列表
 *
 * @param req req
 * @returns OK
 */
export const _organization_children_list_POST = (
  req: OrgIdAndKeywordReq,
): Promise<ApiResp_List_OrganizationDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/organization/children/list', { body: req });
};
/**
 * 添加部门信息,需要携带所属组织和父级id
 *
 * @param req req
 * @returns OK
 */
export const _organization_department_add_POST = (
  req: DepartmentXinZengQingQiuDuiXiang,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/organization/department/add', { body: req });
};
/**
 * 删除部门
 *
 * @param req req
 * @returns OK
 */
export const _organization_department_delete_POST = (
  req: BuMenIdQingQiuTi,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/organization/department/delete', { body: req });
};
/**
 * 添加部门信息,需要携带所属组织和父级id
 *
 * @param req req
 * @returns OK
 */
export const _organization_department_get_POST = (req: JiGouQingQiuDuiXiang): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/organization/department/get', { body: req });
};
/**
 * 根据部门名称和组织id进行搜索
 *
 * @param req req
 * @returns OK
 */
export const _organization_department_list_POST = (
  req: ListDepartmentLieBiaoQingQiuDuiXiang,
): Promise<ApiResp_List_DepartmentDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/organization/department/list', { body: req });
};
/**
 * 修改部门信息,根据部门id进行修改
 *
 * @param req req
 * @returns OK
 */
export const _organization_department_modify_POST = (
  req: DepartmentGengXinQingQiuDuiXiang,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/organization/department/modify', { body: req });
};
/**
 * 获取机构下的部门树列表
 *
 * @param req req
 * @returns OK
 */
export const _organization_department_tree_org_list_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/organization/department/tree/org/list', {
    body: req,
  });
};
/**
 * 机构选择部门（去除当前部门子树）
 *
 * @param req req
 * @returns OK
 */
export const _organization_department_tree_select_list_POST = (
  req: JiGouQingQiuDuiXiang,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/organization/department/tree/select/list', {
    body: req,
  });
};
/**
 * 部门下用户列表
 *
 * @param req req
 * @returns OK
 */
export const _organization_department_user_list_POST = (
  req: JiChuFenYe_QingQiuTi,
): Promise<DepartmentUserBuMenHeYongHuXinXiHeGuanXi> => {
  return httpClient.post('/poit-operation-platform/organization/department/user/list', {
    body: req,
  });
};
/**
 * 获取组织详情
 *
 * @param req req
 * @returns OK
 */
export const _organization_get_POST = (
  req: JiGouQingQiuDuiXiang,
): Promise<ApiResp_OrganizationDuiXiang_> => {
  return httpClient.post('/poit-operation-platform/organization/get', { body: req });
};
/**
 * 组织列表
 *
 * @param req req
 * @returns OK
 */
export const _organization_list_POST = (
  req: ListOrganizationLieBiaoQingQiuDuiXiang,
): Promise<ApiResp_List_OrganizationDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/organization/list', { body: req });
};
/**
 * 修改组织信息
 *
 * @param req req
 * @returns OK
 */
export const _organization_modify_POST = (
  req: OrganizationGengXinQingQiuDuiXiang,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/organization/modify', { body: req });
};
/**
 * 根据组织id和职位名称搜索
 *
 * @param req req
 * @returns OK
 */
export const _organization_position_list_POST = (
  req: ListPositionLieBiaoQingQiuDuiXiang,
): Promise<ApiResp_List_PositionUserResp__> => {
  return httpClient.post('/poit-operation-platform/organization/position/list', { body: req });
};
/**
 * 获取组织用户详情
 *
 * @param req req
 * @returns OK
 */
export const _organization_user_get_POST = (
  req: JiGouQingQiuDuiXiang,
): Promise<ApiResp_UserResp_> => {
  return httpClient.post('/poit-operation-platform/organization/user/get', { body: req });
};
/**
 * 机构下用户信息列表
 *
 * @param req req
 * @returns OK
 */
export const _organization_user_list_POST = (req: RenYuanXinXiBiao): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/organization/user/list', { body: req });
};
/**
 * 获取页面数据分析明细表
 *
 * @param req req
 * @returns OK
 */
export const _page_analyse_detail_list_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<ApiResp_List_PageStatDetailVO__> => {
  return httpClient.post('/poit-operation-platform/page/analyse/detail/list', { body: req });
};
/**
 * 导出页面数据分析明细表，按页面
 *
 * @param req req
 * @returns OK
 */
export const _page_analyse_page_detail_export_POST = (req: JiChuFeign_QingQiuTi): Promise<void> => {
  return httpClient.post('/poit-operation-platform/page/analyse/page/detail/export', { body: req });
};
/**
 * 导出页面数据分析汇总表，按页面
 *
 * @param req req
 * @returns OK
 */
export const _page_analyse_page_statistics_export_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<void> => {
  return httpClient.post('/poit-operation-platform/page/analyse/page/statistics/export', {
    body: req,
  });
};
/**
 * 获取页面数据分析结果，按页面汇总表
 *
 * @param req req
 * @returns OK
 */
export const _page_analyse_page_statistics_get_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<ApiResp_PageAnalyseResultVO_> => {
  return httpClient.post('/poit-operation-platform/page/analyse/page/statistics/get', {
    body: req,
  });
};
/**
 * 导出页面数据分析明细表，按时间
 *
 * @param req req
 * @returns OK
 */
export const _page_analyse_time_detail_export_POST = (req: JiChuFeign_QingQiuTi): Promise<void> => {
  return httpClient.post('/poit-operation-platform/page/analyse/time/detail/export', { body: req });
};
/**
 * 导出页面数据分析汇总表，按时间
 *
 * @param req req
 * @returns OK
 */
export const _page_analyse_time_statistics_export_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<void> => {
  return httpClient.post('/poit-operation-platform/page/analyse/time/statistics/export', {
    body: req,
  });
};
/**
 * 获取页面数据分析结果，按时间
 *
 * @param req req
 * @returns OK
 */
export const _page_analyse_time_statistics_get_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<ApiResp_PageAnalyseResultVO_> => {
  return httpClient.post('/poit-operation-platform/page/analyse/time/statistics/get', {
    body: req,
  });
};
/**
 * 、关注核心功能趋势
 *
 * @param req req
 * @returns OK
 */
export const _pageInfo_feature_trend_list_POST = (
  req: HuoQuHeXinGongNeng_GuanZhuGongNengQuShi,
): Promise<ApiResp_List_CurveData__> => {
  return httpClient.post('/poit-operation-platform/pageInfo/feature/trend/list', { body: req });
};
/**
 * getModuleTree
 *
 * @param request request
 * @returns OK
 */
export const _pageInfo_module_tree_get_POST = (
  request: JiChuFeignAuth_QingQiuTi,
): Promise<ApiResp_PlatformModules_> => {
  return httpClient.post('/poit-operation-platform/pageInfo/module/tree/get', { body: request });
};
/**
 * 获取新页面排名
 *
 * @param request request
 * @returns OK
 */
export const _pageInfo_new_page_rank_list_POST = (
  request: PingTaiXinXiReq,
): Promise<ApiResp_List_PageVisitVO__> => {
  return httpClient.post('/poit-operation-platform/pageInfo/new/page/rank/list', { body: request });
};
/**
 * 企业项目--删除
 *
 * @param req req
 * @returns OK
 */
export const _project_enterpriseProject_delete_POST = (
  req: DeleteProjectReq_ShanChuKeHuXiangMu,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/project/enterpriseProject/delete', {
    body: req,
  });
};
/**
 * 企业项目列表
 *
 * @param req req
 * @returns OK
 */
export const _project_enterpriseProject_list_POST = (
  req: KeHuXiangMuLieBiao,
): Promise<PageListEnterpriseProjectInfoResp> => {
  return httpClient.post('/poit-operation-platform/project/enterpriseProject/list', { body: req });
};
/**
 * 项目管理--实施评价新增
 *
 * @param req req
 * @returns OK
 */
export const _project_enterpriseProject_projectEvaluate_add_POST = (
  req: KeHuXiangMuTianJia__PingJia,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/project/enterpriseProject/projectEvaluate/add', {
    body: req,
  });
};
/**
 * 项目管理--实施评价删除
 *
 * @param req req
 * @returns OK
 */
export const _project_enterpriseProject_projectEvaluate_delete_POST = (
  req: EvaluateIdReq,
): Promise<ApiResp> => {
  return httpClient.post(
    '/poit-operation-platform/project/enterpriseProject/projectEvaluate/delete',
    { body: req },
  );
};
/**
 * 项目管理--实施评价
 *
 * @param req req
 * @returns OK
 */
export const _project_enterpriseProject_projectEvaluate_get_POST = (
  req: EvaluateIdReq,
): Promise<ApiResp_ProjectEvaluateDuiXiang_> => {
  return httpClient.post('/poit-operation-platform/project/enterpriseProject/projectEvaluate/get', {
    body: req,
  });
};
/**
 * 项目管理--实施评价修改
 *
 * @param req req
 * @returns OK
 */
export const _project_enterpriseProject_projectEvaluate_modify_POST = (
  req: KeHuXiangMuTianJia__PingJia,
): Promise<ApiResp> => {
  return httpClient.post(
    '/poit-operation-platform/project/enterpriseProject/projectEvaluate/modify',
    { body: req },
  );
};
/**
 * 项目管理--基本信息新增
 *
 * @param req req
 * @returns OK
 */
export const _project_enterpriseProject_projectInfo_add_POST = (
  req: KeHuXiangMuTianJia,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/project/enterpriseProject/projectInfo/add', {
    body: req,
  });
};
/**
 * 项目管理--基本信息删除
 *
 * @param req req
 * @returns OK
 */
export const _project_enterpriseProject_projectInfo_delete_POST = (
  req: XiangMuIdQingQiuDuiXiang,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/project/enterpriseProject/projectInfo/delete', {
    body: req,
  });
};
/**
 * 项目管理--基本信息
 *
 * @param req req
 * @returns OK
 */
export const _project_enterpriseProject_projectInfo_get_POST = (
  req: XiangMuIdQingQiuDuiXiang,
): Promise<ApiResp_DepartmentUserDuiXiang_> => {
  return httpClient.post('/poit-operation-platform/project/enterpriseProject/projectInfo/get', {
    body: req,
  });
};
/**
 * 项目管理--基本信息修改
 *
 * @param req req
 * @returns OK
 */
export const _project_enterpriseProject_projectInfo_modify_POST = (
  req: KeHuXiangMuTianJia,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/project/enterpriseProject/projectInfo/modify', {
    body: req,
  });
};
/**
 * 项目实施结果+项目存在问题项新增
 *
 * @param req req
 * @returns OK
 */
export const _project_feedback_add_POST = (
  req: KeHuXiangMu__XiangMuShiShiJieGuo_XiangMuCunZaiWenTiXiangTianJia,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/project/feedback/add', { body: req });
};
/**
 * 项目实施结果+项目存在问题项删除
 *
 * @param req req
 * @returns OK
 */
export const _project_feedback_delete_POST = (
  req: KeHuXiangMu__XiangMuShiShiJieGuo_XiangMuCunZaiWenTiXiangShanChu,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/project/feedback/delete', { body: req });
};
/**
 * 项目实施结果+项目存在问题项列表
 *
 * @param req req
 * @returns OK
 */
export const _project_feedback_list_POST = (
  req: TypeReq,
): Promise<ApiResp_List_ProjectFeedbackResp__> => {
  return httpClient.post('/poit-operation-platform/project/feedback/list', { body: req });
};
/**
 * 项目实施结果+项目存在问题项修改
 *
 * @param req req
 * @returns OK
 */
export const _project_feedback_modify_POST = (
  req: KeHuXiangMu__XiangMuShiShiJieGuo_XiangMuCunZaiWenTiXiangXiuGai,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/project/feedback/modify', { body: req });
};
/**
 * 项目管理--项目范围付费产品list
 *
 * @param req req
 * @returns OK
 */
export const _project_product_list_POST = (
  req: KeywordReq,
): Promise<ApiResp_List_ProductDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/project/product/list', { body: req });
};
/**
 * 项目管理--续签新增
 *
 * @param req req
 * @returns OK
 */
export const _project_projectRenew_add_POST = (
  req: XinZengXiangMuXuQianJiLuBiaoQingQiuDuiXiang,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/project/projectRenew/add', { body: req });
};
/**
 * 项目管理--续签删除
 *
 * @param req req
 * @returns OK
 */
export const _project_projectRenew_delete_POST = (req: DeleteProjectRenewReq): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/project/projectRenew/delete', { body: req });
};
/**
 * 项目管理--续签列表
 *
 * @param req req
 * @returns OK
 */
export const _project_projectRenew_list_POST = (
  req: XiangMuIdQingQiuDuiXiang,
): Promise<ApiResp_List_ProjectRenew__> => {
  return httpClient.post('/poit-operation-platform/project/projectRenew/list', { body: req });
};
/**
 * 项目管理--续签修改
 *
 * @param req req
 * @returns OK
 */
export const _project_projectRenew_modify_POST = (
  req: ProjectRenew_GengXinQingQiuDuiXiang,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/project/projectRenew/modify', { body: req });
};
/**
 * 项目管理--项目范围菜单新增
 *
 * @param req req
 * @returns OK
 */
export const _project_projectScope_add_POST = (
  req: KeHuXiangMu__XiangMuFanWeiTianJia,
): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/project/projectScope/add', { body: req });
};
/**
 * 项目管理--项目范围菜单修改
 *
 * @param req req
 * @returns OK
 */
export const _project_projectScope_modify_POST = (req: KeHuXiangMuXiuGai): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/project/projectScope/modify', { body: req });
};
/**
 * 项目管理--项目范围菜单
 *
 * @param req req
 * @returns OK
 */
export const _project_projectScope_tree_POST = (
  req: KeHuXiangMu__XiangMuFanWei,
): Promise<ApiResp_ProjectResourcesResp_> => {
  return httpClient.post('/poit-operation-platform/project/projectScope/tree', { body: req });
};
/**
 * 查询用户的所在企业信息,用于用户登录后选企业
 *
 * @param req req
 * @returns OK
 */
export const _resource_permission_org_list_POST = (
  req: BaseEntReq,
): Promise<ApiResp_Collection_OrganizationDuiXiang__> => {
  return httpClient.post('/poit-operation-platform/resource/permission/org/list', { body: req });
};
/**
 * 角色分页
 *
 * @param req req
 * @returns OK
 */
export const _resource_role_ent_page_POST = (
  req: YunYingPingTaiJiaoSeFenYeQingQiuDuiXiang,
): Promise<ApiResp_Page_OperationResourcesNameResp__> => {
  return httpClient.post('/poit-operation-platform/resource/role/ent/page', { body: req });
};
/**
 * 获取当前角色是否有关联用户
 *
 * @param req req
 * @returns OK
 */
export const _resource_role_has_relation_by_roleId_POST = (
  req: RoleIDQingQiuDuiXiang,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/resource/role/has/relation/by/roleId', {
    body: req,
  });
};
/**
 * 获取所有企业列表
 *
 * @param req req
 * @returns OK
 */
export const _resource_role_list_allOrg_POST = (
  req: ListDwmOperationOrganizationMsgLieBiaoQingQiuDuiXiang,
): Promise<ApiResp_List_SimpleOrgResp__> => {
  return httpClient.post('/poit-operation-platform/resource/role/list/allOrg', { body: req });
};
/**
 * 获取角色已配置运营企业列表
 *
 * @param req req
 * @returns OK
 */
export const _resource_role_operation_org_by_roleIds_POST = (
  req: ListOperationOrgReq,
): Promise<ApiResp_List_SimpleOrgResp__> => {
  return httpClient.post('/poit-operation-platform/resource/role/operation/org/by/roleIds', {
    body: req,
  });
};
/**
 * 获取当前用户所运营企业下拉列表-用于企业筛选
 *
 * @param req req
 * @returns OK
 */
export const _resource_role_operationOrg_select_list_POST = (
  req: BaseEntReq,
): Promise<ApiResp_List_SimpleOrgResp__> => {
  return httpClient.post('/poit-operation-platform/resource/role/operationOrg/select/list', {
    body: req,
  });
};
/**
 * 获取所有企业分页
 *
 * @param req req
 * @returns OK
 */
export const _resource_role_page_allOrg_POST = (
  req: FenYeJiChuQingQiuRuCan,
): Promise<ApiResp_Page_SimpleOrgResp__> => {
  return httpClient.post('/poit-operation-platform/resource/role/page/allOrg', { body: req });
};
/**
 * 查询运营平台权限菜单树
 *
 * @param req req
 * @returns OK
 */
export const _resource_role_resources_tree_POST = (
  req: BaseEntReq,
): Promise<ApiResp_List_ResourcesModel__> => {
  return httpClient.post('/poit-operation-platform/resource/role/resources/tree', { body: req });
};
/**
 * 删除角色
 *
 * @param req req
 * @returns OK
 */
export const _resource_role_roleId_delete_POST = (
  req: RoleShanChuQingQiuDuiXiang,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/resource/role/roleId/delete', { body: req });
};
/**
 * 获取角色已授权的资源
 *
 * @param req req
 * @returns OK
 */
export const _resource_role_roleResourceIds_get_POST = (
  req: ListOperationMenuReq,
): Promise<ApiResp_List_long__> => {
  return httpClient.post('/poit-operation-platform/resource/role/roleResourceIds/get', {
    body: req,
  });
};
/**
 * 新增或更新角色
 *
 * @param req req
 * @returns OK
 */
export const _resource_role_saveOrUpdate_POST = (
  req: YunYingPingTaiXinZengHuoGengXinJiaoSeQingQiuDuiXiang,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/resource/role/saveOrUpdate', { body: req });
};
/**
 * 获取用户分页列表
 *
 * @param req req
 * @returns OK
 */
export const _resource_user_page_POST = (
  req: JiChuFenYe_QingQiuTiDuiXiang,
): Promise<ApiResp_Page_OperationUserResp__> => {
  return httpClient.post('/poit-operation-platform/resource/user/page', { body: req });
};
/**
 * 修改用户关联的角色
 *
 * @param req req
 * @returns OK
 */
export const _resource_user_update_POST = (
  req: UserRoleXinZengQingQiuDuiXiang,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/resource/user/update', { body: req });
};
/**
 * 登录后获取用户所拥有的菜单
 *
 * @param req req
 * @returns OK
 */
export const _resource_user_userId_tree_POST = (
  req: BaseEntReq,
): Promise<ApiResp_List_ResourcesModel__> => {
  return httpClient.post('/poit-operation-platform/resource/user/userId/tree', { body: req });
};
/**
 * 添加
 *
 * @param req req
 * @returns OK
 */
export const _smsPush_add_POST = (req: JiChu_QingQiuTi): Promise<ApiResp_long_> => {
  return httpClient.post('/poit-operation-platform/smsPush/add', { body: req });
};
/**
 * 删除
 *
 * @param req req
 * @returns OK
 */
export const _smsPush_delete_POST = (req: TaskIdReqQingQiuDuiXiang_): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/smsPush/delete', { body: req });
};
/**
 * 获取详情
 *
 * @param req req
 * @returns OK
 */
export const _smsPush_get_POST = (
  req: TaskIdReqQingQiuDuiXiang_,
): Promise<ApiResp_SmsPushTaskDetail_> => {
  return httpClient.post('/poit-operation-platform/smsPush/get', { body: req });
};
/**
 * 修改
 *
 * @param req req
 * @returns OK
 */
export const _smsPush_modify_POST = (req: JiChu_QingQiuTi): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/smsPush/modify', { body: req });
};
/**
 * 分页
 *
 * @param req req
 * @returns OK
 */
export const _smsPush_page_POST = (
  req: ListDelayTaskLieBiaoQingQiuDuiXiang,
): Promise<ApiResp_IPage_SmsPushTaskDetail__> => {
  return httpClient.post('/poit-operation-platform/smsPush/page', { body: req });
};
/**
 * 账号登陆
 *
 * @param req req
 * @returns OK
 */
export const _user_account_login_POST = (req: LoginUserReq): Promise<ApiResp_LoginUserResp_> => {
  return httpClient.post('/poit-operation-platform/user/account/login', { body: req });
};
/**
 * 用户管理--新增用户信息
 *
 * @param req req
 * @returns OK
 */
export const _user_add_POST = (req: UserGengXinQingQiuDuiXiang): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/user/add', { body: req });
};
/**
 * 导出用户活跃趋势明细表
 *
 * @param req req
 * @returns OK
 */
export const _user_analyse_active_trend_detail_export_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<void> => {
  return httpClient.post('/poit-operation-platform/user/analyse/active/trend/detail/export', {
    body: req,
  });
};
/**
 * 获取用户活跃趋势明细表
 *
 * @param req req
 * @returns OK
 */
export const _user_analyse_active_trend_detail_list_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<ApiResp_List_UserActiveTrendDetailItem__> => {
  return httpClient.post('/poit-operation-platform/user/analyse/active/trend/detail/list', {
    body: req,
  });
};
/**
 * 导出用户活跃趋势汇总表
 *
 * @param req req
 * @returns OK
 */
export const _user_analyse_active_trend_summary_export_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<void> => {
  return httpClient.post('/poit-operation-platform/user/analyse/active/trend/summary/export', {
    body: req,
  });
};
/**
 * 获取用户活跃趋势汇总表
 *
 * @param req req
 * @returns OK
 */
export const _user_analyse_active_trend_summary_list_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<ApiResp_List_UserActiveTrendSummaryItemVO__> => {
  return httpClient.post('/poit-operation-platform/user/analyse/active/trend/summary/list', {
    body: req,
  });
};
/**
 * 导出用户增长趋势明细表
 *
 * @param req req
 * @returns OK
 */
export const _user_analyse_grow_trend_detail_export_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<void> => {
  return httpClient.post('/poit-operation-platform/user/analyse/grow/trend/detail/export', {
    body: req,
  });
};
/**
 * 获取用户增长趋势明细表
 *
 * @param req req
 * @returns OK
 */
export const _user_analyse_grow_trend_detail_list_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<ApiResp_List_UserGrowTrendDetailItem__> => {
  return httpClient.post('/poit-operation-platform/user/analyse/grow/trend/detail/list', {
    body: req,
  });
};
/**
 * 导出用户增长趋势汇总表
 *
 * @param req req
 * @returns OK
 */
export const _user_analyse_grow_trend_summary_export_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<void> => {
  return httpClient.post('/poit-operation-platform/user/analyse/grow/trend/summary/export', {
    body: req,
  });
};
/**
 * 获取用户增长趋势汇总表
 *
 * @param req req
 * @returns OK
 */
export const _user_analyse_grow_trend_summary_list_POST = (
  req: JiChuFeign_QingQiuTi,
): Promise<ApiResp_List_UserGrowTrendSummaryItem__> => {
  return httpClient.post('/poit-operation-platform/user/analyse/grow/trend/summary/list', {
    body: req,
  });
};
/**
 * 用户管理--删除用户
 *
 * @param req req
 * @returns OK
 */
export const _user_delete_POST = (req: UserIdQingQiuDuiXiang): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/user/delete', { body: req });
};
/**
 * 用户管理--查看单个用户信息
 *
 * @param req req
 * @returns OK
 */
export const _user_get_POST = (req: UserIdQingQiuDuiXiang): Promise<ApiResp_User_> => {
  return httpClient.post('/poit-operation-platform/user/get', { body: req });
};
/**
 * 手机登录
 *
 * @param req req
 * @returns OK
 */
export const _user_mobile_login_POST = (
  req: MobileLoginUserCenterReq,
): Promise<ApiResp_LoginUserResp_> => {
  return httpClient.post('/poit-operation-platform/user/mobile/login', { body: req });
};
/**
 * 用户管理--修改用户信息
 *
 * @param req req
 * @returns OK
 */
export const _user_modify_POST = (req: UserGengXinQingQiuDuiXiang): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/user/modify', { body: req });
};
/**
 * 修改用户状态
 *
 * @param req req
 * @returns OK
 */
export const _user_modify_state_POST = (
  req: YongHuCaoZuoQingQiuDuiXiang,
): Promise<ApiResp_boolean_> => {
  return httpClient.post('/poit-operation-platform/user/modify/state', { body: req });
};
/**
 * 用户管理--修改用户密码
 *
 * @param req req
 * @returns OK
 */
export const _user_password_reset_POST = (req: ZhongZhiMiMaQingQiuDuiXiang): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/user/password/reset', { body: req });
};
/**
 * 获取手机验证码
 *
 * @param req req
 * @returns OK
 */
export const _user_verification_code_get_POST = (req: GetVerificationReq): Promise<ApiResp> => {
  return httpClient.post('/poit-operation-platform/user/verification/code/get', { body: req });
};
/**
 * countKeyEnterprise
 *
 * @param request request
 * @returns OK
 */
export const _value_board_key_enterprise_count_POST = (
  request: JiChuFeignAuth_QingQiuTi,
): Promise<ApiResp_int_> => {
  return httpClient.post('/poit-operation-platform/value/board/key/enterprise/count', {
    body: request,
  });
};
